<template>
  <div class="d-flex flex-column">
    <div class="owner-detail d-flex justify-content-between">
      <button ref="editButton" class="btn edit-button" @click="handleEditClick"><i class="fas fa-pencil-alt"></i></button>
    </div>

    <div
      class="d-flex flex-column"
      v-for="bankAccount in bankAccounts"
      :key="bankAccount.id"
    >
      <div v-if="bankAccount.status === 'pending'">
        {{ bankAccount.nameOnAccount }}
      </div>
      <template v-else>
        <div v-if="bankAccount.accountLabel && bankAccounts.length > 1">
          <h3 class="mb-1 mt-2">{{ bankAccount.accountLabel }}</h3>
        </div>
        <div class="d-grid grid-columns-3 gap-3">
          <div style="grid-column: span 3;">
            <label for="name">
              <span>Name on account</span>
            </label>
            <span>{{ bankAccount.nameOnAccount }}</span>
          </div>
          <div>
            <label for="routing-number">
              <span>Routing number</span>
            </label>
            <span>{{ bankAccount.accountRouting }}</span>
          </div>
          <div>
            <label for="account-number">
              <span>Account number</span>
            </label>
            <span>{{ bankAccount.accountNumber }}</span>
          </div>
          <div>
            <label for="account-type">
              <span>Account type</span>
            </label>
            <span>{{ bankAccount.accountType }}</span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BankAccountData',
  props: { bankAccounts: Array, identityType: String },
  inject: ['$validator'],
  computed: {
    modelValidations() {
      return {
        accountLabel: { required: true },
        nameOnAccount: { required: true },
        accountType: { required: true },
        accountRouting: { required: true, min: 9, max: 9, numeric: true },
        accountNumber: { required: true, min: 5, max: 17, numeric: true }
      };
    }
  },
  methods: {
    handleEditClick() {
      this.$router.push({ name: 'BankForm' }).catch(()=>{});
    },
    async validate() {
      const validations = this.modelValidations;

      let isValid = true;
      const errors = {};

      const validateField = async (field, rules) => {
        try {
          const value = this.bankAccounts[0][field];

          // Handle obfuscated values for accountRouting and accountNumber
          if ((field === 'accountRouting' || field === 'accountNumber') && typeof value === 'string' && value.includes('*')) {
            // For obfuscated values, we'll only validate the visible part
            const visiblePart = value.replace(/\*/g, '');
            if (visiblePart.length > 0 && /^\d+$/.test(visiblePart)) {
              // If the visible part is numeric, consider it valid
              return;
            }
          }

          const result = await this.$validator.verify(value, rules, { name: field });
          if (!result.valid) {
            isValid = false;
            errors[field] = result.errors[0];
            console.error(`Validation failed for ${field}:`, result.errors[0]);
          }
        } catch (error) {
          console.error(`Error validating field ${field}:`, error);
          isValid = false;
          errors[field] = 'Validation error occurred';
        }
      };

      try {
        for (const [field, rules] of Object.entries(validations)) {
          await validateField(field, rules);
        }

        if (!isValid) {
          console.error('Bank account data validation errors:', errors);
        }

        return { isValid, errors };
      } catch (error) {
        console.error('An error occurred during validation:', error);
        return { isValid: false, errors: { general: 'Validation process failed' } };
      }
    }
  }
};
</script>
