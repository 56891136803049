import { DateTime } from 'luxon';

export const mustHaveStreetNumber = {
  name: 'mustHaveStreetNumber',
  obj: {
    getMessage: () => 'Please enter street number at the begining',
    validate: val => {
      if (!val) return false;
      return !isNaN(val.split(' ')[0]);
    }
  }
};

export const mustHaveStreetName = {
  name: 'mustHaveStreetName',
  obj: {
    getMessage: () => 'Please enter street name',
    validate: val => {
      if (!val) return false;
      return !!val.split(' ')[1];
    }
  }
};

export const validUrl = {
  name: 'validUrl',
  obj: {
    getMessage: () => 'Please enter a valid URL',
    validate: url => {
      if (!url) return false;
      if (url.trim() === '') return true;

      // Remove leading/trailing whitespace
      url = url.trim();

      // Check if the URL already starts with http:// or https://
      if (!url.startsWith('http://') && !url.startsWith('https://')) {
        url = `http://${url}`;
      }

      const res = url.match(
        /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w-]+)+[\w\-_~:/?#[\]@!$&'()*+,;=]+$/gm
      );
      return res !== null;
    }
  }
};

export const dateLessThanTomorrow = {
  name: 'dateLessThanTomorrow',
  obj: date => DateTime.fromFormat(date, ['MM-dd-yyyy', 'yyyy-MM-dd'], { zone: 'utc' })
    .startOf('day')
    .isBefore(DateTime.now().plus({ days: 1 }).startOf('day'))
};

export const validDateFormat = {
  name: 'validDateFormat',
  obj: date => DateTime.fromFormat(date, ['MM-dd-yyyy', 'yyyy-MM-dd'], { zone: 'utc' })
    .isValid
};

export const validBirthDate = {
  name: 'validBirthDate',
  obj: date => {
    const diff = DateTime.now().diff(
      DateTime.fromFormat(date, ['MM-dd-yyyy', 'yyyy-MM-dd'], { zone: 'utc' }),
      'years'
    ).years;
    return diff >= 18 && diff <= 100;
  }
};

export const validzipcodeRegex = /^\d{5}$/;

export const validfederalTaxId = /^\d{2}-?\d{7}$/;

export const validSSN = /^\d{3}-?\d{2}-?\d{4}$/;

export const validPhoneFormat = {
  name: 'validPhoneFormat',
  obj: {
    getMessage: () => 'Please enter a valid phone',
    validate: val => {
      if (!val) return false;
      return val !== 'invalid format';
    }
  }
};
