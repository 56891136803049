export default datas => async (to, from, next) => {
  const { meta } = to;
  if (!meta) return next();
  const { middleware } = meta;
  if (!middleware) return next();
  const { length } = meta;
  if (length < 1) return next();

  const arr = to.meta.middleware;

  for (let index = 0; index < arr.length; index++) {
    const method = arr[index];
    const result = method({ ...datas, to, from, next });
    if (result === false) {
      break;
    }
  }
  return;
};
