<template>
  <div
    class="modal custom-modal fade"
    id="terms-of-service"
    tabindex="-1"
    role="dialog"
    aria-labelledby="terms-of-service"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">
            {{ facilitatorName }} General Terms of Service Agreement
          </h4>
          <button
            type="button"
            class="close close-custom"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="privacy-content text-justify">
            <div class="container">
              Last updated: August 31, 2023<br />
              <p>
                These Terms of Services are a legal agreement (“Agreement”) between Jupi Co dba
                {{ facilitatorName }}(”{{ facilitatorName }}”, “we,” “our” or “us”) and
                the person or entity (“you,” “your”, or “user”) who registered on the
                {{ facilitatorName }} Account page to receive and use, directly or
                indirectly, our payment processing services, website, any software,
                programs, documentation, tools, hardware, internet-based Services,
                components, and any updates (including software maintenance, Services
                information, help content, bug fixes or maintenance releases) (each, a
                “Services”). You may not access or use any Services unless you agree to
                abide by all of the terms and conditions in this Agreement.
                {{ facilitatorName }} offers the Services through integrations with
                third-party websites (“Platforms”). The Platform through which you access
                the Services might have its own terms of Services and privacy policy that
                apply to you. This Agreement incorporates by reference all policies,
                notices, and other content that appear on our website at www.jupico.com as
                well as policies, notices, and other content concerning the Services that
                appear on the Platform's website (collectively, the “Website”).
              </p>
              <h5 class="terms-section-title">SECTION A. General Terms</h5>
              <p>
                This Agreement provides a general description of the Services that
                {{ facilitatorName }} may provide to you, including those that allow you
                to accept payments from purchasers of your goods or services or donors to
                your organization (your “Customers”). If you are using the Services on
                behalf of a business, you represent to us that you have authority to bind
                that business or entity to these terms, and that business accepts these
                terms. By using any of the Services, you agree to this Agreement and any
                policies referenced within (“Policies”), including terms that limit our
                liability (see Section 18) and require individual arbitration for any
                potential legal dispute (see Section 20). You also agree to the payment
                processing services terms (“Section C”) in Section B, which become part of
                your agreement with us (collectively, the “Terms”). You should read all of
                our terms carefully before using the Services.
              </p>
              <h6 class="terms-section-item">
                1. {{ facilitatorName }} Account Registration
              </h6>
              <p>
                You must open an account with us (a “{{ facilitatorName }} Account”) to
                use the Services. During registration we will ask you for information,
                which may include but is not limited to, your name and other personal
                information. You must provide accurate and complete information in
                response to our questions, and you must keep that information current. You
                are fully responsible for all activity that occurs under your
                {{ facilitatorName }} Account, including for any actions taken by persons
                to whom you have granted access to the {{ facilitatorName }} Account. We
                reserve the right to change the account type, suspend or terminate the
                {{ facilitatorName }} Account of anyone who provides inaccurate, untrue,
                or incomplete information, or who fails to comply with the account
                registration requirements.
              </p>
              <h6 class="terms-section-item">2. Revisions</h6>
              <p>
                We may amend the Agreement at any time with notice that we deem to be
                reasonable under the circumstances, by posting the revised version on our
                website or communicating it to you through the Services (each a “Revised
                Version”). The Revised Version will be effective as of the time it is
                posted but will not apply retroactively. Your continued use of the
                Services after the posting of a Revised Version constitutes your
                acceptance of such Revised Version. Any Dispute (as defined in Section 20)
                that arose before the changes will be governed by the Agreement in place
                when the Dispute arose.
              </p>
              <h6 class="terms-section-item">3. E-Sign Consent and Communications</h6>
              <p>
                a.
                <span class="terms-sub-section-title">Electronic Delivery:</span>
                By accepting this Agreement, you agree and consent to receive
                electronically all communications agreements, documents, notices and
                disclosures (collectively, “Communications”) that we or the Platform
                provide in connection with your {{ facilitatorName }} Account and your use
                of the Services. Communications include but are not limited to: (1)
                agreements and policies, such as this Agreement and our Privacy Policy,
                including updates thereto; (2) payment authorizations and transaction
                receipts or confirmations, (3) account statements and history, (4) and all
                federal and state tax statements and documents (we may, but are not
                obligated to, send tax-related information electronically). We may also
                use electronic signatures and obtain them from you; (5) annual
                disclosures; (6) communication in relation to delinquent accounts (which
                may also be by phone, and may be made by {{ facilitatorName }} or by
                anyone on its behalf, including a third-party collection agent); and (7)
                requests for {{ facilitatorName }} Account's secondary authentication,
                reminders, notifications regarding updates to your
                {{ facilitatorName }} Account and its support. <br /><br />
                We or the Platform will provide these Communications to you by emailing
                them to you at the primary email address listed in your
                {{ facilitatorName }} Account registration, by texting them to you at the
                primary telephone number listed in your {{ facilitatorName }} Account
                registration, by emailing or texting you a link or instructions how to
                access them on a website, or (if permitted by law) by posting them on the
                Website. We or the Platform may also provide certain Communications (such
                as federal and state tax statements) by U.S. mail to the street address
                listed in your Account registration. Communications are considered
                received by you within 24 hours of the time they are emailed to you,
                posted to the Website, or mailed to you. You further agree that your
                electronic signature has the same effect as your manual, physical
                signature.
              </p>
              <p>
                Notices to Jupi Co must
                <span style="font-weight: bold !important">
                  be sent via registered mail, postage prepaid, return receipt requested,
                  to Jupi Co
                </span>
                , 5830 Granite Pkwy #100-238 Plano, TX 75024, USA, Attn: Customer Support
                - Legal.
              </p>
              <p>
                b.
                <span class="terms-sub-section-title">System Requirements:</span>
                To access and retain the electronic Communications, you will need the
                following: (1) a computer or mobile device with Internet or mobile
                connectivity. (2) for desktop website-based Communications a modern web
                browser that includes 256-bit encryption, such as the current version of
                Chrome (<a href="https://www.google.com/chrome" target="_blank"
                  >www.google.com/chrome</a
                >), Internet Explorer (<a
                  href="https://www.microsoft.com/edge"
                  target="_blank"
                  >www.microsoft.com/edge</a
                >), Mozilla Firefox (<a href="https://www.mozilla.com" target="_blank"
                  >www.mozilla.com</a
                >), or Apple Safari (<a
                  href="https://www.apple.com/safari"
                  target="_blank"
                  >www.apple.com/safari</a
                >); (3) for application-based Communications, a recent device operating
                system that supports text messaging, downloading, and applications from
                the Apple App Store or Google Play store; and the most recent versions of
                Apple Safari or Google Chrome on iOS or Google Chrome for Android OS; (4)
                access to the email address used to create the
                {{ facilitatorName }} Account; and (5) sufficient storage space to save
                Communications and/or a printer to print them. <br /><br />
                By giving your consent, you are confirming that you have access to the
                necessary equipment and are able to receive, open, and print or download a
                copy of any Communications for your records. It is important for you to
                retain copies of Communications because they may not be accessible in your
                {{ facilitatorName }} Account at a later date.
              </p>
              <p>
                c.
                <span class="terms-sub-section-title"
                  >Requesting Paper Copies of Electronic Communications:</span
                >
                You have the right to receive Communications in paper form. To request a
                paper copy of any Communication at no charge, please write to
                {{ facilitatorName }}, 5830 Granite Pkwy #100-238 Plano, TX 75024, USA,
                Attn: Customer Support – Legal (“{{ facilitatorName }} Address”) within
                180 days of the date of the Disclosure, specifying in detail the
                Communication you would like to receive.
              </p>
              <p>
                d.
                <span class="terms-sub-section-title"
                  >Updating Your Contact Information:
                </span>
                It is your responsibility to keep your primary email and street address up
                to date. You understand and agree that if {{ facilitatorName }} or the
                Platform sends you a Communication but you do not receive it because your
                primary email or street address on file is incorrect, out of date, blocked
                by your service provider, or you are otherwise unable to receive
                electronic Communications, {{ facilitatorName }} or the Platform will be
                deemed to have provided the Communication to you. Please note that if you
                use a spam filter that blocks or re-routes emails from senders not listed
                in your email address book, you must add {{ facilitatorName }} or the
                Platform to your email address book so that you will be able to receive
                the Communications we send to you. If your email address becomes invalid
                such that electronic Communications sent to you by
                {{ facilitatorName }} or the Platform are returned, then
                {{ facilitatorName }} or the Platform may close your
                {{ facilitatorName }} Account, and you will not be able to transact any
                activity using your {{ facilitatorName }} Account until we receive a
                valid, functioning primary email address from you.
              </p>
              <p>
                e.
                <span class="terms-sub-section-title"
                  >How to Withdraw Your Consent:
                </span>
                You may withdraw your consent to receive electronic Communications at any
                time, by writing to the {{ facilitatorName }} Address. However, withdrawal
                of your consent to receive electronic Communications may result in
                termination of your access to Services. Any withdrawal of your consent
                will be effective after a reasonable period of time for processing your
                request.
              </p>
              <h6 class="terms-section-item">4. Your Relationship with Your Customers</h6>
              <p>
                You may only use the Services for legitimate Transactions with your
                Customers. You know your Customers better than we do, and you are
                responsible for your relationship with them. {{ facilitatorName }} is not
                responsible for the products or services you publicize or sell, or that
                your Customers purchase using the Services; or if you accept donations,
                for your communication to your Customers of the intended use of such
                donations. You affirm that you are solely responsible for the nature and
                quality of the products or services you provide, and for delivery,
                support, refunds, returns, and for any other ancillary services you
                provide to your Customers. <br /><br />
                {{ facilitatorName }} provides Services to you, but we have no way of
                knowing if any particular purchase, sale, donation, order, or other
                transaction (each a “Transaction”) is accurate or complete, or typical for
                your business. You are responsible for knowing whether a Transaction
                initiated by your Customer is erroneous (such as a Customer purchasing one
                item when they meant to order another) or suspicious (such as unusual or
                large purchases, or a request for delivery to a foreign country where this
                typically does not occur). If you are unsure if a Transaction is erroneous
                or suspicious, you agree to research the Transaction and, if necessary,
                contact your Customer before fulfilling or completing the Transaction. You
                are solely responsible for any losses you incur due to erroneous or
                fraudulent Transactions in connection with your use of the Services.
              </p>
              <h6 class="terms-section-item">
                5. Service Requirements, Limitations and Restrictions
              </h6>
              <p>
                a.
                <span class="terms-sub-section-title"
                  >Compliance with Applicable Laws:</span
                >You must use the Services in a lawful manner, and must obey all laws,
                rules, regulatory guidance, industry best practices and regulations
                (“Laws”) applicable to your use of the Services and to Transactions. As
                applicable, this may include compliance with domestic and international
                Laws related to the use or provision of financial services, notification
                and consumer protection, unfair competition, privacy, and false
                advertising, and any other Laws relevant to Transactions.
              </p>
              <p>
                b.
                <span class="terms-sub-section-title"
                  >Restricted Businesses and Activities:</span
                >
                You may not use the Services to enable any person (including you) to
                benefit from any activities {{ facilitatorName }} has identified as a
                restricted business or activity (collectively, “Restricted Businesses”).
                Restricted Businesses include use of the Services in or for the benefit of
                a country, organization, entity, or person embargoed or blocked by any
                government, including those on sanctions lists identified by the
                <a
                  href="https://www.treasury.gov/resource-center/sanctions"
                  target="_blank"
                >
                  United States Office of Foreign Asset Control (OFAC). </a
                ><br /><br />
                Please review the list of Restricted Businesses thoroughly before
                registering for and opening a {{ facilitatorName }} Account. If you are
                uncertain whether a category of business or activity is restricted or have
                questions about how these restrictions apply to you, please contact us. We
                may add to or update the Restricted Business List at any time.
              </p>
              <p>
                c.
                <span class="terms-sub-section-title">Other Restricted Activities:</span>
                You may only use the Services for business purposes. You must not, and
                must not enable or allow any third party to: (i) use the Services for
                personal, family or household purposes; (ii) access or attempt to access
                non-public
                {{ facilitatorName }} systems, programs, data, or services; (iii) copy,
                reproduce, republish, upload, post, transmit, resell, or distribute in any
                way, any data, content, or any part of the Services, Documentation, or our
                website except as expressly permitted by applicable Laws; (iv) act as
                service bureau or pass-through agent for the Services with no added value
                to Customers; (v) transfer any rights granted to you under this Agreement;
                (vi) work around any of the technical limitations of the Services or
                enable functionality that is disabled or prohibited; (vii) reverse
                engineer or attempt to reverse engineer the Services except as expressly
                permitted by Laws; (viii) perform or attempt to perform any actions that
                would interfere with the normal operation of the Services or affect use of
                the Services by our other users; or (ix) impose an unreasonable or
                disproportionately large load on the Service.
              </p>
              <p>
                d.
                <span class="terms-sub-section-title">
                  Suspicion of Unauthorized or Illegal Use:</span
                >
                If we reasonably suspect that your {{ facilitatorName }} Account has been
                used for an unauthorized, illegal, or criminal purpose, you give us
                express authorization to share information about you, your
                {{ facilitatorName }} Account, and any of your Transactions with law
                enforcement.
              </p>
              <h6 class="terms-section-item">
                6. Services and {{ facilitatorName }} Account Support
              </h6>
              <p>
                We will provide you with support to resolve general issues relating to
                your {{ facilitatorName }} Account and your use of the Services. This
                support includes resources and documentation that we make available to you
                through the current versions of {{ facilitatorName }}'s support pages, API
                documentation, and other pages on our website (collectively,
                “Documentation”). The most efficient way to get answers to your questions
                is to review our Documentation. If you still have questions after
                reviewing the Documentation, please contact us. <br /><br />
                You are solely responsible for providing support to Customers regarding
                Transaction receipts, product or service delivery, support, returns,
                refunds, and any other issues related to your products and services and
                business activities. We are not responsible for providing support for the
                Services to your Customers unless we agree to do so in a separate
                agreement with you or one of your Customers. <br /><br />
                We do not warrant that the Services will be compatible with your mobile
                device or carrier. Your use of the Services may be subject to the terms of
                your agreements with your mobile device manufacturer or your carrier. You
                may not use a modified device to use the Services if the modification is
                contrary to the manufacturer's software or hardware guidelines, including
                disabling hardware or software controls.
              </p>
              <h6 class="terms-section-item">7. Service Updates and Modifications</h6>
              <p>
                Jupi Co reserves the right to make changes to the Services at its
                discretion. These changes may include the addition or removal of features
                or the introduction of new usage conditions. In the event of significant
                adverse changes, deprecations, or removal of functionality that affects
                the Services you are currently utilizing, Jupi Co will inform you
                promptly.
              </p>
              <h6 class="terms-section-item">8. Your Content</h6>
              <p>
                You may use the Services to upload or publish text, images, and other
                content (collectively, “Content”) to your {{ facilitatorName }} Account
                and to third-party sites or applications but only if you agree to obtain
                the appropriate permissions and, if required, licenses to upload or
                publish any such Content using the Services. You agree to fully reimburse
                {{ facilitatorName }} for all fees, fines, losses, claims, and any other
                costs we may incur that arise from publishing illegal Content through the
                Services, or claims that Content you published infringes the intellectual
                property, privacy, or other proprietary rights of others. <br /><br />
                You grant us and our subsidiaries, affiliates, and successors a worldwide,
                non-exclusive, royalty-free, fully-paid, transferable, irrevocable,
                perpetual, and sub-licensable right to use, reproduce, modify, adapt,
                publish, prepare derivative works of, distribute, publicly perform, and
                publicly display your Content throughout the world in any media for any
                reason, including to provide, promote, and/or incorporate into the
                Services. You retain all rights in your Content, subject to the rights you
                granted to us in this Agreement. You may modify or remove your Content via
                your {{ facilitatorName }} Account or by terminating your
                {{ facilitatorName }} Account, but your Content may persist in historical,
                archived or cached copies and versions thereof available on or through the
                Services. <br /><br />
                You will not upload or provide Content or otherwise post, transmit,
                distribute, or disseminate through the Services any material that: (a) is
                false, misleading, unlawful, obscene, indecent, lewd, pornographic,
                defamatory, libelous, threatening, harassing, hateful, abusive, or
                inflammatory; (b) encourages conduct that would be considered a criminal
                offense or gives rise to civil liability; (c) breaches or infringes any
                duty toward or rights of any person or entity, including rights of
                publicity, privacy or intellectual property; (d) contains corrupted data
                or any other harmful, disruptive, or destructive files; (e) advertises
                products or Services competitive with
                {{ facilitatorName }} 's or its partners' products and Services, as
                determined by us in our sole discretion; or (f) in our sole judgment, is
                objectionable, restricts or inhibits any person or entity from using or
                enjoying any portion of the Services, or which may expose
                {{ facilitatorName }}, its affiliates or its customers to harm or
                liability of any nature. <br /><br />
                Although we have no obligation to monitor any Content, we have absolute
                discretion to remove Content at any time and for any reason without
                notice. {{ facilitatorName }} may also monitor such Content to detect and
                prevent fraudulent activity or violations of this Agreement. You
                understand that by using the Services, you may be exposed to Content that
                is offensive, indecent, or objectionable. We take no responsibility and
                assume no liability for any Content, including any loss or damage to any
                of your Content.
              </p>
              <h6 class="terms-section-item">9. Intellectual Property Infringement</h6>
              <p>
                We respect the intellectual property rights of others and ask you to do
                the same. We have adopted an Intellectual Property Policy regarding
                third-party claims that your material infringes the rights of others. We
                respond to all valid notices of such infringement, and our policy is to
                suspend or terminate the accounts of repeat infringers.
              </p>
              <h6 class="terms-section-item">10. Security</h6>
              <p>
                We have implemented technical and organizational measures designed to
                secure your personal information from accidental loss and from
                unauthorized access, use, alteration, or disclosure. However, we cannot
                guarantee that unauthorized third parties will never be able to defeat
                those measures or use your personal information for improper purposes. You
                provide your personal information at your own risk. <br /><br />
                You are responsible for safeguarding your password and for restricting
                access to the Services from your compatible mobile devices and
                computer(s). You will immediately notify us of any unauthorized use of
                your password or {{ facilitatorName }} Account or any other breach of
                security. Notwithstanding Sections 21, in the event of any dispute between
                two or more parties as to account ownership, we will be the sole arbiter
                of such dispute in our sole discretion. Our decision (which may include
                termination or suspension of any {{ facilitatorName }} Account subject to
                dispute) will be final and binding on all parties.
              </p>
              <h6 class="terms-section-item">11. Privacy Policy</h6>
              <p>
                When you process information that identifies or is reasonably capable of
                identifying an individual to {{ facilitatorName }} in connection with the
                Services (including information collected by {{ facilitatorName }} on your
                behalf), you agree to comply with applicable laws regarding the
                collection, use, disclosure, protection, and retention of this
                information. You acknowledge that you have reviewed and consent to
                {{ facilitatorName }}'s Privacy Notice, which explains how we collect, use
                and protect the personal information you provide to us.
              </p>
              <h6 class="terms-section-item">12. Paid Services</h6>
              <p>
                {{ facilitatorName }} may offer Services to be paid for on a recurring
                basis (“Subscription Services”) or on an as-used basis (“A La Carte
                Services” and, together with the Subscription Services, “Paid Services”).
                Subscription Services may subject you to recurring fees and/or terms. By
                signing up for a Subscription Services, including after any free trial
                period, you agree to pay us the subscription fee and any applicable taxes
                as set forth in your {{ facilitatorName }} Account settings or as
                otherwise agreed in writing (“Subscription Fee”). A La Carte Services may
                subject you to fees charged per usage and/or terms. By using an A La Carte
                Services, you agree to pay the fees and any taxes incurred at the time of
                usage (“A La Carte Fees” and, together with Subscription Fees, the “Paid
                Services Fees”). <br /><br />
                Paid Services Fees may be paid by debit card, credit card, or deducted
                from your transaction proceeds. If you link a debit or credit card to your
                account, you authorize us to collect Paid Services Fees by debit from your
                linked debit card or charge to your linked credit card. Regardless of
                payment device, we reserve the right to collect Paid Services Fees by
                deduction from your transaction proceeds, the Balance (as defined in the
                Section C) in your {{ facilitatorName }} Account or your linked bank
                account.<br /><br />
                Unless otherwise provided in a Subscription Service's terms, Subscription
                Fees will be charged on the 1st of every month until cancelled. You may
                cancel a Subscription Services at any time from your
                {{ facilitatorName }} Account settings. If you cancel a Subscription
                Services, you will continue to have access to that Subscription Services
                through the end of your then current billing period, but you will not be
                entitled to a refund or credit for any Subscription Fee already due or
                paid. We reserve the right to change our Subscription Fee upon thirty (30)
                days' advance notice. Your continued use of Subscription Services after
                notice of a change to our Subscription Fee will constitute your agreement
                to such changes.
              </p>
              <h6 class="terms-section-item">13. Termination</h6>
              <p>
                a.
                <span class="terms-sub-section-title">Term and Termination:</span>We may
                terminate this Agreement or suspend or terminate your
                {{ facilitatorName }} Account or your access to any Services, at any time
                for any reason. We may add or remove, suspend, stop, delete, discontinue
                or impose conditions on Services or any feature or aspect of a Services.
                We will take reasonable steps to notify you of termination or these other
                types of Services changes by email or at the next time you attempt to
                access your {{ facilitatorName }}
                Account. You may also terminate the Agreement applicable to your
                {{ facilitatorName }} Account by contacting support@jupico.com at any
                time. If you use the Services again or register for another
                {{ facilitatorName }} Account, you are consenting to this Agreement.
              </p>
              <p>
                b.
                <span class="terms-sub-section-title">Effects of Termination:</span>
                If this Agreement or your {{ facilitatorName }} Account is terminated or
                suspended for any reason: (a) the license and any other rights granted
                under these Agreement will end, (b) we may (but have no obligation to)
                delete your information and account data stored on our servers, and (c) we
                will not be liable to you or any third party for compensation,
                reimbursement, or damages for any termination or suspension of the
                Services, or for deletion of your information or account data. In addition
                to any payment obligations under the Payment Processing Terms, the
                following sections of this Agreement survive and remain in effect in
                accordance with their terms upon termination: 8 (Your Content), 9
                (Intellectual Property Infringement), 13(b) (Effect of Termination), 15
                (Ownership), 16 (Indemnity), 17 (Representations and Warranties), 18 (No
                Warranties), 19 (Limitation of Liability and Damages), 20 (Third Party
                Products), 21 (Disputes), 22 (Limitation on Time to Initiate a Dispute),
                23 (Binding Individual Arbitration), 24 (Governing Law), 25 (Assignment),
                26 (Third Party Services and Links to Other Web Sites), and 27 (Entire
                Agreement). <br /><br />
                In addition, upon termination you understand and agree that (i) all
                licenses granted to you by {{ facilitatorName }} under this Agreement will
                end; (ii) we reserve the right (but have no obligation) to delete all of
                your information and account data stored on our servers; (iii) we will not
                be liable to you for compensation, reimbursement, or damages related to
                your use of the Services, or any termination or suspension of the Services
                or deletion of your information or account data; and (iv) you are still
                liable to us for any Fees or fines, or other financial obligation incurred
                by you or through your use of the Services prior to termination.
              </p>
              <h6 class="terms-section-item">14. Your License</h6>
              <p>
                We grant you a limited, non-exclusive, revocable, non-transferable,
                non-sublicensable license to use the software that is part of the
                Services, as authorized in this Agreement. We may make software updates to
                the Services available to you, which you must install to continue using
                the Services. Any such software updates may be subject to additional terms
                made known to you at that time. <br /><br />
                You may not: (i) claim or register ownership of {{ facilitatorName }} IP
                on your behalf or on behalf of others; (ii) sublicense any rights in
                {{ facilitatorName }} IP granted by us; (iii) import or export any
                {{ facilitatorName }} IP to a person or country in violation of any
                country's export control Laws; (iv) use {{ facilitatorName }} IP in a
                manner that violates this Agreement or Laws; or (v) attempt to do any of
                the foregoing.
              </p>
              <h6 class="terms-section-item">15. Ownership</h6>
              <p>
                We reserve all rights not expressly granted to you in this Agreement. We
                own all rights, title, interest, copyright and other
                {{ facilitatorName }} IP (as defined below) in the Services and all copies
                of the Services. This Agreement do not grant you any rights to our
                trademarks or Services marks. <br /><br />
                You own all your Jupi Co Account data, as described in section 8. You are
                solely responsible for the accuracy, quality, content, and legality
                (including compliance with all Applicable Law and Rules) of the Jupi Co
                Account Data. You represent, warrant and covenant that you have all rights
                necessary to upload your Content to the Services and to otherwise have
                such data used or shared, as applicable, by Jupi Co in relation to the
                Services. You will not upload or store any data or materials containing
                any such information in violation of this section and section 8.
                <br /><br />
                You acknowledge and agree that, while providing the Services to you, both
                Jupi Co and you will collect, store, and use Customer Data. To the extent
                you use of the Customer Data outside the scope described in this Agreement
                or within Jupi Co's Privacy Statement, you shall be solely responsible for
                putting in place any additional controls and governance in relation to
                such use. For the purposes of this Agreement, “{{ facilitatorName }} IP”
                means all patent rights, copyright rights, mask work rights, moral rights,
                rights of publicity, trademark, trade dress and Services mark rights,
                goodwill, trade secret rights, and other intellectual property rights that
                may exist now or come into existence in the future, and all of their
                applications, registrations, renewals and extensions, under the laws of
                any state, country, territory or other jurisdiction.<br /><br />
                You may submit comments or ideas about the Services (“Ideas”) to
                support@jupico.com. By submitting any Idea, you agree that your disclosure
                is gratuitous, unsolicited, and without restriction, that it will not
                place us under any fiduciary, confidentiality or other obligation, and
                that we are free to use the Idea without any additional compensation to
                you, and/or to disclose the Idea on a non-confidential basis or otherwise
                to anyone.
              </p>
              <h6 class="terms-section-item">16. Indemnity</h6>
              <p>
                You will indemnify, defend, and hold us and our processors (and our
                respective employees, directors, agents, affiliates and representatives)
                harmless from and against any and all claims, costs, losses, damages,
                judgments, tax assessments, penalties, interest, and expenses (including
                without limitation reasonable attorneys' fees) arising out of any claim,
                action, audit, investigation, inquiry, or other proceeding instituted by a
                person or entity that arises out of or relates to: (a) any actual or
                alleged breach of your representations, warranties, or obligations set
                forth in these Agreement; (b) your wrongful or improper use of the
                Services; (c) your violation of any third-party right, including without
                limitation any right of privacy, publicity rights or Intellectual Property
                Rights; (d) your violation of any law, rule or regulation of the United
                States or any other country; and (e) any other party's access and/or use
                of the Services with your unique name, password or other appropriate
                security code.
              </p>
              <h6 class="terms-section-item">17. Representations and Warranties</h6>
              <p>
                By accepting the terms of this Agreement, you represent and warrant that:
                (a) you are eligible to register and use the Services and have the
                authority to execute and perform the obligations required by this
                Agreement; (b) any information you provide us about your business,
                products, or services is accurate and complete; (c) any Charges represent
                a Transaction for permitted products, services, or donations, and any
                related information accurately describes the Transaction; (d) you will
                fulfill all of your obligations to Customers and will resolve all Disputes
                with them; (e) you will comply with all Laws applicable to your business
                and use of the Services; (f) your employees, contractors and agents will
                at all times act consistently with the terms of this Agreement; (g) you
                will not use payment processing services for personal, family or household
                purposes, for peer-to-peer money transmission, or (except in the normal
                course of business) intercompany Transactions; and (h) you will not use
                the Services, directly or indirectly, for any fraudulent or illegal
                undertaking, or in any manner that interferes with the normal operation of
                the Services.
              </p>
              <h6 class="terms-section-item">18. No Warranties</h6>
              <p>
                WE PROVIDE THE SERVICES AND {{ facilitatorUppercaseName }} IP “AS IS” AND
                “AS AVAILABLE”, WITHOUT ANY EXPRESS, IMPLIED, OR STATUTORY WARRANTIES OF
                TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NONINFRINGEMENT,
                OR ANY OTHER TYPE OF WARRANTY OR GUARANTEE. NO DATA, DOCUMENTATION OR ANY
                OTHER INFORMATION PROVIDED BY {{ facilitatorUppercaseName }} OR OBTAINED
                BY YOU FROM OR THROUGH THE SERVICES –FROM {{ facilitatorUppercaseName }},
                AND WHETHER ORAL OR WRITTEN – CREATES OR IMPLIES ANY WARRANTY FROM
                {{ facilitatorUppercaseName }} TO YOU.YOU AFFIRM THAT
                {{ facilitatorUppercaseName }} DOES NOT CONTROL THE PRODUCTS OR SERVICES
                THAT YOU OFFER OR SELL OR THAT YOUR CUSTOMERS PURCHASE USING THE PAYMENT
                PROCESSING SERVICES. YOU UNDERSTAND THAT WE CANNOT GUARANTEE AND WE
                DISCLAIM ANY KNOWLEDGE THAT YOUR CUSTOMERS POSSESS THE AUTHORITY TO MAKE,
                OR WILL COMPLETE, ANY TRANSACTION.{{ facilitatorUppercaseName }} DISCLAIM
                ANY KNOWLEDGE OF, AND DO NOT GUARANTEE: (a) THE ACCURACY, RELIABILITY, OR
                CORRECTNESS OF ANY DATA PROVIDED THROUGH THE SERVICES; (b) THAT THE
                SERVICES WILL MEET YOUR SPECIFIC BUSINESS NEEDS OR REQUIREMENTS; (c) THAT
                THE SERVICES WILL BE AVAILABLE AT ANY PARTICULAR TIME OR LOCATION, OR WILL
                FUNCTION IN AN UNINTERRUPTED MANNER OR BE SECURE; (d) THAT
                {{ facilitatorUppercaseName }} WILL CORRECT ANY DEFECTS OR ERRORS IN THE
                SERVICE, API, DOCUMENTATION, OR DATA; OR (e) THAT THE SERVICES ARE FREE OF
                VIRUSES OR OTHER HARMFUL CODE. USE OF DATA YOU ACCESS OR DOWNLOAD THROUGH
                THE SERVICES IS DONE AT YOUR OWN RISK – YOU ARE SOLELY RESPONSIBLE FOR ANY
                DAMAGE TO YOUR PROPERTY, LOSS OF DATA, OR ANY OTHER LOSS THAT RESULTS FROM
                SUCH ACCESS OR DOWNLOAD. YOU UNDERSTAND THAT THE
                {{ facilitatorUppercaseName }} ENTITIES MAKE NO GUARANTEES TO YOU
                REGARDING TRANSACTION PROCESSING TIMES OR PAYOUT SCHEDULES. <br /><br />
                NOTHING IN THIS AGREEMENT OPERATES TO EXCLUDE, RESTRICT OR MODIFY THE
                APPLICATION OF ANY IMPLIED CONDITION, WARRANTY OR GUARANTEE, OR THE
                EXERCISE OF ANY RIGHT OR REMEDY, OR THE IMPOSITION OF ANY LIABILITY UNDER
                LAW WHERE TO DO SO WOULD: (A) CONTRAVENE THAT LAW; OR (B) CAUSE ANY TERM
                OF THIS AGREEMENT TO BE VOID.
              </p>
              <h6 class="terms-section-item">19. Limitations of Liability and Damages</h6>
              <p>
                Under no circumstances will {{ facilitatorName }} be responsible or liable
                to you for any indirect, punitive, incidental, special, consequential, or
                exemplary damages resulting from your use or inability to use the Services
                or for the unavailability of the Services, for lost profits, personal
                injury, or property damage, or for any other damages arising out of, in
                connection with, or relating to this Agreement or your use of the
                Services, even if such damages are foreseeable, and whether or not you or
                {{ facilitatorName }} have been advised of the possibility of such
                damages. {{ facilitatorName }} is not liable, and deny responsibility for,
                any damages, harm, or losses to you arising from or relating to hacking,
                tampering, or other unauthorized access or use of the Services, your
                {{ facilitatorName }}
                Account, or data, or your failure to use or implement anti-fraud measures,
                security controls, or any other data security measure.
                {{ facilitatorName }} further deny responsibility for all liability and
                damages to you or others caused by (a) your access or use of the Services
                inconsistent with the Documentation; (b) any unauthorized access of
                servers, infrastructure, or data used in connection with the Services; (c)
                interruptions to or cessation of the Services; (d) any bugs, viruses, or
                other harmful code that may be transmitted to or through the Services; (e)
                any errors, inaccuracies, omissions, or losses in or to any data provided
                to us; (f) third-party content provided by you; or (g) the defamatory,
                offensive, or illegal conduct of others. <br /><br />
                You agree to limit any additional liability not disclaimed or denied by
                {{ facilitatorName }} under this Agreement to your direct and documented
                damages; and you further agree that under no circumstances will any such
                liability exceed in the aggregate the amount of fees paid by you to
                {{ facilitatorName }} during the three-month period immediately preceding
                the event that gave rise to your claim for damages. The limitation of
                liability in the preceding sentence will not apply to claims by you
                against {{ facilitatorName }} for failure to settle any payment processing
                proceeds received by {{ facilitatorName }} and owed to you in connection
                with the payment processing services; in the case of such a claim, you
                agree the {{ facilitatorName }}'s liability is limited to the amount of
                payment processing proceeds that {{ facilitatorName }} has received and
                owes to you, but failed to settle to you, in connection with the payment
                processing services. <br /><br />
                These limitations on our liability to you will apply regardless of the
                legal theory on which your claim is based, including contract, tort
                (including negligence), strict liability, or any other theory or basis.
                <br /><br />
                We provide the Services from facilities in the United States. We do not
                claim, and we cannot guarantee that Services we provide from the United
                States are or will be appropriate or available for any other location or
                jurisdiction, comply with the Laws of any other location or jurisdiction,
                or comply with Laws governing export, import, or foreign use.
              </p>
              <h6 class="terms-section-item">20. Third Party Products</h6>
              <p>
                All third-party hardware and other products included or sold with the
                Services are provided solely according to the warranty and other terms
                specified by the manufacturer, who is solely responsible for Services and
                support for its product. For Services, support, or warranty assistance,
                you should contact the manufacturer directly.
                {{ facilitatorUppercaseName }}
                MAKES NO REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT
                TO SUCH THIRD-PARTY PRODUCTS, AND EXPRESSLY DISCLAIMS ANY WARRANTY OR
                CONDITION OF MERCHANTABILITY, NON-INFRINGEMENT, OR FITNESS FOR A
                PARTICULAR PURPOSE.
              </p>
              <h6 class="terms-section-item">21. Disputes</h6>
              <p>
                “Disputes” are defined as any claim, controversy, or dispute between you
                and {{ facilitatorName }}, its processors, suppliers or licensors (or
                their respective affiliates, agents, directors or employees), including
                any claims relating in any way to these Agreement or the Services, or any
                other aspect of our relationship.
              </p>
              <h6 class="terms-section-item">
                22. Limitation on Time to Initiate a Dispute
              </h6>
              <p>
                Any action or proceeding by you relating to any Dispute must commence
                within one year after the cause of action accrues
              </p>
              <h6 class="terms-section-item">23. Binding Arbitration</h6>
              <p>
                You and {{ facilitatorName }} agree to arbitrate any and all Disputes by a
                neutral arbitrator who has the power to award the same individual damages
                and individual relief that a court can. ANY ARBITRATION UNDER THIS
                AGREEMENT WILL ONLY BE ON AN INDIVIDUAL BASIS; CLASS ARBITRATIONS, CLASS
                ACTIONS, REPRESENTATIVE ACTIONS, AND CONSOLIDATION WITH OTHER ARBITRATIONS
                ARE NOT PERMITTED. YOU WAIVE ANY RIGHT TO HAVE YOUR CASE DECIDED BY A JURY
                AND YOU WAIVE ANY RIGHT TO PARTICIPATE IN A CLASS ACTION AGAINST
                {{ facilitatorUppercaseName }}. If any provision of this arbitration
                agreement is found unenforceable, the unenforceable provision will be
                severed, and the remaining arbitration terms will be enforced (but in no
                case will there be a class or representative arbitration). All Disputes
                will be resolved finally and exclusively by binding individual arbitration
                with a single arbitrator (the “Arbitrator”) administered by the American
                Arbitration Association (<a href="https://www.adr.org" target="_blank"
                  >https://www.adr.org</a
                >) according to this Section and the applicable arbitration rules for that
                forum. The Arbitrator shall be responsible for determining all threshold
                arbitrability issues, including issues relating to the Agreement (or any
                aspect thereof) are enforceable, unconscionable or illusory and any
                defense to arbitration, including waiver, delay, laches, or estoppel.
                Subject to applicable jurisdictional requirements, you may elect to pursue
                your claim in your local small-claims court rather than through
                arbitration so long as your matter remains in small claims court and
                proceeds only on an individual (non-class or non-representative) basis.
                The Federal Arbitration Act, 9 U.S.C. §§ 1-16, fully applies. If you are a
                consumer bringing a claim relating to a transaction intended for a
                personal, household, or family use, any arbitration hearing will occur
                within the county where you reside. Otherwise, any arbitration hearing
                will occur in Collin County, Texas, or another mutually agreeable
                location. The arbitrator's award will be binding on the parties and may be
                entered as a judgment in any court of competent jurisdiction. While an
                arbitrator may award declaratory or injunctive relief, the Arbitrator may
                do so only with respect to the individual party seeking relief and only to
                the extent necessary to provide relief warranted by the individual party's
                claim. An Arbitrator's decision and judgment thereon will not have a
                precedential or collateral estoppel effect. {{ facilitatorName }} will
                reimburse the arbitration fees due to the American Arbitration Association
                for individual arbitrations brought in accordance with this section for
                all claims totaling less than $5,000 unless the Arbitrator determines that
                your claims were frivolous. If you prevail on any claim for which you are
                legally entitled to attorney's fees, you may seek to recover those fees
                from the arbitrator. For any claim where you are seeking relief, we will
                not seek to have you pay our attorney's fees, even if fees might otherwise
                be awarded, unless the Arbitrator determines that your claim was
                frivolous. For purposes of this arbitration provision, references to you
                and {{ facilitatorName }} also include respective subsidiaries,
                affiliates, agents, employees, predecessors, successors and assigns as
                well as authorized users or beneficiaries of the Services. Subject to and
                without waiver of the arbitration provisions above, you agree that any
                judicial proceedings (other than small claims actions in consumer cases as
                discussed above) will be brought in and you hereby consent to the
                exclusive jurisdiction and venue in the state courts in the County of
                Collin, Texas, or federal court for the Northern District of Texas.
              </p>
              <h6 class="terms-section-item">24. Governing Law</h6>
              <p>
                This Agreement and any Dispute will be governed by Texas law and/or
                applicable federal law (including the Federal Arbitration Act), without
                regard to its choice of law or conflicts of law principles.
              </p>
              <h6 class="terms-section-item">25. Assignment</h6>
              <p>
                This Agreement, and any rights and licenses granted hereunder, may not be
                transferred or assigned by you and any attempted transfer or assignment
                will be null and void.
              </p>
              <h6 class="terms-section-item">
                26. Third Party Services and Links to Other Websites
              </h6>
              <p>
                You may be offered Services, products and promotions provided by third
                parties and not by {{ facilitatorName }}, including, but not limited to,
                third party developers who use {{ facilitatorName }}'s Services (“Third
                Party Services”). If you decide to use Third Party Services, you will be
                responsible for reviewing and understanding the terms and conditions for
                these Services. We are not responsible or liable for the performance of
                any Third Party Services. Further, you agree to resolve any disagreement
                between you and a third party regarding the terms and conditions of any
                Third Party Services with that third party directly in accordance with the
                terms and conditions of that relationship, and not {{ facilitatorName }}.
                The Services may contain links to third party websites. The inclusion of
                any website link does not imply an approval, endorsement, or
                recommendation by {{ facilitatorName }}. Such third party websites are not
                governed by this Agreement. You access any such website at your own risk.
                We expressly disclaim any liability for these websites. When you use a
                link to go from the Services to a third party website, our Privacy Notice
                is no longer in effect. Your browsing and interaction on a third party
                website, including those that have a link in the Services is subject to
                that website's own terms, rules and policies.
              </p>
              <h6 class="terms-section-item">27. Entire Agreement</h6>
              <p>
                This Agreement and any applicable Policies are a complete statement of the
                agreement between you and {{ facilitatorName }} regarding the Services. In
                the event of a conflict between this Agreement and any other
                {{ facilitatorName }} agreement or Policy, this Agreement will prevail and
                control the subject matter of such conflict. If any provision of this
                Agreement is invalid or unenforceable under applicable law, then it will
                be changed and interpreted to accomplish the objectives of such provision
                to the greatest extent possible under applicable law, and the remaining
                provisions will continue in full force and effect. This Agreement does not
                limit any rights that we may have under trade secret, copyright, patent,
                or other laws. No waiver of any term of this Agreement will be deemed a
                further or continuing waiver of such term or any other term.
              </p>
              <h5 class="terms-section-title">SECTION B. Payment Processing Terms</h5>
              <p>
                {{ facilitatorName }} works with third party processing parties, Networks
                and Acquirer(s) to provide you with access to the Services you may use to
                accept payments from your Customers for Transactions, perform other
                financial transactions, manage subscriptions, and perform transaction
                reporting (“Payment Processing Services”). <br /><br />
                For purpose of this Agreement, an “Acquirer” means a financial institution
                that is authorized by Networks to enable the use of a payment method by
                accepting Charges from Customers on behalf of the respective Network and
                routing these Charges to the appropriate Network. “Charge” means a credit
                or debit instruction to capture funds from an account that a Customer
                maintains with a bank or other financial institution in connection with a
                Transaction. <br /><br />
                At {{ facilitatorName }}'s sole discretion, you may be offered Payment
                Processing Services provided by Stripe, Inc (“Stripe”). Payment Processing
                Services provided by Stripe are subject to the
                <a href="https://stripe.com/legal/connect-account"
                  >Stripe Connected Account Agreement</a
                >, which includes the
                <a href="https://stripe.com/legal/ssa">Stripe Terms of Service</a>
                (collectively, the “Stripe Services Agreement”) and subject to certain
                fees and surcharges communicated to you during the enrollment process and
                as may be updated by Jupi Co from time to time. By enrolling in the
                Payment Processing Services provided by Stripe, you agree to be bound by
                the Stripe Services Agreement, as the same may be modified by Stripe from
                time to time. Further, you agree to provide Jupi Co accurate and complete
                information about you and your business, and you authorize Jupi Co to
                share it and transaction information related to your use of the Payment
                Processing Services provided by Stripe pursuant to our Privacy Policy.
              </p>
              <h6 class="terms-section-item">1. Our Role</h6>
              <p>
                {{ facilitatorName }} is a payment facilitator that allows you to accept
                Cards from customers for the payment for goods and services. We are not a
                bank and do not offer banking services. Our Payment Processing Services
                allow you to accept payments from any US-issued and most non-US issued
                credit, debit, prepaid, or gift cards (“Cards”) bearing the trademarks of
                American Express Travel Related Services Company, Inc. (“American
                Express”), DFS Services, LLC (“Discover”), MasterCard International Inc.
                (“MasterCard”) and Visa Inc. (“Visa”) (collectively, the “Networks”). You
                are not required to accept any card brand as a condition of receiving the
                Payment Processing Services. We may remove or add Cards that we accept at
                any time without prior notice. <br /><br />
                In order to serve in this role, we must enter into agreements with
                Networks, processors and acquiring banks (each, a “Payment Method
                Provider”). A Payment Method Provider may terminate your ability to accept
                a Card at any time and for any reason, in which case you will no longer be
                able to accept that Card under this Agreement. Jupi Co may add, change or
                remove a Payment Provider at any time and for any reason without notice to
                you. Additionally, the Networks require that any person that signs up for
                a {{ facilitatorName }} Account to use Payment Processing Services (a
                “Seller”) and processes more than Network specified amounts of Cards sales
                enter into an agreement directly with {{ facilitatorName }}'s acquiring
                banks. By accepting or using the Payment Processing Services to submit
                Charges, you agree to the terms and conditions of the applicable
                <a
                  href="https://help.jupico.com/references-and-resources/legal/terms-of-service#_section_c._merchant"
                >
                  “Merchant Services Agreement”
                </a>
                effective as of the date you process such specified amounts or as
                otherwise required by {{ facilitatorName }}'s acquiring banks. See Section
                C for more details. If you fail to agree to the Merchant Services
                Agreement, we may suspend or terminate your {{ facilitatorName }} Account.
                Similarly, if the Networks consider you to be a high value customer, it
                may require that you maintain your agreement directly with American
                Express (and designate {{ facilitatorName }} as your agent for American
                Express payments) or the Acquirer Bank. If that is the case, we will
                notify you of such requirements.
              </p>
              <h6 class="terms-section-item">
                2. Your Authorization for Settlement of Proceeds and for
                {{ facilitatorName }}
                to Recover Funds
              </h6>
              <p>
                In connection with the Payment Processing Services, you authorize us to
                act as your agent for the purposes of holding, receiving, and disbursing
                funds on your behalf. Your authorization permits us to generate a paper
                draft or an electronic funds transfer to settle the proceeds of each Card
                transaction that you authorize, less any applicable Fees (as defined in
                Section 4) (“Proceeds”). Your authorizations will remain in full force and
                effect until the later of closure or termination of your
                {{ facilitatorName }} Account, or the disbursement of all funds held on
                your behalf. Our receipt of transaction funds satisfies your customer's
                obligations to make payment to you. We will remit to you funds actually
                received by us on your behalf, less amounts owed to us, subject to any
                Chargeback provisions (as defined in Section 17-20) or Reserve (as defined
                in Section 14) withheld or applied. <br /><br />
                In addition, by using the Payment Processing Services, you authorize us to
                recover funds from you in accordance with these Payment Terms, the
                Agreement and any other agreement you have with us (“Recovery
                Authorizations.”). Recovery Authorizations include your authorization of
                {{ facilitatorName }} to debit, charge, setoff against and otherwise
                recover funds from any connected {{ facilitatorName }} Account, any
                Balance (as defined in these Payment Terms) any Reserve (as defined in
                these Payment Terms), any payment instrument, linked bank, depository and
                other account registered in your {{ facilitatorName }} Account.
                <br /><br />
                Further, in the event that any attempt to recover funds from you should
                fail, Recovery Authorizations include your grant to
                {{ facilitatorName }} of new, original authorizations to recover all or
                less than all of the amount you owe us and/or belong to us. Recovery
                Authorizations include all authorizations to take the above steps without
                prior notice to you and irrespective of (i) whether we have made demand
                under these Payment Terms, the Agreement or any other agreement you have
                with us; and (ii) whether the obligation is contingent, matured or
                unmatured. Recovery Authorizations include all authorizations to take the
                above steps in complete compliance with the Network Rules (as defined in
                these Payment Terms) and the National Automated Clearing House Association
                -The Electronic Payments Association Operating Rules and Guidelines
                (“NACHA Rules”). You agree that your grant of Recovery Authorizations to
                us has the same legal effect as if you had signed a paper containing the
                same terms.
              </p>
              <h6 class="terms-section-item">3. Our Fees</h6>
              <p>
                You agree to pay the applicable fees listed as described in your Dashboard
                (“Fees”) for use of the Payment Processing Services. All Fees are charged
                at the time we process a transaction and are deducted first from the
                transferred or collected funds and thereafter from the Balance (as defined
                in Section 14) in your
                {{ facilitatorName }} Account. You also grant us Recovery Authorizations;
                which we may utilize to recover fees you owe us. Subject to the General
                Terms and these Payment Terms, we reserve the right to change the Fees
                upon reasonable advance notice. All Balances and all Fees, charges, and
                payments collected or paid through the Payment Processing Services are
                denominated in US dollars.
              </p>
              <h6 class="terms-section-item">4. Processing Errors</h6>
              <p>
                We will attempt to fix processing errors we discover. If an error results
                in your receipt of fewer funds than you are entitled, we will credit your
                {{ facilitatorName }} Account for the difference. You grant us Recovery
                Authorizations concerning processing errors. If an error results in your
                receipt of more funds than you are entitled, {{ facilitatorName }} may
                recover the extra funds from you. We will only correct transactions that
                you process incorrectly if you notify us of such an error. Your failure to
                notify us of a processing error within thirty (30) days of when it first
                appears on your electronic transaction history will be deemed a waiver of
                any right to amounts owed to you.
              </p>
              <h6 class="terms-section-item">Payment Account Setup</h6>
              <h6 class="terms-section-item">
                5. {{ facilitatorName }} Account Registration for Payment Processing
                Services
              </h6>
              <p>
                You must open a {{ facilitatorName }} Account to access any Proceeds. You
                confirm that you are either a legal resident of the United States, a
                United States citizen, or a business entity authorized to conduct business
                by the state(s) in which you operate and that you are an authorized
                signatory for the business you represent. You must use your or your
                business' true and accurate name when opening a
                {{ facilitatorName }} Account. This name will appear on the credit or
                debit card statements of your customers for all payments you accept using
                the Payment Processing Services.
              </p>
              <h6 class="terms-section-item">
                6. Underwriting and Identity Verification
              </h6>
              <p>
                {{ facilitatorName }} will review the information you submit while signing
                up for the Payment Processing Services. You authorize
                {{ facilitatorName }} to request identity verifying information about you,
                including a consumer report that contains your name and address.
                {{ facilitatorName }} may periodically obtain additional reports to
                determine whether you continue to meet the requirements for a
                {{ facilitatorName }} Account. You permit {{ facilitatorName }} to share
                information about you and your application (including whether you are
                approved or declined), and your {{ facilitatorName }} Account with your
                bank or other financial institution, or as otherwise specified in the
                <a
                  href="https://help.jupico.com/references-and-resources/legal/privacy-policy"
                  >User Privacy Notice</a
                >. {{ facilitatorName }} or its processor and/or acquiring bank may
                conclude that you will not be permitted to use the Payment Processing
                Services.
              </p>
              <h6 class="terms-section-item">
                7. Requests for Additional Information and Inspection
              </h6>
              <p>
                {{ facilitatorName }} may request additional information from you at any
                time. For example, {{ facilitatorName }} may ask you to present invoices
                from your suppliers, a government issued identification such as a passport
                or driver's license, a business license, or other information.
                {{ facilitatorName }} may also ask for permission to inspect your business
                location. If you refuse any of these requests, your {{ facilitatorName }}
                Account may be suspended or terminated.
              </p>
              <h6 class="terms-section-item">
                Use of Your {{ facilitatorName }} Account for Payment Processing Services
              </h6>
              <h6 class="terms-section-item">8. Access to Proceeds</h6>
              <p>
                We will settle Proceeds to your verified bank account or other account
                approved by {{ facilitatorName }}, subject to the payout schedule
                described in Section 10. If {{ facilitatorName }} is not able to debit or
                credit the bank account you link to your {{ facilitatorName }} Account,
                that bank account will be de-linked from your
                {{ facilitatorName }} Account. If you do not have an ACH-enabled bank
                account linked to your {{ facilitatorName }} Account, you may request a
                physical check for Proceeds. Check requests may be subject to a processing
                fee and an identity verification process. You must request a check or link
                an ACH-enabled bank account to your {{ facilitatorName }} Account in order
                to receive your Proceeds.
              </p>
              <h6 class="terms-section-item">9. Payout Schedule</h6>
              <p>
                {{ facilitatorName }} will automatically initiate a payout of Proceeds to
                your valid, linked US bank account at the end of the business day before 5
                p.m. PT (8 p.m. ET), except on Friday (which such payout of Friday
                Proceeds is initiated the following Sunday before 5 p.m. PT (8 p.m. ET)).
                If you adjust your business's close of day to a custom time,
                {{ facilitatorName }} will group Proceeds for the 24-hour period preceding
                your custom time and initiate a payout at the end of that business day.
                {{ facilitatorName }} will initiate a payout of Proceeds received on
                non-business days before the next business day.
              </p>
              <h6 class="terms-section-item">10. Availability of Proceeds</h6>
              <p>
                We may defer payout or restrict access to your Proceeds if we need to
                conduct an investigation or resolve any pending dispute related to your
                use of the Services. We also may defer payout or restrict access to your
                Proceeds as necessary to comply with applicable law or court order, or if
                requested by any governmental entity.
              </p>
              <h6 class="terms-section-item">
                11. {{ facilitatorName }} Account History
              </h6>
              <p>
                When a payment is made to your {{ facilitatorName }} Account, we will
                update your {{ facilitatorName }} Account activity on the website and
                provide you a transaction confirmation. The confirmation will serve as
                your receipt. Summaries of your {{ facilitatorName }} Account activity,
                including monthly statements, are available on our website for up to one
                year of account activity. Except as required by law, you are solely
                responsible for (a) compiling and retaining permanent records of all
                transactions and other data associated with your
                {{ facilitatorName }} Account and your use of the Payment Processing
                Services, and (b) reconciling all transactional information that is
                associated with your {{ facilitatorName }} Account. If you believe that
                there is an error or unauthorized transaction activity is associated with
                your {{ facilitatorName }} Account, you must contact us immediately.
              </p>
              <h6 class="terms-section-item">12. Reserve for Holding Funds</h6>
              <p>
                We may withhold funds by temporarily suspending or delaying payouts of
                Proceeds to you and/or designate an amount of funds that you must maintain
                in your {{ facilitatorName }} Accounts or in a separate reserve account (a
                “Reserve”) to secure the performance of your obligations under any
                agreement between you and {{ facilitatorName }}. We may require a Reserve
                for any reason related to your use of the Services. The Reserve will be in
                an amount as reasonably determined by us to cover potential losses to
                {{ facilitatorName }}. The Reserve may be raised, reduced or removed at
                any time by {{ facilitatorName }}, in its sole discretion, based on your
                payment history, a credit review, the amount of any arbitration award or
                court judgment against you in {{ facilitatorName }}'s favor, or otherwise
                as {{ facilitatorName }} or its processor may determine or require. If you
                do not have sufficient funds in your Reserve, we may fund the Reserve from
                any funding source associated with your {{ facilitatorName }} Accounts,
                including any funds (a) deposited by you, (b) due to you, or (c) available
                in your bank account, or other payment instrument registered with us. You
                grant us a security interest in and lien on any and all funds held in any
                Reserve, and also authorize us to make any withdrawals or debits from the
                Reserve or any bank account linked to any of your
                {{ facilitatorName }} Accounts, without prior notice to you, to collect
                amounts that you owe us. You will execute any additional documentation
                required for us to perfect our security interest in any funds in the
                Reserve. This security interest survives for as long as we hold funds in
                your Reserve.
              </p>
              <h6 class="terms-section-item">
                13. {{ facilitatorName }} Account Balances
              </h6>
              <p>
                While you have funds in your {{ facilitatorName }} Account (your
                “Balance”), your Balance will be co-mingled and held with other
                participants' funds in one or more pooled accounts at one or more banks by
                us on your behalf and for the benefit of you and others holding balances
                (each a “Pooled Account”). We have sole discretion over the establishment
                and maintenance of any pooled account. We will not voluntarily make funds
                available to our creditors in the event of bankruptcy or for any other
                purpose. You will not receive interest or any other earnings on any funds
                that we handle for you. As consideration for using the Payment Processing
                Services, you irrevocably assign to us all rights and legal interests to
                any interest and/or other earnings that may accrue or are attributable to
                our holding of your Balance in a Pooled Account or Reserve.
              </p>
              <h6 class="terms-section-item">
                14. Our Set-off Rights; Security Interest
              </h6>
              <p>
                You grant us a security interest in, as well as a right of setoff against,
                and assign, convey, deliver, pledge and transfer to us, as security for
                repayment of any obligations due under these Payment Terms and any other
                agreement you have with us, all of your right, title, and interest in and
                to all of your accounts with us. You grant us Recovery Authorizations
                concerning funds you are obligated to repay us. You authorize us to
                administratively freeze or direct any third party bank holding the account
                to freeze all such accounts to allow us to protect our security interest,
                collection, charge and setoff rights as provided for in this section.
                <br /><br />
                Your failure to fully pay amounts that you owe us on demand will be a
                breach of these terms. You are liable for any of our costs associated with
                collection in addition to any amounts owed, including attorneys' fees and
                expenses, collection agency fees, and any applicable interest.
              </p>
              <h6 class="terms-section-item">
                15. Dormant {{ facilitatorName }} Accounts
              </h6>
              <p>
                If you do not process payments through your {{ facilitatorName }} Account
                for an extended period of time or have not linked a valid bank account,
                you may have a Balance that is deemed “unclaimed” or “abandoned” under
                your state's law. If this occurs, {{ facilitatorName }} will provide you
                with notice as required by applicable law and instructions for how to
                deposit your Balance. If funds still remain in your
                {{ facilitatorName }} Account, {{ facilitatorName }} will escheat such
                funds as required by applicable law or, as permitted, to
                {{ facilitatorName }}.
              </p>
              <h6 class="terms-section-item">Chargebacks</h6>
              <h6 class="terms-section-item">16. Your Liability for Chargebacks</h6>
              <p>
                There may be times when your customer may not be the authorized user of
                the Card or your customer may otherwise contest the transaction. In these
                instances, the amount of a transaction may be reversed or charged back to
                your {{ facilitatorName }} Accounts (a “Chargeback”) if the transaction
                (a) is disputed, (b) is reversed for any reason by the Network, our
                processor, or a customer or its financial institution, (c) was not
                authorized or we have any reason to believe that the transaction was not
                authorized, or (d) is allegedly unlawful, suspicious, or in violation of
                the General Terms or these Payment Terms. You agree to comply with the
                Chargeback process and to the liability associated with such Chargebacks.
              </p>
              <h6 class="terms-section-item">
                17. Our Collection Rights for Chargebacks
              </h6>
              <p>
                For any transaction that results in a Chargeback, we may withhold the
                Chargeback amount in a Reserve. You grant us Recovery Authorizations
                concerning Chargebacks pursuant to which we may recover the amount of any
                Chargeback and any associated Fees, fines, or penalties listed in the Fee
                Schedule or assessed by a Network or our processor. If you have pending
                Chargebacks, we may delay payouts from your {{ facilitatorName }} Account.
                Further, if we reasonably believe that a Chargeback is likely with respect
                to any transaction, we may withhold the amount of the potential Chargeback
                from payments otherwise due to you until such time that (a) a Chargeback
                is assessed due to a customer complaint, in which case we will retain the
                funds, (b) the period of time under applicable law or regulation by which
                your customer may dispute that the transaction has expired, or (c) we
                determine that a Chargeback on the transaction will not occur. If we are
                unable to recover funds related to a Chargeback for which you are liable,
                you agree to pay us the full amount of the Chargeback immediately upon
                demand. You agree to pay all costs and expenses, including attorneys' fees
                and other legal expenses, incurred by us for the collection of all amounts
                unpaid by you.
              </p>
              <h6 class="terms-section-item">18. Excessive Chargebacks</h6>
              <p>
                If we believe you might incur, or you are incurring, an excessive amount
                of Chargebacks, we may establish additional conditions governing your
                {{ facilitatorName }} Account, including (a) establishing new processing
                fees, (b) creating a Reserve in an amount reasonably determined by us to
                cover anticipated Chargebacks and related fees, (c) delaying payouts, or
                (d) terminating or suspending the Payment Processing Services.
              </p>
              <h6 class="terms-section-item">19. Contesting Chargebacks</h6>
              <p>
                You will assist us when requested, at your expense, to investigate any of
                your transactions processed through the Payment Processing Services. To
                that end, you permit us to share information about a Chargeback with the
                customer, the customer's financial institution, and your financial
                institution in order to investigate and/or mediate a Chargeback. We will
                request necessary information from you to contest the Chargeback. We may
                also use any Content you upload through the Services to respond to
                Chargebacks on your behalf. If the Chargeback is contested successfully,
                we will release corresponding reserved funds to your
                {{ facilitatorName }} Account. If a Chargeback dispute is not resolved in
                your favor by the Network or issuing bank or you choose not to contest the
                Chargeback, we may recover the Chargeback amount and any associated fees
                as described in these terms. You acknowledge that your failure to assist
                us in a timely manner when investigating a transaction, including
                providing necessary documentation within fifteen (15) days of our request,
                may result in an irreversible Chargeback. We reserve the right, upon
                notice to you, to charge a fee for mediating and/or investigating
                Chargeback disputes.
              </p>
              <h6 class="terms-section-item">Compliance with Network Rules and Laws</h6>
              <h6 class="terms-section-item">20. Restricted Use</h6>
              <p>
                You will not act as a payment facilitator or otherwise resell the Payment
                Processing Services to any third party. You will not use the Payment
                Processing Services to handle, process or transmit funds for any third
                party. You also may not use the Payment Processing Services to process
                cash advances.
              </p>
              <h6 class="terms-section-item">21. Unauthorized or Illegal Use</h6>
              <p>
                You will only accept Cards for transactions that are allowed by applicable
                law. We may decide not to authorize or settle any transaction that you
                submit to us if we believe that the transaction is in violation of these
                terms or any other agreement with {{ facilitatorName }}, or that it
                exposes you, your customers, other {{ facilitatorName }} Sellers, our
                processors or {{ facilitatorName }} to harm. Harm includes fraud and other
                criminal acts. If we suspect that your {{ facilitatorName }} Account has
                been used for an unauthorized, illegal, or criminal purpose, you give us
                express authorization to share information about you, your
                {{ facilitatorName }} Account, and any of your transactions with law
                enforcement and, as deemed necessary by {{ facilitatorName }}, our payment
                processing and financial institution partners..
              </p>
              <h6 class="terms-section-item">22. Assignment of Receivables</h6>
              <p>
                With the exception of your participation in a merchant cash advance or
                other similar merchant financing programs, or as otherwise required by
                applicable law, you will not assign Card receivables or proceeds due to
                you under these terms to any third party.
              </p>
              <h6 class="terms-section-item">23. Applicable Network Rules</h6>
              <p>
                By using the Payment Processing Services, you agree to comply with all
                applicable by laws, rules, and regulations set forth by the Networks
                (“Network Rules”). The Networks amend their rules and regulations from
                time to time. {{ facilitatorName }} may be required to change these terms
                in connection with amendments to the Network Rules. Significant portions
                of the Network Rules are available to the public at:
                <a href="https://visa.com" target="_blank">https://visa.com</a>,
                <a href="https://www.mastercard.com" target="_blank"
                  >https://www.mastercard.com</a
                >, and
                <a href="https://www.americanexpress.com/merchantopguide" target="_blank"
                  >https://www.americanexpress.com/merchantopguide</a
                >. In the event of inconsistency between a Network Rule and these Payment
                Terms, and except as otherwise agreed between {{ facilitatorName }} and
                the Network, the Network Rule shall apply. You also agree to be bound by
                any applicable NACHA Rules.
              </p>
              <h6 class="terms-section-item">24. Taxes</h6>
              <p>
                You are responsible for determining any and all taxes assessed, incurred,
                or required to be collected, paid, or withheld for any reason for your use
                of the Payment Processing Services (“Taxes”). You also are solely
                responsible for collecting, withholding, reporting, and remitting correct
                Taxes to the appropriate tax authority. We are not obligated to, nor will
                we determine whether Taxes apply, or calculate, collect, report, or remit
                any Taxes to any tax authority arising from any transaction.
                {{ facilitatorName }} specifically disclaims any liability for Taxes. If
                in a given calendar year you process (i) more than the applicable
                reporting threshold according to the Internal Revenue Service (“IRS”)
                and/or state and local governments in gross amount of payments and/or (ii)
                more than the applicable number of payments threshold according to the IRS
                and/or state and local governments, {{ facilitatorName }} will be required
                by law to report information about you and your use of the Payment
                Processing Services to the IRS. For purposes of determining whether you
                have met the IRS and/or state and local government reporting threshold,
                the gross amount does not include any adjustments for credits, cash
                equivalents, discount amounts, fees, refunded amounts or any other
                amounts. Whether you reach the applicable gross amount in payments or more
                than the applicable number of payments, this will be determined by looking
                at the taxpayer identification number (“TIN”) associated with your
                {{ facilitatorName }}
                Account.
              </p>
              <h6 class="terms-section-item">25. Network Logos and Marks</h6>
              <p>
                Your use of Network logos and marks (“Network Marks”) is governed by the
                Network Rules; you must familiarize yourself with and comply with these
                requirements. The Networks are the sole and exclusive owners of their
                respective Network Marks. You will not contest the ownership of the
                Network Marks, and any Network may prohibit you from using its Network
                Marks for any reason and at any time. {{ facilitatorName }} may require
                you to make modifications to your Website or other advertising and signage
                in order to comply with Network Rules related to the Network Marks.
              </p>
              <h6 class="terms-section-item">26. PCI Compliance</h6>
              <p>
                If you store, process or transmit cardholder data, you will comply with
                the applicable Payment Card Industry Data Security Standards (PCI-DSS), as
                well as any requirements under the Network Rules. We may request your most
                recent PCI compliance certificate or report of compliance at any time. You
                will cooperate in forensic investigations as required by
                {{ facilitatorName }} or its partners. For additional information,
                including tools to help you assess your compliance, see
                <a href="http://www.visa.com/cisp" target="_blank"
                  >http://www.visa.com/cisp</a
                >
                and
                <a href="https://www.mastercard.com/sdp" target="_blank"
                  >https://www.mastercard.com/sdp</a
                >.
              </p>
              <h6 class="terms-section-item">
                27. Failure to Comply with Network Rules or Security Standards
              </h6>
              <p>
                Notwithstanding Section 33 of these terms or Section 15 of the General
                Terms, you understand that your failure to comply with the Network Rules
                or the PCI-DSS security standards, including the compromise of any Card
                information, may result in fines or other losses to {{ facilitatorName }}.
                You will indemnify us and reimburse us immediately for any fines or losses
                directly or indirectly caused by your and your agents' actions.
              </p>
              <h6 class="terms-section-item">Your Additional Obligations</h6>
              <h6 class="terms-section-item">28. Receipts</h6>
              <p>
                You will request that your customer personally sign for all transactions
                greater than $25 when your customer is present. You must make a written
                receipt available to your customers for any transaction greater than $15.
                You may give your customers the option to receive or decline a written
                receipt.
              </p>
              <h6 class="terms-section-item">29. Receipts for 501(c)(3) Organizations</h6>
              <p>
                If you operate a 501(c)(3) organization, you may offer
                {{ facilitatorName }}
                electronic receipts to your donors as a convenience, by completing the
                required fields in the “Account” section of our website. You acknowledge
                that not all payments made to your organization may be eligible for
                classification as “tax deductible to the extent allowed by law,” and you
                assume full responsibility for the classification of your transactions.
                {{ facilitatorName }} specifically disclaims any liability in this regard.
              </p>
              <h6 class="terms-section-item">30. Customer Service</h6>
              <p>
                You are solely responsible for all customer service policies and issues
                relating to your goods or services, including pricing, order fulfillment,
                cancellations or no shows by you or a customer, returns, refunds and
                adjustments, rebates, functionality and warranty, technical support, and
                feedback concerning experiences with your personnel, policies or
                processes. In performing customer service, you will always present
                yourself as a separate entity from us. As between you and us, we are
                solely responsible for customer service issues relating to any
                {{ facilitatorName }} Account, payment, Card processing, debiting or
                crediting.
              </p>
              <h6 class="terms-section-item">31. Refunds and Returns</h6>
              <p>
                By accepting Card transactions through the Payment Processing Services,
                you agree to process returns of, and provide refunds and adjustments for,
                your goods or services through your {{ facilitatorName }} Account in
                accordance with these Payment Processing Terms and Network Rules. Network
                Rules require that you disclose your return or cancellation policy to
                customers at the time of purchase. The amount of the refund/adjustment
                must include any associated taxes required to be refunded and cannot
                exceed the amount shown as the total on the original sales data except by
                the exact amount required to reimburse the customer for postage that the
                customer paid to return merchandise. If your refund policy prohibits
                returns or is unsatisfactory to the customer, you may still receive a
                Chargeback relating to such sales. You can process a refund through your
                {{ facilitatorName }} Account up to one hundred and twenty (120) days from
                the day you accepted the payment. If you use
                {{ facilitatorName }}'s backoffice, you may not be able to process a partial
                refund. If your available {{ facilitatorName }} Balance is insufficient to
                cover the refund, we may exercise any of our rights set forth in Section
                2, including withdrawing up to the requested refund amount (the sale
                amount minus the initial transaction fee) from a payment instrument
                registered with your {{ facilitatorName }} Account or any connected
                {{ facilitatorName }} Account, including any Reserve, and crediting it
                back into your customer's Card. The Fees are also refunded by
                {{ facilitatorName }}, so the full purchase amount is always returned to
                your customer. {{ facilitatorName }} has no obligation to accept any
                returns of any of your goods or services on your behalf.
              </p>
              <h6 class="terms-section-item">32. Additional Indemnity</h6>
              <p>
                In addition to Section 15 of the General Terms, you will indemnify,
                defend, and hold us and our processors (and our respective employees,
                directors, agents, affiliates and representatives) harmless from and
                against any and all claims, costs, losses, damages, judgments, Tax
                assessments, penalties, interest, and expenses (including reasonable
                attorneys' fees) arising out of any claim, action, audit, investigation,
                inquiry, or other proceeding instituted by a person or entity that arises
                out of or relates to any transaction submitted by you through the Payment
                Processing Services (including the accuracy of any content or product,
                service, or transaction information that you provide or any claim or
                dispute arising out of products or services offered or sold by you).
              </p>
              <h6 class="terms-section-item">
                33. Additional Representations, Warranties, and Covenants
              </h6>
              <p>
                In addition to Section 17 of the General Terms, with each Card transaction
                you process through the Payment Processing Services, you represent,
                warrant and covenant to us that: (a) the Card transaction represents a
                bona fide sale; (b) the Card transaction accurately describes the goods
                and/or services provided to the customer; (c) you will fulfill all of your
                obligations to the customer and will resolve any dispute or complaint
                directly with the customer; (d) you and the Card transaction comply with
                all federal, state, and local laws, rules, and regulations applicable to
                you and your business, including any applicable tax, wage and hour, and
                tip laws and regulations; (e) except in the ordinary course of business,
                you are not submitting a transaction that represents a sale to any
                principal, partner, proprietor, or owner of your entity; and (f) you are
                not submitting a transaction involving your own Cards (except for
                reasonable test swipes).
              </p>
              <h6 class="terms-section-item">
                Termination of Payment Processing Services
              </h6>
              <h6 class="terms-section-item">
                34. Termination of Payment Processing Services
              </h6>
              <p>
                {{ facilitatorName }} may Terminate your use of the Payment Processing
                Services at any time for any reason. Any termination of these terms does
                not relieve you of obligations to pay Fees or costs accrued prior to the
                termination, Chargebacks, and any other amounts owed to us as provided in
                these Payment Terms or the Terms of Service. If your access to Payment
                Processing Services has been terminated, you may still be permitted to use
                {{ facilitatorName }}'s other products, subject to our discretion.
              </p>
              <h6 class="terms-section-item">
                36. Payment Processing Services Upon Closure of Your {{ facilitatorName }}
                Account
              </h6>
              <p>
                Closure of your {{ facilitatorName }} Account will cancel any pending
                transactions. Any funds that we are holding in custody for you at the time
                of closure, less any applicable Fees, will be paid out according to your
                payout schedule, subject to the other conditions in these Payment Terms.
                If an investigation is pending at the time you close your
                {{ facilitatorName }} Account, we may hold your funds as described herein.
                If you are later determined to be entitled to some or all of the funds, we
                will release those funds to you.
              </p>
              <h6 class="terms-section-item">37. Survival</h6>
              <p>
                The following sections will survive termination of these Payment Terms, in
                addition to those that survive under Section 12 of the General Terms: 5
                (Processing Errors), 7 (Underwriting and Identity Verification), 8 (Access
                to Proceeds), 9 (Payout Schedule), 10 (Availability of Proceeds), 11 ({{
                  facilitatorName
                }}
                Account History), 12 (Reserve for Holding Funds), 13 ({{
                  facilitatorName
                }}
                Account Balances), 14 (Our Set-off Rights; Security Interest), 15 (Dormant
                {{ facilitatorName }} Accounts), 16 (Your Liability for Chargebacks), 18
                (Our Collection Rights for Chargebacks), 19 (Excessive Chargebacks), 19
                (Contesting Chargebacks), 24 (Taxes), and 30 (Customer Service).
              </p>
              <h5 class="terms-section-title">SECTION C. Merchant Services Agreement</h5>
              <p>
                This section is applicable to all Sellers utilizing the Payment Processing
                Services that are defined as “Commercial Entities” by Visa, Inc. and
                Mastercard International, Inc. (collectively, the “Card Brands”). As such
                entity, the seller signing or electronically agreeing to the terms hereof
                (“Sub-merchant”) is entering into this Agreement with KeyBank National
                Bank Association ("Acquirer"), having its principal office at 127 Public
                Square, Cleveland, OH 44114. To govern the authorization, conveyance and
                settlement of Transactions utilizing the Jupico Payment Processing
                Service. By entering into this Agreement Sub-merchant is fulfilling the
                Card Brand Rules requiring a direct contractual relationship between the
                Member and Sub-merchant, and Sub-merchant is agreeing to comply with Card
                Brand Rules as they pertain to payments Sub-merchant receives through the
                {{ facilitatorName }}'s Payment Processing Service. <br /><br />
                Acquirer will provide Sub-merchant with certain payment processing
                services (“Services”) in accordance with the terms of this Merchant
                Services Agreement. In consideration of Sub-merchant's receipt of credit
                or debit card funded payments, and participation in programs affiliated
                with MasterCard International Inc. (“MasterCard”), VISA U.S.A. Inc.
                (“VISA”), Discover (“Discover”), and certain similar entities
                (collectively, “Associations), Sub-merchant is required to comply with the
                Operating Regulations (defined below) as they pertain to applicable credit
                and debit card payments. In addition, if Sub-merchant meets certain
                requirements under the Operating Regulations or an Association or the
                Operating Regulations otherwise require, Sub-merchant may be required to
                enter into a direct relationship with an entity that is a member of the
                Associations. By executing this Agreement, Sub-merchant has fulfilled such
                requirement. However, Acquirer understands that Sub-merchant may have
                contracted with Provider to obtain certain processing services and that
                Provider may have agreed to be responsible to Sub-merchant for all or part
                of Sub-merchant's obligations contained herein.
              </p>
              <h6 class="terms-section-item">1. Certain Sub-merchant Responsibilities</h6>
              <p>
                Sub-merchant agrees to comply, and to cause third parties acting as
                Sub-merchant's agent (“Agents”) to comply, with the Association's and
                other payment network's by-laws, operating regulations and/or all other
                rules, policies and procedures, including but not limited to the Payment
                Card Industry Data Security Standard, the VISA Cardholder Information
                Security Program, the MasterCard Site Data Protection Program, and any
                other program or requirement that may be published and/or mandated by the
                Associations or payment networks (collectively “Operating Regulations”).
                Sub-merchant may review the VISA, MasterCard, and Discover websites for a
                copy of the Visa, MasterCard and Discover regulations. The websites are:
                http://usa.visa.com/merchants/ and http://www.mastercard.com/us/merchant/
                and http://www.discovernetwork.com/merchants/. Sub-merchant also agrees to
                comply with all applicable state, federal, and local laws, rules, and
                regulations (“Laws”). Without limiting the foregoing, Sub-merchant agrees
                that it will fully comply with any and all anti-money laundering laws and
                regulations, including but not limited to the Bank Secrecy Act, the US
                Treasury's Office of Foreign Assets Control (OFAC) and the Federal Trade
                Commission. For purposes of this section, Agents include, but are not
                limited to, Sub-merchant's software providers and/or equipment providers.
                <br /><br />
                If appropriately indicated in Sub-merchant's agreement with Provider,
                Sub-merchant may be a limited-acceptance merchant, which means that
                Sub-merchant has elected to accept only certain Visa and MasterCard card
                types (i.e., consumer credit, consumer debit, and commercial cards) and
                must display appropriate signage to indicate the same. Acquirer has no
                obligation other than those expressly provided under the Operating
                Regulations and applicable law as they may relate to limited acceptance.
                Sub-merchant, and not Acquirer, will be solely responsible for the
                implementation of its decision for limited acceptance, including but not
                limited to policing the card type(s) accepted at the point of sale.
                <br /><br />
                Sub-merchant shall only complete sales transactions produced as the direct
                result of bona fide sales made by Sub-merchant to cardholders, and is
                expressly prohibited from presenting sales transactions which are produced
                as a result of sales made by any person or entity other than Sub-merchant,
                or for any purposes related to any illegal or prohibited activity,
                including but not limited to money-laundering or financing of terrorist
                activities. <br /><br />
                Sub-merchant may set a minimum transaction amount to accept a card that
                provides access to a credit account, under the following conditions: i)
                the minimum transaction amount does not differentiate between card
                issuers; ii) the minimum transaction amount does not differentiate between
                MasterCard, Visa, or any other acceptance brand; and iii) the minimum
                transaction amount does not exceed ten dollars (or any higher amount
                established by the Federal Reserve). Sub-merchant may set a maximum
                transaction amount to accept a card that provides access to a credit
                account, under the following conditions: Sub-merchant is a i) department,
                agency or instrumentality of the U.S. government; ii) corporation owned or
                controlled by the U.S. government; or iii) Sub-merchant whose primary
                business is reflected by one of the following MCCs: 8220, 8244, 8249
                –Schools, Trade or Vocational; and the maximum transaction amount does not
                differentiate between MasterCard, Visa, or any other acceptance brand.
              </p>
              <h6 class="terms-section-item">2. Sub-merchant Prohibitions</h6>
              <p>
                Sub-merchant must not i) require a cardholder to complete a postcard or
                similar device that includes the cardholder's account number, card
                expiration date, signature, or any other card account data in plain view
                when mailed, ii) add any tax to transactions, unless applicable law
                expressly requires that a Sub-merchant impose a tax (any tax amount, if
                allowed, must be included in the transaction amount and not collected
                separately), iii) request or use an account number for any purpose other
                than as payment for its goods or services, iv) disburse funds in the form
                of travelers checks if the sole purpose is to allow the cardholder to make
                a cash purchase of goods or services from Sub-merchant, v) disburse funds
                in the form of cash (except as authorized by the Card Brand Rules), vi)
                submit any transaction receipt for a transaction that was previously
                charged back to the Acquirer and subsequently returned to Sub-merchant,
                irrespective of cardholder approval, vii) accept a Visa consumer credit
                card or commercial Visa product issued by a U.S. issuer to collect or
                refinance an existing debt, viii) accept a card to collect or refinance an
                existing debt that has been deemed uncollectable, or ix) submit a
                transaction that represents collection of a dishonored check. Sub-merchant
                further agrees that, under no circumstance, will Sub-merchant store
                cardholder data in violation of the Laws or the Operating Regulations
                including but not limited to the storage of track-2 data. Neither
                Sub-merchant nor its Agent shall retain or store magnetic-stripe data
                subsequent to the authorization of a sales transaction.
              </p>
              <h6 class="terms-section-item">3. Settlement</h6>
              <p>
                Upon receipt of Sub-merchant's sales data for card transactions, Acquirer
                will process Sub-merchant's sales data to facilitate the funds transfer
                between the various Associations and Sub-merchant. After Acquirer receives
                credit for such sales data, subject to the terms set forth herein,
                Acquirer will fund Sub-merchant, either directly to the Sub-merchant-Owned
                Designated Account or through Provider to an account designated by
                Provider (“Provider Designated Account”), at Acquirer's discretion, for
                such card transactions. Sub-merchant agrees that the deposit of funds to
                the Provider Designated Account shall discharge Acquirer of its settlement
                obligation to Sub-merchant, and that any dispute regarding the receipt or
                amount of settlement shall be between Provider and Sub-merchant. Acquirer
                will debit the Provider Designated Account for funds owed to Acquirer as a
                result of the Services provided hereunder, provided that Acquirer may also
                debit Sub-merchant's designated demand deposit account
                (“Sub-merchant-Owned Designated Account”) upon receipt of such account
                information from Sub-merchant or Provider, or if Acquirer deposits
                settlement funds into the Sub-merchant-Owned Designated Account. Further,
                if a cardholder disputes a transaction, if a transaction is charged back
                for any reason, or if Acquirer reasonably believes a transaction is
                unauthorized or otherwise unacceptable, the amount of such transaction may
                be charged back and debited from Sub-merchant or Provider.
              </p>
              <h6 class="terms-section-item">4. Term and Termination</h6>
              <p>
                This Merchant Services Agreement shall be binding upon Sub-merchant's
                execution. The term of this Merchant Services Agreement shall begin, and
                the terms of the Merchant Services Agreement shall be deemed accepted and
                binding upon Acquirer, on the date Acquirer accepts this Merchant Services
                Agreement by issuing a merchant identification number, and shall be
                coterminous with Provider's agreement with Sub-merchant. <br /><br />
                Notwithstanding the foregoing, Acquirer may immediately cease providing
                Services and/or terminate this Merchant Services Agreement without notice
                if (i) Sub-merchant or Provider fails to pay any amount to Acquirer when
                due, (ii) in Acquirer's opinion, provision of a service to Sub-merchant or
                Provider may be a violation of the Operating Regulations or any Laws,
                (iii) Acquirer believes that Sub-merchant has violated or is likely to
                violate the Operating Regulations or the Laws, (iv) Acquire determines
                Sub-merchant poses a financial or regulatory risk to Acquirer or an
                Association, (v) Acquirer's agreement with Provider terminates, (vi) any
                Association deregisters Provider, (vii) Acquirer ceases to be a member of
                the Associations or fails to have the required licenses, or (viii)
                Acquirer is required to do so by any of the Associations.
              </p>
              <h6 class="terms-section-item">5. Limits of Liability</h6>
              <p>
                Sub-merchant agrees to provide Acquirer, via a communication with
                Provider, with written notice of any alleged breach by Acquirer of this
                Merchant Services Agreement which notice will specifically detail such
                alleged breach, within thirty (30) days of the date on which the alleged
                breach first occurred. Failure to so provide notice shall be deemed an
                acceptance by Sub-merchant and a waiver of any and all rights to dispute
                such breach. <br /><br />
                EXCEPT FOR THOSE EXPRESS WARRANTIES MADE IN THIS MERCHANT SERVICES
                AGREEMENT, ACQUIRER DISCLAIMS ALL WARRANTIES, INCLUDING, WITHOUT
                LIMITATION, ANY EXPRESS OR IMPLIED WARRANTIES OF MERCHANTABILITY OR
                FITNESS FOR A PARTICULAR PURPOSE. Sub-merchant's sole and exclusive remedy
                for any and all claims against Acquirer arising out of or in any way
                related to the transactions contemplated herein shall be termination of
                this Merchant Services Agreement. In the event that Sub-merchant has any
                claim arising in connection with the Services, rights, and/or obligations
                defined in this Merchant Services Agreement, Sub-merchant shall proceed
                against Provider and not against Acquirer, unless otherwise specifically
                set forth in the Operating Regulations. In no event shall Acquirer have
                any liability to Sub-merchant with respect to this Merchant Services
                Agreement or the Services. Sub-merchant acknowledges Acquirer is only
                providing this Merchant Services Agreement to assist in Provider's
                processing relationship with Sub-merchant, that Acquirer is not liable for
                any action or failure to act by Provider, and that Acquirer shall have no
                liability whatsoever in connection with any products or services provided
                to Sub-merchant by Provider. If Provider is unable to provide its services
                to Sub-merchant in connection with this Merchant Services Agreement and
                Acquirer elects to provide those services directly, Sub-merchant
                acknowledges and agrees that the provisions of this Merchant Services
                Agreement will no longer apply and the terms of Acquirer's then current
                Bank Card Merchant Agreement, which would be provided to Sub-merchant,
                will govern Acquirer's relationship with Sub-merchant. If Provider
                subsequently provides its services to Sub-merchant in connection with this
                Merchant Services Agreement, Acquirer will cease to provide such services
                after receipt of notice from Provider and this Merchant Services Agreement
                will govern Acquirer's relationship with Sub-merchant.
              </p>
              <h6 class="terms-section-item">6. Miscellaneous</h6>
              <p>
                This Merchant Services Agreement is entered into, governed by, and
                construed pursuant to the laws of the State of Ohio without regard to
                conflicts of law provisions. This Merchant Services Agreement may not be
                assigned by Sub-merchant without the prior written consent of Acquirer.
                This Merchant Services Agreement shall be binding upon and inure to the
                benefit of the parties hereto and their respective successors, transferees
                and assignees. This Merchant Services Agreement is for the benefit of, and
                may be enforced only by, Acquirer and Sub-merchant and is not for the
                benefit of, and may not be enforced by, any other party. Acquirer may
                amend this Merchant Services Agreement upon notice to Sub-merchant in
                accordance with Acquirer's standard operating procedure. If any provision
                of this Merchant Services Agreement is determined to be illegal or
                invalid, such illegality or invalidity of that provision will not affect
                any of the remaining provisions and this Merchant Services Agreement will
                be construed as if such provision is not contained in the Merchant
                Services Agreement. <b>“Member Bank”</b> as used in this Merchant Services
                Agreement shall mean a member of VISA, MasterCard and/or Discover, as
                applicable, that provides sponsorship services in connection with this
                Merchant Services Agreement. As of the commencement of this Merchant
                Services Agreement, Member Bank shall be KeyBank, an Ohio Banking
                Corporation, located in Cleveland, OH 44114. The Member Bank is a party to
                this Merchant Services Agreement. The Member Bank may be changed, and its
                rights and obligations assigned to another party by Acquirer at any time
                without notice to Sub-merchant. <br /><br />

              </p>

              <span>Important Acquirer Bank Responsibilities:</span>
              <p>
                <ul>
                  <li>
                    a) Acquirer is the only entity approved to extend acceptance of Card
                    Brand products directly to you.
                  </li>
                  <li>b) Acquirer must be a principal (signer) to this Agreement.</li>
                  <li>
                    c) Acquirer is responsible for educating you on pertinent Visa and
                    Mastercard Rules with which you must comply.
                  </li>
                  <li>
                    d) Subject to this Agreement, Acquirer is responsible for and settlement
                    funds to Jupico for distribution to Sub-Merchant or directly to
                    Sub-Merchant.
                  </li>
                </ul>
              </p>

              <span>Important Sub-merchant Responsibilities:</span>
              <p>
                <ul>
                  <li>
                    (a) Ensure compliance with cardholder data security and storage
                    requirements.
                  </li>
                  <li>(b) Maintain fraud and chargebacks below Card Brand thresholds.</li>
                  <li>(c) Review and understand the terms of this Agreement.</li>
                  <li>(d) Comply with Card Brand rules.</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-dismiss="modal" @click="toggleTerms">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'TermsOfService',
  computed: {
    ...mapGetters(['getFacilitator']),
    facilitatorName() {
      return this.getFacilitator.name;
    },
    facilitatorUppercaseName() {
      return this.getFacilitator.name.toUpperCase();
    }
  },
  methods: {
    toggleTerms() {
      this.$store.dispatch('TOGGLE_TERMS', false);
    }
  }
};
</script>
