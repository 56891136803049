<template>
  <div
    class="main-div"
    :class="{
      'input-group-focus': focused,
      'has-label': label
    }"
    style="margin-bottom: 1rem"
  >
    <slot name="label">
      <label v-if="label" class="input-label d-flex" :style="labelStyle">
        <i v-if="required" class="required-field float-left" />
        <span class="text-nowrap">{{ label }}</span>
        <div class="position-relative" v-if="tooltip">
          <i class="fa fa-question-circle tooltip-icon"></i>
          <span class="tooltip-text">{{ tooltip }}</span>
        </div>
      </label>
    </slot>
    <div
      class="input-wrapper mb-0"
      :class="{
        'input-group': hasIcon || $slots.addonRight
      }"
    >
      <div v-if="addonLeftIcon || $slots.addonLeft">
        <slot name="addonLeft">
          <span v-if="addonLeftIcon" class="input-group-prepend">
            <div
              class="input-group-text"
              :class="{
                'error-brdr': hasError,
                'success-brdr': !hasError && touched
              }"
            >
              <i :class="addonLeftIcon"></i>
            </div>
          </span>
        </slot>
      </div>
      <slot>
        <Select
          ref="select"
          v-focus="focus"
          class="select-primary"
          filterable
          :name="name"
          :value="value"
          :placeholder="placeholder"
          :class="{
            'error-brdr': hasValidation && hasError,
            'success-brdr': hasValidation && !hasError && touched
          }"
          :disabled="disabled"
          :popperClass="popperClass"
          :filter-method="filterMethod"
          :multiple="multiple"
          :allow-create="allowCreate"
          :collapse-tags="collapseTags"
          v-on="listeners"
          @keydown.native="handleKeydown"
        >
          <slot name="options">
            <Option
              v-for="(option, index) in options"
              class="select-primary"
              :key="option.value + index"
              :label="option.label"
              :value="option.value"
              :disabled="option.disabled"
              @keydown.native="handleKeydown"
            />
          </slot>
        </Select>
      </slot>
      <div v-if="addonRightIcon || $slots.addonRight">
        <slot name="addonRight">
          <span
            class="success-icon hidden-xs d-none d-lg-block"
            v-if="hasValidation && !hasError && touched"
          >
            <i class="fa fa-check-circle" aria-hidden="true"></i>
          </span>
          <span class="error-icon" v-else-if="hasError">
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
          </span>
          <span v-if="addonRightIcon" class="input-group-append">
            <div
              class="input-group-text"
              :class="{
                'error-brdr': hasError,
                'success-brdr': !hasError && touched
              }"
            >
              <i :class="addonRightIcon"></i>
            </div>
          </span>
        </slot>
      </div>
    </div>
    <slot name="error" v-if="hasError || $slots.error">
      <i class="error-msg">{{ error }}</i>
    </slot>
  </div>
</template>

<script>
import { Select, Option } from 'element-ui';

export default {
  inheritAttrs: false,
  components: {
    Select,
    Option
  },
  name: 'BaseSelect',
  props: {
    labelStyle: String,
    options: Array,
    focus: Boolean,
    required: Boolean,
    label: { type: String },
    error: { type: String, default: null },
    value: { type: [String, Number, Array] },
    addonLeftIcon: { type: String, description: 'Input icon on the left' },
    hasValidation: { type: Boolean, default: false },
    placeholder: { type: String, default: ' ' },
    disabled: Boolean,
    filterMethod: Function,
    multiple: Boolean,
    allowCreate: Boolean,
    collapseTags: Boolean,
    popperClass: String,
    name: String,
    addonRightIcon: {
      type: String,
      description: 'Input icon on the right'
    },
    tooltip: {
      type: String,
      default: ''
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  data() {
    return {
      focused: false,
      touched: false
    };
  },
  computed: {
    hasError() {
      return !!this.error && this.error.trim().length > 0;
    },
    hasIcon() {
      const { addonLeft, addonRight } = this.$slots;
      return (
        addonLeft !== undefined ||
        this.addonLeftIcon !== undefined ||
        addonRight !== undefined ||
        this.addonRightIcon !== undefined
      );
    },
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
        blur: this.onBlur,
        focus: this.onFocus
      };
    }
  },
  methods: {
    onInput(evt) {
      if (Array.isArray(evt)) {
        if (evt.length > 0)
          if (!this.touched) {
            this.touched = true;
          }
      } else if (!this.touched) {
        this.touched = true;
      }
      this.$emit('input', evt);
    },
    onFocus() {
      this.focused = true;
      this.$emit('onFocus');
    },
    onBlur() {
      this.focused = false;
      this.$emit('blur');
    },
    reset() {
      this.touched = false;
    },
    setFocus() {
      this.$refs.select.focus();
    },
    blur() {
      this.$refs.select.blur();
    },
    handleKeydown(event) {
      if (event.key !== 'Enter') {
        return;
      }
      const selectedOption = this.options.find(option => option.value === this.value);
      if (selectedOption) {
        this.$emit('input', selectedOption.value);
        this.blur();
        this.onBlur();
        this.$emit('switch-focus');
      }
      event.preventDefault();
    }
  },
  directives: {
    focus: {
      inserted(el, { value }, { context }) {
        context.$nextTick(() => {
          if (value) el.children[0].children[0].focus();
        });
      }
    }
  },
  mounted() {
    const input = this.$el.querySelector('input');
    if (input) {
      input.classList.add('form-control');
    }
  }
};
</script>

<style scoped lang="scss">
.error {
  display: block;
  width: 100%;
  font-size: 80%;
  color: #ff1721 !important;
  margin-top: 5px;
}
.success-icon {
  position: absolute;
  top: 16px;
  right: 10px;
  font-size: 10px;
}
.error-icon {
  position: absolute;
  top: 12px;
  right: 10px;
  line-height: 1.5;
}
.main-div div {
  width: 100%;
}
.no-caret .select-primary i {
  display: none;
}

.input-group-prepend,
.input-group-append {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}
.input-group-prepend {
  left: 0;
}

.input-group-append {
  right: 0;
}

.input-group-text {
  background-color: transparent;
  border: none;
}

.tooltip-icon {
  margin-left: 5px;
  cursor: help;
}

.tooltip-text {
  visibility: hidden;
  width: 350px;
  background-color: #322f35;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1000; // Ensure it's above other elements
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-icon:hover + .tooltip-text {
  visibility: visible;
  opacity: 1;
}

input.deep {
  background: rgba(96, 127, 127, 0.07) !important;
  border-color: rgb(103, 103, 103) !important;
  border-width: 1px !important;
}



</style>


