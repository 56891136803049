export function getColorType(color) {
  if (!color) {
    return 'light';
  }
  // Convert the color to RGB values
  const rgb = getRGB(color);

  // Calculate the luminance of the color
  const luminance = calculateLuminance(rgb);

  // Check if the luminance is below a threshold to determine if it's a dark color
  return luminance < 0.75 ? 'dark' : 'light';
}

function getRGB(color) {
  // Remove any leading '#' from the color
  const cleanedColor = color.replace('#', '');

  // Check if the cleanedColor is a three-character hex code
  if (cleanedColor.length === 3) {
    const red = parseInt(cleanedColor[0].repeat(2), 16);
    const green = parseInt(cleanedColor[1].repeat(2), 16);
    const blue = parseInt(cleanedColor[2].repeat(2), 16);
    return { red, green, blue };
  }

  // Convert the color to RGB values
  const red = parseInt(cleanedColor.substring(0, 2), 16);
  const green = parseInt(cleanedColor.substring(2, 4), 16);
  const blue = parseInt(cleanedColor.substring(4, 6), 16);

  return { red, green, blue };
}

function calculateLuminance({ red, green, blue }) {
  // Calculate the relative luminance of the color using the formula
  // L = 0.2126 * R + 0.7152 * G + 0.0722 * B
  return (0.2126 * red + 0.7152 * green + 0.0722 * blue) / 255;
}
export function smartAdjustColor(hex, options = {}) {
  const { lightness = 0, saturation = 0, hue = 0, contrast = 1.5, overDark } = options;

  // Clone the hex to prevent external mutations
  const normalizedHex = normalizeHex(hex);
  const rgb = hexToRgb(normalizedHex);
  if (!rgb) {
    console.warn(`Invalid hex color: ${normalizedHex}`);
    return normalizedHex;
  }

  // Clone the HSL object to avoid mutating the original
  const hsl = { ...rgbToHsl(rgb) };

  const MAX_LIGHTNESS = 0.65;
  const MIN_LIGHTNESS = 0.05;

  // Apply initial adjustments immutably
  const adjustedHsl = {
    l: Math.max(Math.min(hsl.l + lightness * 0.1, MAX_LIGHTNESS), MIN_LIGHTNESS),
    s: Math.max(Math.min(hsl.s + saturation * 0.1, 1), 0),
    h: (hsl.h + hue + 360) % 360
  };

  const adjustedRgb = hslToRgb(adjustedHsl);
  const adjustedHex = rgbToHex(adjustedRgb);
  // Calculate contrast between adjusted color and original
  const initialContrast = getContrastRatio(adjustedHex, normalizedHex);

  if (initialContrast < contrast) {
    const originalLuminance = getLuminance(normalizedHex);
    const fgLuminance = getLuminance(adjustedHex);

    // Determine if we should lighten or darken
    const shouldLighten = fgLuminance !== undefined
      ? (fgLuminance + originalLuminance) / 2 < fgLuminance
      : originalLuminance < fgLuminance;

    // Calculate the target luminance based on desired contrast
    let targetLuminance;

    targetLuminance = shouldLighten
      ? Math.max((fgLuminance + 0.05) * contrast - 0.05, (originalLuminance + 0.05) * contrast - 0.05)
      : Math.min((fgLuminance + 0.05) / contrast - 0.05, (originalLuminance + 0.05) / contrast - 0.05);


    // Convert target luminance to lightness
    const targetLightness = convertLuminanceToLightness(targetLuminance);

    // Adjust lightness immutably
    const finalHsl = {
      ...adjustedHsl,
      l: Math.max(Math.min(targetLightness, MAX_LIGHTNESS), MIN_LIGHTNESS)
    };

    const finalRgb = hslToRgb(finalHsl);
    return rgbToHex(finalRgb);
  }

  return adjustedHex;
}
// Helper function to normalize HEX strings
function normalizeHex(hex) {
  return hex.toLowerCase();
}
// Convert HEX to RGB with memoization and cache size limit
function hexToRgb(hex) {
  const normalizedHex = normalizeHex(hex);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(normalizedHex);
  const rgb = result
    ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    }
    : null;

  return rgb ? { ...rgb } : null; // Return a shallow copy
}
// https://css-tricks.com/converting-color-spaces-in-javascript/
function rgbToHsl(rgb) {
  const r = rgb.r / 255; //set values between 0 and 1
  const g = rgb.g / 255;
  const b = rgb.b / 255;
  const min = Math.min(r, g, b);
  const max = Math.max(r, g, b);
  const delta = max - min;
  let h = 0;
  let s = 0;
  let l = 0;

  if (delta === 0) {
    h = 0;
  } else if (max === r) {
    h = ((g - b) / delta) % 6;
  } else if (max === g) {
    h = (b - r) / delta + 2;
  } else if (max === b) {
    h = (r - g) / delta + 4;
  }

  h = Math.round(h * 60);

  if (h < 0) {
    //if value is less than 0, add 360 to make it positive
    h += 360;
  }

  l = (min + max) / 2; //calculate lightness

  //calculate saturation
  if (delta === 0) {
    s = 0;
  } else if (l < 0.5) {
    s = delta / (max + min);
  } else {
    s = delta / (2 - max - min);
  }

  return {
    h,
    s,
    l
  };
}
// https://stackoverflow.com/questions/2353211/hsl-to-rgb-color-conversion
function hslToRgb(hsl) {
  const h = hsl.h / 360;
  const { s, l } = hsl;

  let r;
  let g;
  let b;

  const hue2rgb = function hue2rgb(p, q, t) {
    if (t < 0) t += 1;
    if (t > 1) t--;
    if (t < 1 / 6) return p + (q - p) * 6 * t;
    if (t < 1 / 2) return q;
    if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
    return p;
  };

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return {
    r: Math.round(r * 255),
    g: Math.round(g * 255),
    b: Math.round(b * 255)
  };
}
function rgbToHex(rgb) {
  return `#${((1 << 24) + (rgb.r << 16) + (rgb.g << 8) + rgb.b).toString(16).slice(1)}`;
}
const MAX_CACHE_SIZE = 500;

function manageCache(cache) {
  if (cache.size > MAX_CACHE_SIZE) {
    // Remove the first (oldest) key inserted
    const firstKey = cache.keys().next().value;
    cache.delete(firstKey);
  }
}
// Calculate contrast ratio between two HEX colors with memoization and cache size limit
function getContrastRatio(hex1, hex2) {
  const normalizedHex1 = normalizeHex(hex1);
  const normalizedHex2 = normalizeHex(hex2);
  const cacheKey = `${normalizedHex1}-${normalizedHex2}`;

  const lum1 = getLuminance(normalizedHex1);
  const lum2 = getLuminance(normalizedHex2);
  const brightest = Math.max(lum1, lum2);
  const darkest = Math.min(lum1, lum2);

  const ratio = (brightest + 0.05) / (darkest + 0.05);
  return ratio;
}
// Calculate relative luminance of a HEX color with memoization and cache size limit
function getLuminance(hex) {
  const normalizedHex = normalizeHex(hex);

  const rgb = hexToRgb(normalizedHex);
  if (!rgb) return 0;
  const a = [rgb.r, rgb.g, rgb.b].map(v => {
    const normalized = v / 255;
    return normalized <= 0.03928 ? normalized / 12.92 : ((normalized + 0.055) / 1.055) ** 2.4;
  });
  const luminance = 0.2126 * a[0] + 0.7152 * a[1] + 0.0722 * a[2];
  return luminance;
}
// Helper function to convert luminance to lightness
function convertLuminanceToLightness(luminance) {
  const lightness = luminance <= 0.008856 ? luminance * 903.3 : luminance ** (1 / 3) * 116 - 16;
  return Math.max(0, Math.min(lightness / 100, 1));
}
