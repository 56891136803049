<template>
  <section class="main-body-wrapper">
    <div class="container p-0">
      <div class="form-wrapper congrats-content">
        <div class="container-fluid">
          <h1>Unsupported Browser</h1>
          <p>
            {{ getFacilitator.name }} is committed to providing modern, secure technologies to our
            customers and as such we no longer support the Internet Explorer 11
            browser per Microsoft's recommendation. Please access this
            application from any of the following browsers:
            <a href="https://www.microsoft.com/edge" target="_blank"
              >Microsoft Edge</a
            >,
            <a href="https://www.google.com/chrome" target="_blank">Chrome</a>,
            <a href="https://www.mozilla.com" target="_blank">Firefox</a>, or
            <a href="https://www.apple.com/safari" target="_blank">Safari</a>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "UnsupportedBrowser",
  computed: {
    ...mapGetters(["getFacilitator"])
  }
};
</script>
