<template>
  <section class="main-body-wrapper">
    <div class="container p-0">
      <div class="form-wrapper congrats-content">
        <div class="container-fluid">
          <h1>Application Approved</h1>
          <div class="congrats-image">
            <img src="/static/img/congrats-approved.png" />
          </div>
          <h1>Account: {{ account }}</h1>
          <p>
            We have approved your application and will send your <br />login credentials
            to you via email so you can access your backoffice.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Success',
  mounted() {
    this.$store.commit('MARK_STEP_COMPLETED', 4); // Updated from setStepProgress
    localStorage.clear();
    window.addEventListener('popstate', this.preventBackButton);
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.preventBackButton);
  },
  methods: {
    preventBackButton(event) {
      event.preventDefault();
      this.$router.replace({ name: 'GetStarted' }).catch(()=>{});
    }
  }
};
</script>
