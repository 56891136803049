// import accounting from 'accounting-js';

// export const formatColumnMoney = amounts => {
//   return accounting.formatColumn(amounts, { symbol: '$ ' });
// };

export const formatCurrency = amount => {
  return amount || amount === 0
    ? new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(amount)
    : '';
};
