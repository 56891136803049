<template>
  <section class="main-body-wrapper">
    <div class="container">
      <div class="initiate-failure-content-outer">
        <div class="initiate-failure"></div>
        <div class="initiate-failure-page-title">
          <h1>Platform is not active yet !</h1>
          <h2>
            Please contact your sale representative to confirm the active
            account status.
          </h2>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'InitiateFailure',
  data() {
    return {
      checkStatus: ''
    };
  },
  methods: {}
};
</script>
