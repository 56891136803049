<template>
  <section class="main-body-wrapper">
    <div class="container p-0">
      <div class="form-wrapper congrats-content">
        <div class="container-fluid">
          <h1>Your invite has expired</h1>
          <p>
            Unfortunately, the invite you're trying to access has expired. You can start a
            new process by clicking the link below.
            <br /><br />
            <router-link :to="{ name: 'Welcome' }"> Get Started </router-link>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'InviteExpired'
};
</script>
