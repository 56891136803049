// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import locale from 'element-ui/lib/locale';
import lang from 'element-ui/lib/locale/lang/en';
import 'element-ui/lib/theme-chalk/index.css';
import Maska from 'maska';
import VeeValidate from 'vee-validate';
import Vue from 'vue';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Toasted from 'vue-toasted';
import App from './App';
import router from './router';
import baseMiddleware from './router/middlewares/baseMiddleware';
import store from './store';
locale.use(lang);

import axios from 'axios';
import BaseCustomInput from './components/BaseCustomInput';
import BaseDatePicker from './components/BaseDatePicker';
import BasePhoneInput from './components/BasePhoneInput';
import BaseSelect from './components/BaseSelect';
import SimplePasswordInput from './components/SimplePasswordInput';

import config from './config';

import { initScrollbar, destroyScrollbar } from './plugins/perfect-scrollbar';

Vue.use(Maska);
Vue.use(Toasted);
Vue.use(VeeValidate, { fieldsBagName: 'veeFields', inject: false });
Vue.use(Loading, { opacity: 0.2 });
Vue.component(BaseCustomInput.name, BaseCustomInput);
Vue.component(BaseSelect.name, BaseSelect);
Vue.component(BaseDatePicker.name, BaseDatePicker);
Vue.component(BasePhoneInput.name, BasePhoneInput);
// Vue.component(BaseCustomPasswordInput.name, BaseCustomPasswordInput);
Vue.component(SimplePasswordInput.name, SimplePasswordInput);

Vue.directive('focus', {
  inserted: function (el) {
    el.focus();
  }
});
Vue.directive('scrollbar', {
  inserted(el, binding) {
    el._psInstance = initScrollbar(el, binding.value);
  },
  unbind(el) {
    destroyScrollbar(el._psInstance);
    delete el._psInstance;
  }
});

axios.defaults.baseURL = config.ROOT_API;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status !== 401) return Promise.reject(error);
    const current = router.history.current;
    if (current.name == 'GetStarted' && current.name == 'continue') {
      router.push({
        name: 'continue',
        spProductSlug: current.params.spProductSlug
      });
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);
Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
Vue.prototype.$ENV = config.ENV;
Vue.prototype.$config = config;

router.beforeEach(baseMiddleware({ store, axios }));
let vueApp = new Vue({ store });
const loader = vueApp.$loading.show();
vueApp.$store
  .dispatch('GET_FACILITATOR')
  .then(async () => {
    await vueApp.$store.dispatch('SSO');
    initializeVue();
  })
  .finally(() => loader.hide());

function initializeVue() {
  /* eslint-disable no-new */
  vueApp = new Vue({
    el: '#app',
    store,
    router,
    render: h => h(App)
  });
}
