<template>
  <section id="merchant-type" class="main-body-wrapper">
    <form @submit.prevent="handleSubmit">
      <div class="page-title w-100">
        <h1>Choose your business type</h1>
        <span class="description">
          Select the type of business and entity structure that best matches your
          business.
        </span>
        <h2 class="sub-title">Business type & structure</h2>
        <span>Select the type of business and related structure below</span>
      </div>
      <div class="tabs">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{ active: activeTab === 'company' }"
              @click="activeTab = 'company'"
              tabindex="0"
            >
              <i class="fas fa-store-alt"></i>
              <span class="tab-text">Company</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{ active: activeTab === 'government' }"
              @click="activeTab = 'government'"
              tabindex="0"
            >
              <i class="fas fa-building"></i>
              <span class="tab-text">Government</span>
            </a>
          </li>
        </ul>
        <div class="tab-content">
          <template v-for="(type, index) in currentTypes">
            <div
              v-if="!type.collapsible"
              class="form-check"
              :key="index"
              @click="selectType(type.value)"
            >
              <label :for="type.value">
                <span class="form-check-label">{{ type.label }}</span>
                <input
                  class="form-check-radio"
                  type="radio"
                  name="businessType"
                  :id="type.value"
                  :value="type.value"
                  v-model="ownershipType"
                  tabindex="0"
                />
              </label>
            </div>
            <template v-else>
              <button
                @click.prevent="toggleCollapsible(index)"
                class="btn btn-link collapsible-trigger"
                :class="{ expanded: type.isOpen }"
                tabindex="0"
              >
                <label>{{ type.label }}</label>
              </button>
              <transition name="collapsible">
                <div v-show="type.isOpen" class="collapse-content">
                  <div
                    v-for="(item, itemIndex) in type.items"
                    :key="`collapsible-${itemIndex}`"
                    class="form-check"
                    @click="selectType(item.value)"
                  >
                    <label :for="item.value">
                      <span class="form-check-label">{{ item.label }}</span>
                      <input
                        :ref="`collapsible-${itemIndex}`"
                        class="form-check-radio"
                        type="radio"
                        name="businessType"
                        :id="item.value"
                        :value="item.value"
                        v-model="ownershipType"
                        tabindex="0"
                      />
                    </label>
                  </div>
                </div>
              </transition>
            </template>
          </template>
        </div>
      </div>
      <div class="row mt-2 px-0">
        <div class="col-12 px-0">
          <div class="d-flex flex-md-row flex-column-reverse justify-content-center">
            <div class="otp-submit-btn w-100">
              <div
                class="d-flex flex-column justify-content-center align-items-center text-center"
              >
                <button
                  type="submit"
                  class="btn continue-button"
                  :disabled="!this.ownershipType || isNavigating"
                  tabindex="-1"
                >
                  {{ isNavigating ? 'Processing...' : 'Continue' }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  IDENTITY_TYPE,
  OWNERSHIP_TYPES,
  SM_APPLICATION_STATUS,
  SUBMERCHANT_STATUS
} from '../helpers/constants';

export default {
  name: 'MerchantType',
  data() {
    return {
      OWNERSHIP_TYPES,
      merchantTypes: [],
      ownershipType: undefined,
      activeTab: 'company',
      companyTypes: [],
      governmentTypes: [],
      openSeparators: {},
      isNavigating: false
    };
  },
  computed: {
    ...mapGetters(['getSaveAndExit']),
    currentTypes() {
      return this.activeTab === 'company' ? this.companyTypes : this.governmentTypes;
    }
  },
  watch: {
    getSaveAndExit(newValue) {
      if (newValue) {
        this.saveAndExit();
      }
    }
  },
  async mounted() {
    document.addEventListener('keydown', this.handleKeydown);
    const loader = this.$loading.show();
    this.$store.commit('MARK_STEP_COMPLETED', 1); // Updated from setStepProgress
    try {
      if (this.$route.query.smApplicationId) {
        try {
          const response = await this.$axios({
            url: `/onboarding/accounts/${this.$route.query.smApplicationId}/open`,
            method: 'GET'
          });
          const { data } = response;
          if (data.token) {
            this.$axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
            const identityResponse = await this.initializeMerchantData();
            if (identityResponse.data)
              this.navigateToProperStep(
                identityResponse.data?.status,
                identityResponse.data?.subMerchantStatus
              );
          } else if (data.expired) {
            this.$toasted.show('The link has expired', {
              type: 'error',
              duration: 3000,
              position: 'top-right'
            });
            localStorage.clear();
          }
        } catch (error) {
          this.$toasted.show('Unable to get the merchant application', {
            type: 'error',
            duration: 3000,
            position: 'top-right'
          });
        } finally {
          loader.hide();
        }
      } else {
        const identityResponse = await this.initializeMerchantData();
        if (identityResponse) {
          if (['review', 'approved'].includes(identityResponse.data.status)) {
            this.$router
              .replace({ name: 'GetStarted', params: { _normalPush: true } })
              .catch(() => {});
          }
        }
      }
    } catch (error) {
      const errorStatus = error.response?.status;
      const errorMessage = error.response?.data?.message
        ? error.response.data.message
        : 'Server side error, please contact the support team.';
      const logObject = {
        module: 'onboarding',
        error: errorMessage,
        details: {
          endpoint: '/onboarding/accounts/${this.$route.query.smApplicationId}/open',
          data: {}
        }
      };
      this.$store.commit('LOG_ERROR', { errorMessage, errorStatus });
      if (errorStatus === 401) {
        this.$router
          .replace({ name: 'GetStarted', params: { _normalPush: true } })
          .catch(() => {});
      }
    } finally {
      loader.hide();
    }
  },
  methods: {
    ...mapActions(['SET_SIGNIFICANT_CHANGES']),
    async initializeMerchantData() {
      await this.getSpProductMerchantTypes();
      await this.getSpProductOwnershipTypes();
      const identityResponse = await this.$axios({
        url: '/onboarding/accounts/identity',
        method: 'GET'
      });
      if (identityResponse.data) {
        this.setOwnershipType(identityResponse.data.identity.business.ownershipType);
      }
      return identityResponse;
    },
    async saveAndExit() {
      try {
        if (this.ownershipType) {
          const data = {
            ownershipType: this.ownershipType,
            identityType:
              this.ownershipType === OWNERSHIP_TYPES.SOLE_PROP
                ? IDENTITY_TYPE.INDIVIDUAL
                : this.activeTab === 'company'
                  ? IDENTITY_TYPE.BUSINESS
                  : IDENTITY_TYPE.GOVERNMENT
          };

          // Fetch current identity data from server
          const currentIdentity = await this.$axios({
            url: '/onboarding/accounts/identity',
            method: 'GET'
          });

          const currentOwnershipType =
            currentIdentity.data?.identity?.business?.ownershipType;
          const currentIdentityType = currentIdentity.data?.identity?.type;

          // Only update and set significant changes if there's a difference
          if (
            currentOwnershipType !== data.ownershipType ||
            currentIdentityType !== data.identityType ||
            currentOwnershipType === undefined
          ) {
            await this.$axios({
              url: '/onboarding/accounts/identity/type',
              method: 'PUT',
              data
            });
            this.SET_SIGNIFICANT_CHANGES({ hasChanges: true, step: 1 });
          }
        }
        this.$router
          .push({ name: 'ApplicationSaved', params: { _normalPush: true } })
          .catch(() => {});
      } catch (error) {
        const errorStatus = error.response?.status;
        const errorMessage = error.response?.data.message
          ? error.response.data.message
          : 'Server side error, please contact the support team.';
        this.$store.commit('LOG_ERROR', { errorMessage, errorStatus });
      }
    },
    async next() {
      const data = {
        ownershipType: this.ownershipType,
        identityType:
          this.ownershipType === OWNERSHIP_TYPES.SOLE_PROP
            ? IDENTITY_TYPE.INDIVIDUAL
            : this.activeTab === 'company'
              ? IDENTITY_TYPE.BUSINESS
              : IDENTITY_TYPE.GOVERNMENT
      };
      try {
        // Fetch current identity data from server
        const currentIdentity = await this.$axios({
          url: '/onboarding/accounts/identity',
          method: 'GET'
        });

        const currentOwnershipType =
          currentIdentity.data?.identity?.business?.ownershipType;
        const currentIdentityType = currentIdentity.data?.identity?.type;

        // Only update and set significant changes if there's a difference
        if (
          currentOwnershipType !== data.ownershipType ||
          currentIdentityType !== data.identityType ||
          currentOwnershipType === undefined
        ) {
          await this.$axios({
            url: '/onboarding/accounts/identity/type',
            method: 'PUT',
            data
          });
          this.SET_SIGNIFICANT_CHANGES({ hasChanges: true, step: 1 });
        }
        this.navigate();
      } catch (error) {
        const errorStatus = error.response?.status;
        const errorMessage = error.response?.data?.message
          ? error.response.data.message
          : 'Server side error, please contact the support team.';
        const logObject = {
          module: 'onboarding',
          error: errorMessage,
          details: {
            page: 'merchantType.next',
            endpoint: '/onboarding/accounts/identity',
            data
          }
        };
        this.$store.commit('LOG_ERROR', { errorMessage, errorStatus });
      }
    },
    async navigateToProperStep(smAppStatus, smStatus) {
      let targetRoute = null;

      if (smStatus === SUBMERCHANT_STATUS.BUSINESS_INFO) {
        this.$store.commit('MARK_STEP_COMPLETED', 1);
        targetRoute = { name: 'BankForm', params: { _normalPush: true } };
      } else if (smStatus === SUBMERCHANT_STATUS.PAYOUT) {
        this.$store.commit('MARK_STEP_COMPLETED', 2);
        targetRoute = { name: 'ConfirmProfile', params: { _normalPush: true } };
      } else if (smAppStatus === SM_APPLICATION_STATUS.SMS_VALID) {
        targetRoute = { name: 'MerchantType', params: { _normalPush: true } };
      } else if (
        smAppStatus === SM_APPLICATION_STATUS.TYPE_SELECTED ||
        smAppStatus === SM_APPLICATION_STATUS.IDENTITY
      ) {
        await this.navigate();
        return;
      }

      if (targetRoute) {
        await this.$router.push(targetRoute).catch(() => {});
      }

      this.$store.dispatch('RESET_SIGNIFICANT_CHANGES');
    },
    async navigate() {
      const query = { ...this.$route.query, smApplicationId: undefined };
      if (this.ownershipType) {
        this.$store.commit('MARK_STEP_VISITED', 1); // Updated from setStepProgress
        await this.$router
          .push({ name: 'CompanyForm', query, params: { _normalPush: true } })
          .catch(() => {});
      } else {
        await this.$router
          .push({ name: 'MerchantType', query, params: { _normalPush: true } })
          .catch(() => {});
      }
    },
    async getSpProductMerchantTypes() {
      try {
        const response = await this.$axios({
          url: `/onboarding/spproducts/${this.$route.params.spProductSlug}/merchant-types`,
          method: 'GET'
        });
        if (Array.isArray(response.data)) this.merchantTypes = response.data;
      } catch (error) {
        const errorStatus = error.response?.status;
        const errorMessage = error.response?.data.message
          ? error.response.data.message
          : 'Server side error, please contact the support team.';
        this.$store.commit('LOG_ERROR', { errorMessage, errorStatus });
      }
    },
    async getSpProductOwnershipTypes() {
      const response = await this.$axios({
        url: `/onboarding/spproducts/${this.$route.params.spProductSlug}/ownership-types`,
        method: 'GET'
      });
      if (!Array.isArray(response.data)) {
        return;
      }

      this.governmentTypes = response.data.includes(OWNERSHIP_TYPES.GOV)
        ? [
            {
              label: 'Federal, State or Local Government Entity',
              value: OWNERSHIP_TYPES.GOV
            }
          ]
        : [];

      this.companyTypes = [
        { label: 'LLC (Single or Multi-member)', value: OWNERSHIP_TYPES.LLC },
        {
          label: 'Private Corporation (S-corp or C-corp)',
          value: OWNERSHIP_TYPES.PRIVATE_CORP
        },
        { label: 'Sole Proprietorship', value: OWNERSHIP_TYPES.SOLE_PROP }
      ].filter(o => response.data.includes(o.value));

      const otherCompanyTypes = [
        { label: 'Partnership', value: OWNERSHIP_TYPES.PARTNERSHIP },
        { label: 'Publicly Traded Company', value: OWNERSHIP_TYPES.PUBLIC_CORP },
        {
          label: 'Limited Partnership (LP)',
          value: OWNERSHIP_TYPES.LIMITED_PARTNERSHIP
        },
        {
          label: 'Limited Liability Partnership (LLP)',
          value: OWNERSHIP_TYPES.LIMITED_LIABILITY_PARTNERSHIP
        }
      ].filter(o => response.data.includes(o.value));

      if (otherCompanyTypes.length > 0) {
        this.companyTypes.push({
          label: 'Other',
          collapsible: true,
          isOpen: false,
          items: otherCompanyTypes
        });
      }
    },
    toggleCollapsible(index, toggle = true) {
      const type = this.currentTypes[index];
      if (toggle) {
        type.isOpen = !type.isOpen;
      }
      this.$nextTick(() => {
        if (type.isOpen) {
          this.$refs[`collapsible-${index}`]?.[0]?.focus();
        }
      });
    },
    setOwnershipType(ownershipType) {
      this.ownershipType = ownershipType;
      const foundType = this.companyTypes.find(
        type =>
          type.value === ownershipType ||
          (type.collapsible && type.items.some(item => item.value === ownershipType))
      );

      if (foundType) {
        this.activeTab = 'company';
        if (foundType.collapsible) {
          this.toggleCollapsible(this.companyTypes.indexOf(foundType));
        }
      } else if (this.governmentTypes.some(type => type.value === ownershipType)) {
        this.activeTab = 'government';
      }
    },
    handleKeydown(event) {
      const tabs = ['company', 'government'];
      const currentIndex = tabs.indexOf(this.activeTab);
      switch (event.key) {
        case 'ArrowRight':
          const nextTabIndex = (currentIndex + 1) % tabs.length;
          this.activeTab = tabs[nextTabIndex];
          this.ownershipType = this.currentTypes[0].value; // Automatically select the first type of the new tab
          break;
        case 'ArrowLeft':
          const prevTabIndex = (currentIndex - 1 + tabs.length) % tabs.length;
          this.activeTab = tabs[prevTabIndex];
          this.ownershipType = this.currentTypes[0].value; // Automatically select the first type of the new tab
          break;
        case 'ArrowDown':
          this.navigateTypesLinearly('forwards');
          break;
        case 'ArrowUp':
          this.navigateTypesLinearly('backwards');
          break;
        case ' ':
          this.selectOrToggleType();
          break;
        case 'Tab':
          event.preventDefault();
          if (event.shiftKey) {
            this.navigateTypesLinearly('backwards');
          } else {
            this.navigateTypesLinearly('forwards');
          }
          break;
        case 'Enter':
          this.handleSubmit();
          break;
      }
    },
    navigateTypesLinearly(direction) {
      const flattenTypes = typesArray => {
        const flatTypes = [];
        typesArray.forEach((type, index) => {
          if (type.collapsible) {
            flatTypes.push(...type.items.map(item => ({ ...item, parentIndex: index })));
          } else {
            flatTypes.push({ ...type, parentIndex: index });
          }
        });
        return flatTypes;
      };

      let flatTypes = flattenTypes(this.currentTypes);
      let currentIndex = flatTypes.findIndex(type => type.value === this.ownershipType);
      if (currentIndex === -1) {
        currentIndex = 0;
      }

      let nextIndex =
        (currentIndex + (direction === 'forwards' ? 1 : -1) + flatTypes.length) %
        flatTypes.length;

      const parentType = this.currentTypes[flatTypes[nextIndex].parentIndex];
      if (parentType.collapsible && !parentType.isOpen) {
        this.toggleCollapsible(flatTypes[nextIndex].parentIndex, true);
      }

      if (
        (currentIndex === 0 && direction === 'backwards') ||
        (currentIndex === flatTypes.length - 1 && direction === 'forwards')
      ) {
        this.activeTab = this.activeTab === 'company' ? 'government' : 'company';
        flatTypes = flattenTypes(this.currentTypes);
        nextIndex = direction === 'forwards' ? 0 : flatTypes.length - 1;
      }

      this.ownershipType = flatTypes[nextIndex].value;
    },
    selectOrToggleType() {
      const currentType = this.currentTypes.find(
        type => type.value === this.ownershipType
      );
      if (currentType && currentType.collapsible) {
        this.toggleCollapsible(this.currentTypes.indexOf(currentType));
      }
    },
    async handleSubmit() {
      if (!this.ownershipType || this.isNavigating) return;

      this.isNavigating = true;
      try {
        await this.next();
      } catch (error) {
        console.error('Form submission or navigation failed:', error);
      } finally {
        this.isNavigating = false;
      }
    },
    selectType(value) {
      this.ownershipType = value;
    }
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleKeydown);
  }
};
</script>

<style lang="scss">
#merchant-type {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    max-width: 480px;
    width: 100%;
  }

  .page-title {
    text-align: left;
    & > h1 {
      margin-bottom: 10px;
    }
    & > h2 {
      margin-top: 15px;
      font-size: 24px;
      font-weight: 400;
    }
    & > span {
      font-size: 14px;
      font-weight: 300;
    }
  }

  .tabs {
    margin-top: 20px;
  }

  .nav-tabs {
    display: flex;
    border-bottom: 2px solid #dee2e6;

    .nav-item {
      flex: 1;
    }
  }

  .nav-link {
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    i {
      font-size: 1.2rem;
      color: #7f7f7f;
    }

    .tab-text {
      color: #7f7f7f;
      font-size: 0.85rem;
    }

    &.active {
      border-color: transparent;
      border-bottom: 2px solid black;
      background: #dcdcdc;
    }
  }

  .tab-content {
    border: 1px solid #dee2e6;
  }

  .form-check {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 20px;
    position: relative;
    cursor: pointer;

    border-bottom: 1px solid #ccc;

    &:not(:first-of-type)::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }

    &:last-child {
      border-bottom: none;
    }

    label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      cursor: pointer;
    }

    .form-check-label {
      flex-grow: 1;
      text-align: left;
      margin-right: 1rem;
    }

    input[type='radio'] {
      cursor: pointer;
    }
  }

  .collapsible-trigger {
    background: none;
    border: none;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: left;
    margin: 10px 0 0 0;
    padding-bottom: 10px;

    &:hover,
    &:focus {
      text-decoration: none;
    }

    &::after {
      font-family: 'Font Awesome 5 Free';
      content: '\f078';
      font-weight: 900;
      color: #bcbcbc;
      transition: transform 0.3s ease-in-out;
      padding: 0 20px;
    }

    &.expanded {
      border-bottom: 1px solid #ccc;

      &::after {
        transform: rotate(180deg);
      }
    }

    & > label {
      padding: 0 20px;
    }
  }

  .collapsible-enter-active,
  .collapsible-leave-active {
    transition: opacity 0.5s;
  }

  .collapsible-enter,
  .collapsible-leave-to {
    opacity: 0;
  }
}
.form-wrapper {
  max-width: 830px;
}
</style>
