<template>
  <footer id="footer">
    <div v-if="showSaveAndExit" id="save-and-exit">
      <button tabindex="-1" @click="saveAndExit">
        <span>Save & Exit</span>
      </button>
    </div>
    <div class="footer-content">
      <div class="branding">
        <span class="footer-text">Powered by</span>
        <img class="footer-logo" src="/static/img/jupico-logo.png" alt="Jupico Logo" />
      </div>
      <nav class="footer-nav">
        <ul class="footer-links">
          <li>
            <a href="https://help.jupico.com/" target="_blank" rel="noopener noreferrer"
              >Help</a
            >
          </li>
          <li><a ref="termsLink" href data-toggle="modal" data-target="#terms-of-service">Terms</a></li>
          <li><a ref="privacyLink" href data-toggle="modal" data-target="#privacy-policy">Privacy</a></li>
        </ul>
      </nav>
    </div>
    <div v-if="showSaveAndExit" class="flex-spacer"></div>
    <div id="terms-and-privacy">
      <TermsOfService ref="termsModal" />
      <PrivacyPolicy ref="privacyModal" />
      <ESignConsent />
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex';
import TermsOfService from '../components/TermsOfService';
import PrivacyPolicy from '../components/PrivacyPolicy';
import ESignConsent from '../components/ESignConsent';

export default {
  name: 'FooterComponent',
  components: {
    TermsOfService,
    PrivacyPolicy,
    ESignConsent
  },
  computed: {
    ...mapGetters(['isTermsOpen', 'isPrivacyOpen']),
    showSaveAndExit() {
      return [
        'MerchantType',
        'CompanyForm',
        'VerifyOwnership',
        'BankForm',
        'ConfirmProfile'
      ].includes(this.$route.name);
    }
  },
  watch: {
    isTermsOpen() {
      this.$refs.termsLink.click();
    },
    isPrivacyOpen() {
      this.$refs.privacyLink.click();
    }
  },
  methods: {
    async saveAndExit() {
      await this.$store.dispatch('ACTIVATE_SAVE_AND_EXIT');
    }
  }
};
</script>

<style lang="scss">
#footer {
  position: relative;
  background-color: #333;
  color: #fff;
  // padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 87px;

  #save-and-exit {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;

    & > button {
      background-color: transparent;
      color: #fff;
      border: 1px solid #79747e;
      padding: 10px 24px;
      border-radius: 100px;
      font-size: 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      transition: background-color 0.3s ease;
      white-space: nowrap;
      position: relative;

      &:before {
        content: '\f2f5';
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
      }

      @media (max-width: 600px) {
        padding: 5px 8px;
        & > span {
          display: none;
        }
      }

      &:hover {
        background-color: #79747e;
      }
    }
  }

  .footer-content {
    display: flex;
    flex: 1;
    align-items: end;
    justify-content: center;
    max-width: 968px;
    width: 100%;

    @media screen and (min-width: calc(968px + 160px)) {
      min-width: 968px;
    }

    @media screen and (max-width: 600px) {
      zoom: 0.8;
    }

    .branding {
      margin-left: 25px;
      position: relative;

      .footer-text {
        position: absolute;
        top: 0;
        left: -24px;
        margin: 0;
      }

      .footer-logo {
        height: auto;
        max-height: 45px;
        margin-top: 25px;
      }
    }

    .footer-nav {
      ul {
        list-style: none;
        display: flex;
        margin: 0 0 10px 30px;
        padding: 0;
        gap: 30px;

        li {
          margin: 0;

          a {
            color: #fff;
            text-decoration: none;
            transition: color 0.3s ease;

            &:hover {
              color: #8e8787;
            }
          }
        }
      }
    }
  }

  .flex-spacer {
    flex: 0 1 calc((100vw - 968px) / 2);
  }

  #terms-and-privacy * {
    color: black;
  }
}
</style>
