<template>
  <div class="d-flex flex-column">
    <div v-if="business.address" class="d-flex flex-column">
      <div class="owner-detail d-flex justify-content-between">
        <button
          ref="editButton"
          class="btn edit-button"
          @click="$router.push({ name: 'CompanyForm' })"
        >
          <i class="fas fa-pencil-alt"></i>
        </button>
      </div>
      <div class="d-grid grid-columns-2 gap-4">
        <div style="grid-column: span 2">
          <label for="businesslegalname">
            <span>Business legal name</span>
          </label>
          <span>{{ business.legalName }}</span>
        </div>
        <div>
          <label for="doingbusinessas">
            <span>Doing business as</span>
          </label>
          <span>{{ business.dbaName }}</span>
        </div>
        <div>
          <label for="billingdescriptor">
            <span>Billing descriptor</span>
          </label>
          <span>{{ business.billingDescriptor }}</span>
        </div>
        <div>
          <label for="type">
            <span>Ownership Type</span>
          </label>
          <span>{{ getOwnershipType(business.ownershipType) }}</span>
        </div>
        <div>
          <label for="federalTaxId">
            <span>{{ tinTypeLabel(business.tinType) }}</span>
          </label>
          <span>{{ formatEINOrSSN(business.federalTaxId, business.tinType) }}</span>
        </div>
        <div>
          <label for="phonenumber">
            <span>Phone</span>
          </label>
          <span>{{ business.phoneNumber }}</span>
        </div>
        <div style="grid-column: span 2">
          <label for="websitelink">
            <span>Website</span>
          </label>
          <span>{{ business.websiteUrl }}</span>
        </div>
        <div>
          <label for="streetNum">
            <span>Address</span>
          </label>
          <span>{{
            `${business.address.streetNum} ${business.address.streetName}`
          }}</span>
        </div>
        <div>
          <label for="city">
            <span>City</span>
          </label>
          <span>{{ business.address.city }}</span>
        </div>
        <div>
          <label for="State">
            <span>State</span>
          </label>
          <span>{{ business.address.state }}</span>
        </div>
        <div>
          <label for="Zip code">
            <span>Zip code</span>
          </label>
          <span>{{ business.address.postalCode }}</span>
        </div>
        <div>
          <label for="country">
            <span>Country</span>
          </label>
          <span>{{ business.address.country || 'US' }}</span>
        </div>
      </div>
    </div>
    <div
      class="d-flex flex-column"
      v-for="(person, index) in individuals"
      :key="index"
      id="add-owner-loop"
      v-if="!ignoreIndividuals"
    >
      <hr id="form-border" class="my-4" />
      <IndividualData :individual="person" :type="identity.type" />
    </div>
  </div>
</template>

<script>
import IndividualData from '../components/IndividualData';
import { IDENTITY_TYPE } from '../helpers/constants';
import {
  validPhoneFormat,
  mustHaveStreetName,
  mustHaveStreetNumber,
  validUrl,
  validfederalTaxId,
  validzipcodeRegex
} from '@/helpers/CustomValidations';

export default {
  name: 'CompanyData',
  inject: ['$validator'],
  components: { IndividualData },
  props: { identity: { type: Object }, ignoreIndividuals: { type: Boolean } },
  created() {
    this.$validator.extend(mustHaveStreetName.name, mustHaveStreetName.obj);
    this.$validator.extend(mustHaveStreetNumber.name, mustHaveStreetNumber.obj);
    this.$validator.extend(validPhoneFormat.name, validPhoneFormat.obj);
    this.$validator.extend(validUrl.name, validUrl.obj);
  },
  computed: {
    business() {
      return (this.identity && this.identity.business) || {};
    },
    individuals() {
      if (this.identity) {
        return this.identity.type === IDENTITY_TYPE.GOVERNMENT
          ? this.identity.contacts
          : this.identity.owners;
      }
      return [];
    },
    isNotIdentityPublic() {
      return this.identity.type !== IDENTITY_TYPE.GOVERNMENT;
    },
    isNotIdentityIndividual() {
      return !(
        this.identity.type === IDENTITY_TYPE.INDIVIDUAL ||
        this.business.ownershipType === 'SoleProprietorship'
      );
    },
    modelValidations() {
      const validations = {
        business: {
          legalName: { required: this.isNotIdentityIndividual, max: 40 },
          dbaName: { required: true, regex: /^[a-zA-Z0-9 _\-&.',]*$/, max: 40 },
          federalTaxId: { required: true, regex: validfederalTaxId },
          phoneNumber: { required: true, validPhoneFormat: true },
          address: {
            streetNum: { required: true },
            streetName: { required: true, max: 40 },
            city: { required: true, max: 28 },
            state: { required: true },
            postalCode: { required: true, regex: validzipcodeRegex },
            country: { required: true }
          },
          websiteUrl: { required: true, validUrl: true, max: 112 },
          expectedMonthlyVolume: {
            required: true,
            decimal: 2,
            max_value: 10000000.0,
            min_value: 0.01
          },
          businessEstablishedDate: { required: true },
          ownershipType: { required: true },
          mccCode: { required: true }
        }
      };
      if (this.isNotIdentityPublic) {
        validations.owners = {
          length: { min_value: 1 },
          totalOwnership: { min_value: 76, max_value: 100 }
        };
      }
      return validations;
    }
  },
  methods: {
    getOwnershipType(type) {
      if (!type) return '';
      const ownershipTypes = [
        { value: 'PrivateCorporation', label: 'Private Corporation' },
        { value: 'SoleProprietorship', label: 'Sole Proprietorship' },
        { value: 'LLC', label: 'LLC' },
        { value: 'Partnership', label: 'Partnership' },
        { value: 'NonProfit', label: 'Non Profit' },
        { value: 'Trust', label: 'Trust' },
        { value: 'PublicCorporation', label: 'Public Corporation' },
        { value: 'Government', label: 'Government' }
      ];
      return ownershipTypes.find(o => o.value === type).label;
    },
    formatEIN(ein = '') {
      const splittedEIN = ein.split('');
      splittedEIN.splice(2, 0, '-');
      return splittedEIN.join('');
    },
    async validate() {
      const validations = this.modelValidations.business;

      let isValid = true;
      const errors = {};

      const validateField = async (obj, field, rules) => {
        try {
          const value = obj[field];
          const result = await this.$validator.verify(value, rules, { name: field });
          if (!result.valid) {
            isValid = false;
            errors[field] = result.errors[0];
            console.error(`Validation failed for ${field}:`, result.errors[0]);
          }
        } catch (error) {
          console.error(`Error validating field ${field}:`, error);
          isValid = false;
          errors[field] = 'Validation error occurred';
        }
      };

      try {
        for (const [field, rules] of Object.entries(validations)) {
          if (field === 'address') {
            if (this.business.address) {
              for (const [addressField, addressRules] of Object.entries(rules)) {
                await validateField(this.business.address, addressField, addressRules);
              }
            }
          } else {
            await validateField(this.business, field, rules);
          }
        }

        if (!isValid) {
          console.error('Company data validation errors:', errors);
        }

        return isValid;
      } catch (error) {
        console.error('An error occurred during validation:', error);
        return false;
      }
    },
    tinTypeLabel(tinType) {
      switch (tinType) {
        case '1':
          return 'SSN';
        case '2':
          return 'EIN';
        case '3':
          return 'ITIN';
        default:
          return 'EIN/SSN';
      }
    },
    formatEINOrSSN(tin, tinType) {
      if (tinType === '1') {
        // SSN
        return `${tin.slice(0, 3)}-${tin.slice(3, 5)}-${tin.slice(5)}`;
      } else if (tinType === '2') {
        // EIN
        return `${tin.slice(0, 2)}-${tin.slice(2)}`;
      }
      return tin; // Return as is if format is not applicable
    }
  }
};
</script>
