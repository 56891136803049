<template>
  <div
    class="modal custom-modal fade"
    id="e-sing"
    tabindex="-1"
    role="dialog"
    aria-labelledby="e-sing"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">E-Sign Consent</h4>
          <button
            type="button"
            class="close close-custom"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="privacy-content text-justify">
            <div class="container">
              Last updated: August 31, 2023<br />
              <p>
                Jupi Co DBA {{ getFacilitator.name }} and its affiliates and third party
                products (“{{ getFacilitator.name }}”) may need to provide you with
                certain communications, notices, agreements, billing statements, or
                disclosures in writing (“Communications”) regarding our Services. Your
                agreement to this E-sign Consent confirms your ability and consent to
                receive Communications electronically from {{ getFacilitator.name }}, its
                affiliates, and its third party products, rather than in paper form, and
                to the use of electronic signatures in our relationship with you
                (“Consent”). If you choose not to agree to this Consent or you withdraw
                your consent, you may be restricted from using the Services.
              </p>
              <p>
                <span class="terms-sub-section-title">Electronic Delivery:</span>
                By accepting this Agreement, you agree and consent to receive
                electronically all communications agreements, documents, notices and
                disclosures (collectively, “Communications”) that we or the Platform
                provide in connection with your {{ getFacilitator.name }} Account and your
                use of the Services. Communications include but are not limited to: (1)
                agreements and policies, such as this Agreement and our Privacy Policy,
                including updates thereto; (2) payment authorizations and transaction
                receipts or confirmations, (3) account statements and history, (4) and all
                federal and state tax statements and documents (we may, but are not
                obligated to, send tax-related information electronically). We may also
                use electronic signatures and obtain them from you; (5) annual
                disclosures; (6) communication in relation to delinquent accounts (which
                may also be by phone, and may be made by {{ getFacilitator.name }} or by
                anyone on its behalf, including a third-party collection agent); and (7)
                requests for {{ getFacilitator.name }} Account’s secondary authentication,
                reminders, notifications regarding updates to your
                {{ getFacilitator.name }} Account and its support. <br /><br />
                We or the Platform will provide these Communications to you by emailing
                them to you at the primary email address listed in your
                {{ getFacilitator.name }} Account registration, by texting them to you at
                the primary telephone number listed in your
                {{ getFacilitator.name }} Account registration, by emailing or texting you
                a link or instructions how to access them on a website, or (if permitted
                by law) by posting them on the Website. We or the Platform may also
                provide certain Communications (such as federal and state tax statements)
                by U.S. mail to the street address listed in your Account registration.
                Communications are considered received by you within 24 hours of the time
                they are emailed to you, posted to the Website, or mailed to you. You
                further agree that your electronic signature has the same effect as your
                manual, physical signature. <br /><br />
                By giving your consent, you are confirming that you have access to the
                necessary equipment and are able to receive, open, and print or download a
                copy of any Communications for your records. It is important for you to
                retain copies of Communications because they may not be accessible in your
                {{ getFacilitator.name }} Account at a later date.
              </p>
              <p>
                <span class="terms-sub-section-title">System Requirements:</span>
                To access and retain the electronic Communications, you will need the
                following: (1) a computer or mobile device with Internet or mobile
                connectivity. (2) for desktop website-based Communications a modern web
                browser that includes 256-bit encryption, such as the current version of
                Chrome (<a href="https://www.google.com/chrome" target="_blank"
                  >www.google.com/chrome</a
                >), Microsoft Edge (<a
                  href="https://www.microsoft.com/edge"
                  target="_blank"
                  >www.microsoft.com/edge</a
                >), Mozilla Firefox (<a href="https://www.mozilla.com" target="_blank"
                  >www.mozilla.com</a
                >), or Apple Safari (<a
                  href="https://www.apple.com/safari"
                  target="_blank"
                  >www.apple.com/safari</a
                >); (3) for application-based Communications, a recent device operating
                system that supports text messaging, downloading, and applications from
                the Apple App Store or Google Play store; and the most recent versions of
                Apple Safari or Google Chrome on iOS or Google Chrome for Android OS; (4)
                access to the email address used to create the
                {{ getFacilitator.name }} Account; and (5) sufficient storage space to
                save Communications and/or a printer to print them. <br /><br />
                By giving your consent, you are confirming that you have access to the
                necessary equipment and are able to receive, open, and print or download a
                copy of any Communications for your records. It is important for you to
                retain copies of Communications because they may not be accessible in your
                {{ getFacilitator.name }} Account at a later date.
              </p>
              <p>
                <span class="terms-sub-section-title"
                  >Requesting Paper Copies of Electronic Communications:</span
                >
                You have the right to receive Communications in paper form. To request a
                paper copy of any Communication at no charge, please write to
                {{ getFacilitator.name }}, 5830 Granite Pkwy #100-238 Plano, TX 75024,
                USA, Attn: Customer Support – Legal (“{{ getFacilitator.name }} Address”)
                within 180 days of the date of the Disclosure, specifying in detail the
                Communication you would like to receive.
              </p>
              <p>
                <span class="terms-sub-section-title"
                  >Updating Your Contact Information:</span
                >
                It is your responsibility to keep your primary email and street address up
                to date. You understand and agree that if {{ getFacilitator.name }} or the
                Platform sends you a Communication but you do not receive it because your
                primary email or street address on file is incorrect, out of date, blocked
                by your product, or you are otherwise unable to receive electronic
                Communications, {{ getFacilitator.name }} or the Platform will be deemed
                to have provided the Communication to you. Please note that if you use a
                spam filter that blocks or re-routes emails from senders not listed in
                your email address book, you must add {{ getFacilitator.name }} or the
                Platform to your email address book so that you will be able to receive
                the Communications we send to you. If your email address becomes invalid
                such that electronic Communications sent to you by
                {{ getFacilitator.name }} or the Platform are returned, then
                {{ getFacilitator.name }} or the Platform may close your
                {{ getFacilitator.name }} Account, and you will not be able to transact
                any activity using your {{ getFacilitator.name }} Account until we receive
                a valid, functioning primary email address from you.
              </p>
              <p>
                <span class="terms-sub-section-title">How to Withdraw Your Consent:</span>
                You may withdraw your consent to receive electronic Communications at any
                time, by writing to the {{ getFacilitator.name }} Address. However,
                withdrawal of your consent to receive electronic Communications may result
                in termination of your access to Services. Any withdrawal of your consent
                will be effective after a reasonable period of time for processing your
                request.
              </p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'ESignConsent',
  computed: {
    ...mapGetters(['getFacilitator'])
  }
};
</script>
