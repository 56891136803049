/* eslint-disable */
let config = {};
let hostname = window.location.hostname;
let host = window.location.host;
//PROD ENV
if (
  hostname === 'onboarding.jupiterhq.com' ||
  hostname === 'payments-onboarding.jupiterhq.com'
) {
  config.ENV = 'production';
  config.ROOT_API = 'https://payments-api.jupiterhq.com/api/v1';
  config.PLAID_PRODUCTS = 'auth';
  config.PLAID_ENV = 'production';
  config.PLAID_PUBLIC_KEY = '46225d2c76ead505b25cdc1fad27d0';
  config.PLAID_COUNTRY_CODES = 'US';
  config.BACKOFFICE_URL = 'https://accounts.jupiterhq.com/payments/login';
  config.RECOVER_PASSWORD_URL = 'https://accounts.jupiterhq.com/forgot-password';
  config.CONTINUE_URL = spProductSlug =>
    `https://payments-onboarding.jupiterhq.com/${spProductSlug}/continue`;
  document.title = 'Jupico Onboarding';
  //PROD JUPICO
} else if (
  hostname === 'onboarding.jupico.com' ||
  hostname === 'payments-onboarding.jupico.com'
) {
  config.ENV = 'production';
  config.ROOT_API = 'https://payments-api.jupico.com/api/v1';
  config.PLAID_PRODUCTS = 'auth';
  config.PLAID_ENV = 'production';
  config.PLAID_PUBLIC_KEY = '46225d2c76ead505b25cdc1fad27d0';
  config.PLAID_COUNTRY_CODES = 'US';
  config.BACKOFFICE_URL = 'https://accounts.jupico.com/payments/login';
  config.RECOVER_PASSWORD_URL = 'https://accounts.jupico.com/forgot-password';
  config.CONTINUE_URL = spProductSlug =>
    `https://payments-onboarding.jupico.com/${spProductSlug}/continue`;
  document.title = 'Jupico Onboarding';
  //SANDBOX
} else if (hostname === 'sandbox-payments-onboarding.jupiterhq.com') {
  config.ENV = 'sandbox';
  config.ROOT_API = 'https://sandbox-payments-api.jupiterhq.com/api/v1';
  config.PLAID_PRODUCTS = 'auth';
  config.PLAID_ENV = 'sandbox';
  config.PLAID_PUBLIC_KEY = '46225d2c76ead505b25cdc1fad27d0';
  config.PLAID_COUNTRY_CODES = 'US';
  config.BACKOFFICE_URL = 'https://sandbox-accounts.jupiterhq.com/payments/login';
  config.RECOVER_PASSWORD_URL = 'https://sandbox-accounts.jupiterhq.com/forgot-password';
  config.CONTINUE_URL = spProductSlug =>
    `https://sandbox-payments-onboarding.jupiterhq.com/${spProductSlug}/continue`;
  document.title = 'Jupico Onboarding';
  //SANDBOX JUPICO
} else if (hostname === 'sandbox-payments-onboarding.jupico.com') {
  config.ENV = 'sandbox';
  config.ROOT_API = 'https://sandbox-payments-api.jupico.com/api/v1';
  config.PLAID_PRODUCTS = 'auth';
  config.PLAID_ENV = 'sandbox';
  config.PLAID_PUBLIC_KEY = '46225d2c76ead505b25cdc1fad27d0';
  config.PLAID_COUNTRY_CODES = 'US';
  config.BACKOFFICE_URL = 'https://sandbox-accounts.jupico.com/payments/login';
  config.RECOVER_PASSWORD_URL = 'https://sandbox-accounts.jupico.com/forgot-password';
  config.CONTINUE_URL = spProductSlug =>
    `https://sandbox-payments-onboarding.jupico.com/${spProductSlug}/continue`;
  document.title = 'Jupico Onboarding';
  //STAGING JUPITERHQ
} else if (hostname === 'staging-payments-onboarding.jupiterhq.com') {
  config.ENV = 'sandbox';
  config.ROOT_API = 'https://staging-payments-api.jupiterhq.com/api/v1';
  config.PLAID_PRODUCTS = 'auth';
  config.PLAID_ENV = 'sandbox';
  config.PLAID_PUBLIC_KEY = '46225d2c76ead505b25cdc1fad27d0';
  config.PLAID_COUNTRY_CODES = 'US';
  config.BACKOFFICE_URL = 'https://staging-accounts.jupiterhq.com/payments/login';
  config.RECOVER_PASSWORD_URL = 'https://staging-accounts.jupiterhq.com/forgot-password';
  config.CONTINUE_URL = spProductSlug =>
    `https://staging-payments-onboarding.jupiterhq.com/${spProductSlug}/continue`;
  document.title = 'Jupico Onboarding';
  //STAGE JUPITERHQ
} else if (hostname === 'stage-payments-onboarding.jupiterhq.com') {
  config.ENV = 'sandbox';
  config.ROOT_API = 'https://stage-payments-api.jupiterhq.com/api/v1';
  config.PLAID_PRODUCTS = 'auth';
  config.PLAID_ENV = 'sandbox';
  config.PLAID_PUBLIC_KEY = '46225d2c76ead505b25cdc1fad27d0';
  config.PLAID_COUNTRY_CODES = 'US';
  config.BACKOFFICE_URL = 'https://stage-accounts.jupiterhq.com/payments/login';
  config.RECOVER_PASSWORD_URL = 'https://stage-accounts.jupiterhq.com/forgot-password';
  config.CONTINUE_URL = spProductSlug =>
    `https://stage-payments-onboarding.jupiterhq.com/${spProductSlug}/continue`;
  document.title = 'Jupico Onboarding';
  //STAGING JUPICO
} else if (hostname === 'staging-payments-onboarding.jupico.com') {
  config.ENV = 'sandbox';
  config.ROOT_API = 'https://staging-payments-api.jupico.com/api/v1';
  config.PLAID_PRODUCTS = 'auth';
  config.PLAID_ENV = 'sandbox';
  config.PLAID_PUBLIC_KEY = '46225d2c76ead505b25cdc1fad27d0';
  config.PLAID_COUNTRY_CODES = 'US';
  config.BACKOFFICE_URL = 'https://staging-accounts.jupico.com/payments/login';
  config.RECOVER_PASSWORD_URL = 'https://staging-accounts.jupico.com/forgot-password';
  config.CONTINUE_URL = spProductSlug =>
    `https://staging-payments-onboarding.jupico.com/${spProductSlug}/continue`;
  document.title = 'Jupico Onboarding';
  //STAGE JUPICO
} else if (hostname === 'stage-payments-onboarding.jupico.com') {
  config.ENV = 'sandbox';
  config.ROOT_API = 'https://stage-payments-api.jupico.com/api/v1';
  config.PLAID_PRODUCTS = 'auth';
  config.PLAID_ENV = 'sandbox';
  config.PLAID_PUBLIC_KEY = '46225d2c76ead505b25cdc1fad27d0';
  config.PLAID_COUNTRY_CODES = 'US';
  config.BACKOFFICE_URL = 'https://stage-accounts.jupico.com/payments/login';
  config.RECOVER_PASSWORD_URL = 'https://stage-accounts.jupico.com/forgot-password';
  config.CONTINUE_URL = spProductSlug =>
    `https://stage-payments-onboarding.jupico.com/${spProductSlug}/continue`;
  document.title = 'Jupico Onboarding';
  //STAGING PAYFAC
} else if (hostname === 'staging-payments-onboarding.payfac.io') {
  config.ENV = 'sandbox';
  config.ROOT_API = 'https://staging-payments-api.payfac.io/api/v1';
  config.PLAID_PRODUCTS = 'auth';
  config.PLAID_ENV = 'sandbox';
  config.PLAID_PUBLIC_KEY = '46225d2c76ead505b25cdc1fad27d0';
  config.PLAID_COUNTRY_CODES = 'US';
  config.BACKOFFICE_URL = 'https://staging-accounts.payfac.io/payments/login';
  config.RECOVER_PASSWORD_URL = 'https://staging-accounts.payfac.io/forgot-password';
  config.CONTINUE_URL = spProductSlug =>
    `https://staging-payments-onboarding.payfac.io/${spProductSlug}/continue`;
  document.title = 'Payfac Onboarding';
  //STAGE PAYFAC
} else if (hostname === 'stage-payments-onboarding.payfac.io') {
  config.ENV = 'sandbox';
  config.ROOT_API = 'https://stage-payments-api.payfac.io/api/v1';
  config.PLAID_PRODUCTS = 'auth';
  config.PLAID_ENV = 'sandbox';
  config.PLAID_PUBLIC_KEY = '46225d2c76ead505b25cdc1fad27d0';
  config.PLAID_COUNTRY_CODES = 'US';
  config.BACKOFFICE_URL = 'https://stage-accounts.payfac.io/payments/login';
  config.RECOVER_PASSWORD_URL = 'https://stage-accounts.payfac.io/forgot-password';
  config.CONTINUE_URL = spProductSlug =>
    `https://stage-payments-onboarding.payfac.io/${spProductSlug}/continue`;
  document.title = 'Payfac Onboarding';
  //DEV ENV
} else if (hostname === 'dev-payments-onboarding.jupiterhq.com') {
  config.ENV = 'development';
  config.ROOT_API = 'https://dev-payments-api.jupiterhq.com/api/v1';
  config.PLAID_PRODUCTS = 'auth';
  config.PLAID_ENV = 'sandbox';
  config.PLAID_PUBLIC_KEY = '46225d2c76ead505b25cdc1fad27d0';
  config.PLAID_COUNTRY_CODES = 'US';
  config.BACKOFFICE_URL = 'https://dev-accounts.jupiterhq.com/payments/login';
  config.RECOVER_PASSWORD_URL = 'https://dev-accounts.jupiterhq.com/forgot-password';
  config.CONTINUE_URL = spProductSlug =>
    `https://dev-payments-onboarding.jupiterhq.com/${spProductSlug}/continue`;
  document.title = 'Jupico Onboarding';
  //DEV JUPICO
} else if (hostname === 'dev-payments-onboarding.jupico.com') {
  config.ENV = 'development';
  config.ROOT_API = 'https://dev-payments-api.jupico.com/api/v1';
  config.PLAID_PRODUCTS = 'auth';
  config.PLAID_ENV = 'sandbox';
  config.PLAID_PUBLIC_KEY = '46225d2c76ead505b25cdc1fad27d0';
  config.PLAID_COUNTRY_CODES = 'US';
  config.BACKOFFICE_URL = 'https://dev-accounts.jupico.com/payments/login';
  config.RECOVER_PASSWORD_URL = 'https://dev-accounts.jupico.com/forgot-password';
  config.CONTINUE_URL = spProductSlug =>
    `https://dev-payments-onboarding.jupico.com/${spProductSlug}/continue`;
  document.title = 'Jupico Onboarding';
  //DEV ENV 2
} else if (hostname === 'dev-payments-onboarding.payfac.io') {
  config.ENV = 'development';
  config.ROOT_API = 'https://dev-payments-api.payfac.io/api/v1';
  config.PLAID_PRODUCTS = 'auth';
  config.PLAID_ENV = 'sandbox';
  config.PLAID_PUBLIC_KEY = '46225d2c76ead505b25cdc1fad27d0';
  config.PLAID_COUNTRY_CODES = 'US';
  config.BACKOFFICE_URL = 'https://dev-accounts.payfac.io/payments/login';
  config.RECOVER_PASSWORD_URL = 'https://dev-accounts.payfac.io/forgot-password';
  config.CONTINUE_URL = spProductSlug =>
    `https://dev-payments-onboarding.payfac.io/${spProductSlug}/continue`;
  document.title = 'Payfac Onboarding';
} else if (hostname === 'sandbox-payments-onboarding.payfac.io') {
  // PAYFAC SANDBOX
  config.ENV = 'sandbox';
  config.ROOT_API = 'https://sandbox-payments-api.payfac.io/api/v1';
  config.PLAID_PRODUCTS = 'auth';
  config.PLAID_ENV = 'sandbox';
  config.PLAID_PUBLIC_KEY = '46225d2c76ead505b25cdc1fad27d0';
  config.PLAID_COUNTRY_CODES = 'US';
  config.BACKOFFICE_URL = 'https://sandbox-accounts.payfac.io/payments/login';
  config.RECOVER_PASSWORD_URL = 'https://sandbox-accounts.payfac.io/forgot-password';
  config.CONTINUE_URL = spProductSlug =>
    `https://sandbox-payments-onboarding.payfac.io/${spProductSlug}/continue`;
  document.title = 'Payfac Onboarding';
} else if (hostname === 'local-payments-onboarding.jupiterhq.com') {
  //LOCAL WITH DOMAIN
  config.ENV = 'local';
  config.ROOT_API = 'http://local-payments-api.jupiterhq.com/api/v1';
  config.PLAID_PRODUCTS = 'auth';
  config.PLAID_ENV = 'sandbox';
  config.PLAID_PUBLIC_KEY = '46225d2c76ead505b25cdc1fad27d0';
  config.PLAID_COUNTRY_CODES = 'US';
  config.BACKOFFICE_URL = 'http://local-accounts.jupiterhq.com/payments/login';
  config.RECOVER_PASSWORD_URL = 'http://local-accounts.jupiterhq.com/forgot-password';
  config.CONTINUE_URL = spProductSlug =>
    `http://local-payments-onboarding.jupiterhq.com/${spProductSlug}/continue`;
  document.title = 'Jupico Onboarding';
} else if (hostname === 'local-payments-onboarding.jupico.com') {
  //LOCAL WITH DOMAIN
  config.ENV = 'local';
  config.ROOT_API = 'http://local-payments-api.jupico.com/api/v1';
  config.PLAID_PRODUCTS = 'auth';
  config.PLAID_ENV = 'sandbox';
  config.PLAID_PUBLIC_KEY = '46225d2c76ead505b25cdc1fad27d0';
  config.PLAID_COUNTRY_CODES = 'US';
  config.BACKOFFICE_URL = 'http://local-accounts.jupico.com/payments/login';
  config.RECOVER_PASSWORD_URL = 'http://local-accounts.jupico.com/forgot-password';
  config.CONTINUE_URL = spProductSlug =>
    `http://local-payments-onboarding.jupico.com/${spProductSlug}/continue`;
  document.title = 'Jupico Onboarding';
} else if (hostname === 'local-payments-onboarding.payfac.io') {
  config.ENV = 'local';
  config.ROOT_API = 'http://local-payments-api.payfac.io/api/v1';
  config.PLAID_PRODUCTS = 'auth';
  config.PLAID_ENV = 'sandbox';
  config.PLAID_PUBLIC_KEY = '46225d2c76ead505b25cdc1fad27d0';
  config.PLAID_COUNTRY_CODES = 'US';
  config.BACKOFFICE_URL = 'http://local-accounts.payfac.io/payments/login';
  config.RECOVER_PASSWORD_URL = 'https://local-accounts.payfac.io/forgot-password';
  config.CONTINUE_URL = spProductSlug =>
    `http://local-payments-onboarding.payfac.io/${spProductSlug}/continue`;
  document.title = 'Payfac Onboarding';
} else if (host === 'localhost:8181') {
  config.ENV = 'local';
  config.ROOT_API = 'http://localhost:8140/api/v1';
  config.PLAID_PRODUCTS = 'auth';
  config.PLAID_ENV = 'sandbox';
  config.PLAID_PUBLIC_KEY = '46225d2c76ead505b25cdc1fad27d0';
  config.PLAID_COUNTRY_CODES = 'US';
  config.BACKOFFICE_URL = 'http://localhost:8080';
  config.RECOVER_PASSWORD_URL = 'http://localhost/forgot-password';
  config.CONTINUE_URL = spProductSlug =>
    `http://localhost:9081/${spProductSlug}/continue`;
  //LOCAL ENV WITH PORTS
} else {
  config.ENV = 'local';
  config.ROOT_API = 'http://localhost:8140/api/v1';
  config.PLAID_PRODUCTS = 'auth';
  config.PLAID_ENV = 'sandbox';
  config.PLAID_PUBLIC_KEY = '46225d2c76ead505b25cdc1fad27d0';
  config.PLAID_COUNTRY_CODES = 'US';
  config.BACKOFFICE_URL = 'http://localhost:8080';
  config.RECOVER_PASSWORD_URL = 'http://localhost/forgot-password';
  config.CONTINUE_URL = spProductSlug =>
    `http://localhost:9081/${spProductSlug}/continue`;
}

export default config;
