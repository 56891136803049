var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group",class:{
    'input-group-focus': _vm.focused,
    'has-label': _vm.label,
    'inline-label': _vm.inlineLabel,
    'soft-disable': _vm.readOnly
  },staticStyle:{"margin-bottom":"1rem"}},[_c('div',{staticClass:"d-flex justify-content-between"},[_vm._t("label",function(){return [(_vm.label)?_c('label',{staticClass:"input-label",style:(_vm.labelStyle),attrs:{"for":_vm.name}},[_c('span',[_vm._v(_vm._s(_vm.label))]),(_vm.required)?_c('i',{staticClass:"required-field float-left"}):_vm._e()]):_vm._e()]}),_vm._t("label-extension")],2),(_vm.progress != null)?_c('div',{style:({
      flex: 1,
      background: `linear-gradient(to right, var(--theme-primary-color) ${_vm.progress}%, lightgrey ${_vm.progress}%)`,
      height: '4px',
      margin: '0 0 10px 0'
    })}):_vm._e(),_c('div',{staticClass:"input-wrapper mb-0",class:{
      'input-group': _vm.hasIcon,
      'soft-disable': _vm.readOnly
    }},[_vm._t("addonLeft",function(){return [(_vm.addonLeftIcon)?_c('span',{staticClass:"input-group-prepend"},[_c('div',{staticClass:"input-group-text",class:{
            'error-brdr': _vm.error,
            'success-brdr': !_vm.error && _vm.touched
          }},[_c('i',{class:_vm.addonLeftIcon})])]):_vm._e()]}),_vm._t("default",function(){return [_c('vue-numeric',_vm._g({directives:[{name:"focus",rawName:"v-focus",value:(_vm.focus),expression:"focus"}],ref:"numericInput",staticClass:"form-control",class:{
          'error-brdr': _vm.error,
          'success-brdr': _vm.hasValidation && !_vm.error && _vm.touched && !_vm.disabled,
          'soft-disable': _vm.readOnly
        },style:({ width: _vm.inputWidth }),attrs:{"separator":",","type":"text","output-type":_vm.outputType,"name":_vm.name,"currency":_vm.currency,"currency-symbol-position":_vm.currencyPosition,"precision":_vm.precision,"minus":_vm.minus,"value":_vm.value,"placeholder":_vm.placeholder,"disabled":_vm.disabled || _vm.readOnly,"thousandSeparator":_vm.thousandSeparator,"min":_vm.min,"maxlength":_vm.maxlength},nativeOn:{"keydown":function($event){return _vm.handleKeydown.apply(null, arguments)}}},_vm.listeners))]}),(_vm.hasValidation && !_vm.error && _vm.touched && !_vm.disabled && !_vm.readOnly)?_c('span',{staticClass:"success-icon"},[_c('i',{staticClass:"fa fa-check-circle",attrs:{"aria-hidden":"true"}})]):(_vm.error && !_vm.readOnly)?_c('span',{staticClass:"error-icon"},[_c('i',{staticClass:"fa fa-exclamation-triangle",attrs:{"aria-hidden":"true"}})]):_vm._e()],2),((_vm.error || _vm.$slots.error) && _vm.showErrorMsg && !_vm.readOnly)?_vm._t("error",function(){return [_c('i',{staticClass:"error-msg"},[_vm._v(_vm._s(_vm.error))])]}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }