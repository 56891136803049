<template>
  <section class="main-body-wrapper">
    <div class="container p-0">
      <div class="form-wrapper congrats-content">
        <div class="container-fluid">
          <h1>Your application was successfully saved</h1>
          <p>
            You can come back at any time to complete it. Use the link below to continue
            your application. We also emailed you the link for future reference.
            <br /><br />
            <router-link :to="{ name: 'continue' }"> {{ continueUrl }}</router-link>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import config from '../config.js';

export default {
  computed: {
    continueUrl() {
      return config.CONTINUE_URL(this.$route.params.spProductSlug);
    }
  },
  mounted() {
    this.$axios({
      url: '/onboarding/email',
      method: 'post',
      data: { link: this.continueUrl }
    });
  }
};
</script>
