import { DateTime } from 'luxon';
import {
  DB_DATETIME_FORMAT_HH_MM_SS,
  PRETTY_DATE_FORMAT,
  DB_COMPLETE_DATE_FORMAT
} from './constants';

export const datetimeToString = datetime => DateTime.fromJSDate(datetime).toFormat(DB_DATETIME_FORMAT_HH_MM_SS);
/**
 * Formats a human-readable pretty date into a databas date format (yyyy-MM-dd).
 * @param {string} date - The date string to be formatted.
 * @returns {string} The formatted database-compatible date string.
 */
export const formatPrettyDateToDBDate = date => DateTime.fromFormat(date, PRETTY_DATE_FORMAT).toFormat(DB_COMPLETE_DATE_FORMAT);
/**
 * Formats a date (yyyy-MM-dd) into a human-readable pretty date.
 * @param {string} date - The date string to be formatted.
 * @returns {string} The formatted pretty date string.
 */
export const formatDBDateToPrettyDate = date => DateTime.fromFormat(date, DB_COMPLETE_DATE_FORMAT).toFormat(PRETTY_DATE_FORMAT);
