<template>
  <div class="fees-container">
    <div class="fee-item">
      <label for="discount-fee">Discount Fee</label>
      <span id="discount-fee">{{ discountRate }}</span>
    </div>
    <div class="fee-item">
      <label for="transaction-fee">Transaction Fee</label>
      <span id="transaction-fee">{{ ccCnpSaleFixed }}</span>
    </div>
    <div class="fee-item">
      <label for="monthly-fee">Monthly Fee</label>
      <span id="monthly-fee">{{ monthlyFee }}</span>
    </div>
  </div>
</template>

<script>
import { formatCurrency } from '../helpers/money';

export default {
  name: 'FeesData',
  props: { platformFees: Object },
  methods: {
    formatCurrency(value) {
      return formatCurrency(value);
    }
  },
  computed: {
    discountRate() {
      return this.platformFees.ccCnpSale
        ? `${this.platformFees.ccCnpSale.discountRate} %`
        : '$0.00';
    },
    ccCnpSaleFixed() {
      return this.platformFees.ccCnpSale
        ? this.formatCurrency(this.platformFees.ccCnpSale.fixed)
        : '$0.00';
    },
    monthlyFee() {
      // for the moment this need to be $0.00
      return '$0.00';
    }
  }
};
</script>

<style lang="scss" scoped>
.fees-container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 20px;
}

.fee-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  padding: 0 10px 0 10px;
  flex: 1;

  label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:not(:last-child) {
    border-right: 1px solid #ccc;
  }

  & > span {
    font-weight: bold;
  }
}
</style>
