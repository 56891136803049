import axios from 'axios';
import Vue from 'vue';
import Toasted from 'vue-toasted';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { getBrowseInfo } from './helpers/utils';

Vue.use(Toasted);
Vue.use(Vuex);

const FACILITATORS_ENDPOINT = 'foundation/facilitators';
const INVITES_ENDPOINT = 'onboarding/invites';
const SSO_ENDPOINT = 'auth/sso/onboarding';

const unauthorizedMessage = 'Unauthorized, redirecting to the login.';
const defaultMessage = 'Server side error, please contact the support team.';

export default new Vuex.Store({
  state: {
    platformName: '',
    initiateFrom: '',
    token: '',
    smsCode: '',
    flowCompleted: { status: false },
    flowStatus: null, // approved or not-approved
    identity: {},
    smApplicationId: null,
    payoutAccount: {},
    fees: {},
    facilitator: undefined,
    invite: null,
    saving: false,
    termsOpen: false,
    privacyOpen: false,
    overrideBackButton: false,
    backButtonPressed: false,
    hasSignificantChanges: false,
    lastSignificantChangeStep: 0,
    steps: [
      {
        name: 'Create Account',
        isVisited: true,
        isCompleted: false,
        icon: 'fa fa-user-circle',
        routeNames: ['GetStarted', 'MobileCodeVerification']
      },
      {
        name: 'Business Information',
        isVisited: false,
        isCompleted: false,
        icon: 'fa fa-store-alt',
        routeNames: ['MerchantType', 'CompanyForm']
      },
      {
        name: 'Verify Ownership',
        isVisited: false,
        isCompleted: false,
        icon: 'fa fa-user-plus',
        routeNames: ['VerifyOwnership']
      },
      {
        name: 'Add Bank Account',
        isVisited: false,
        isCompleted: false,
        icon: 'fa fa-university',
        routeNames: ['BankForm']
      },
      {
        name: 'Review & Submit',
        isVisited: false,
        isCompleted: false,
        icon: 'fa fa-paper-plane',
        routeNames: ['ConfirmProfile']
      }
    ]
  },
  mutations: {
    SSO(state, token) {
      state.token = token;
    },
    SET_IDENTITY(state, data) {
      state.identity = data;
    },
    SET_SM_APPLICATION_ID(state, data) {
      state.smApplicationId = data;
    },
    SET_PAYOUT(state, data) {
      state.payoutAccount = data;
    },
    SET_ITEM(state, { item, value }) {
      Vue.set(state, item, value);
    },
    SET_FLOW_STATUS(state, { flowCompleted, flowStatus }) {
      state.flowCompleted.status = flowCompleted;
      state.flowStatus = flowStatus;
    },
    LOG_ERROR(state, params) {
      const { errorMessage, errorStatus } = params;
      const message =
        errorStatus === 401 ? unauthorizedMessage : errorMessage || defaultMessage;
      Vue.toasted.show(message, {
        type: 'error',
        duration: 6000,
        position: 'top-right'
      });
    },
    SET_INITIATE_FROM(state, data) {
      state.initiateFrom = data;
    },
    SET_FEES(state, data) {
      state.fees = data;
    },
    SET_FACILITATOR(state, data) {
      state.facilitator = data;
    },
    SET_INVITE(state, data) {
      state.invite = data;
    },
    SET_SAVE_AND_EXIT(state, value) {
      state.saving = value;
    },
    SET_TERMS_OPEN(state, value) {
      state.termsOpen = value;
    },
    SET_PRIVACY_OPEN(state, value) {
      state.privacyOpen = value;
    },
    SET_OVERRIDE_BACK_BUTTON(state, value) {
      state.overrideBackButton = value;
      state.backButtonPressed = false;
    },
    SET_BACK_BUTTON_PRESSED(state, value) {
      state.backButtonPressed = value;
      if (value) {
        state.overrideBackButton = false;
      }
    },
    SET_SIGNIFICANT_CHANGES(state, { hasChanges, step }) {
      state.hasSignificantChanges = hasChanges;
      state.lastSignificantChangeStep = step;
    },
    RESET_SIGNIFICANT_CHANGES(state) {
      state.hasSignificantChanges = false;
      state.lastSignificantChangeStep = null;
    },
    MARK_STEP_COMPLETED(state, stepIndex) {
      if (!state.steps[stepIndex]) {
        return;
      }

      // Un-complete steps after the current one
      for (let i = stepIndex + 1; i < state.steps.length; i++) {
        Vue.set(state.steps[i], 'isCompleted', false);
      }

      Vue.set(state.steps[stepIndex], 'isCompleted', true);
      Vue.set(state.steps[stepIndex], 'isVisited', true);
    },
    MARK_STEP_VISITED(state, stepIndex) {
      if (state.steps[stepIndex]) {
        Vue.set(state.steps[stepIndex], 'isVisited', true);
      }
    },
    RESET_VISITED_STEPS(state, fromStep) {
      state.steps = state.steps.map((step, index) => {
        if (index > fromStep) {
          return { ...step, isVisited: false, isCompleted: false };
        }
        return step;
      });
    },
    SET_STEPS(state, updatedSteps) {
      state.steps = updatedSteps;
    }
  },
  actions: {
    async SSO({ commit, dispatch, rootGetters }) {
      try {
        const browserFingerprint = await dispatch('GET_BROWSER_INFO');
        const response = await axios.post(SSO_ENDPOINT, {
          browserFingerprint: browserFingerprint.fingerprint,
          facId: rootGetters.getFacilitator.facId,
          application: 'payments'
        });
        axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
        commit('SSO', response.data.token);
        return { success: true };
      } catch (e) {
        return { success: false };
      }
    },
    async GET_BROWSER_INFO() {
      return await getBrowseInfo();
    },
    async GET_IDENTITY({ commit }) {
      try {
        const response = await axios.get('/onboarding/accounts/identity');
        const { identity } = response.data;
        commit('SET_IDENTITY', identity);
        commit('SET_SM_APPLICATION_ID', response.data._id);
      } catch (error) {
        console.error(error);
        throw new Error('GET_IDENTITY_ERROR');
      }
    },
    async GET_PAYOUT_ACCOUNT({ commit, state }, addAsterisks) {
      try {
        const response = await axios.get(
          `/onboarding/accounts/${state.smApplicationId}/payout`,
          { params: { addAsterisks } }
        );
        commit('SET_PAYOUT', response.data);
      } catch (error) {
        console.error({ error });
        throw new Error('SET_PAYOUT_ERROR');
      }
    },
    SET_INITIATE_FROM({ commit }, initiateFrom) {
      commit('SET_INITIATE_FROM', initiateFrom);
    },
    async GET_FEES({ commit }) {
      try {
        const response = await axios.get('/onboarding/accounts/fees');
        commit('SET_FEES', response.data);
      } catch (error) {
        throw new Error('SET_FEES_ERROR');
      }
    },
    async GET_FACILITATOR({ commit }) {
      try {
        const response = await axios.get(FACILITATORS_ENDPOINT);
        if (response.data && response.data.success) {
          const facilitator = response.data.data;

          document.querySelector('link[rel="icon"]').href = facilitator.branding.favIcon;
          document.documentElement.style.setProperty(
            '--theme-primary-color',
            facilitator.branding.color
          );
          commit('SET_FACILITATOR', facilitator);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async GET_INVITE({ commit }, { inviteId, facId, spProductSlug }) {
      try {
        const response = await axios.get(INVITES_ENDPOINT, {
          params: { inviteId, facId, spProductSlug }
        });
        if (response.data && response.data.success) {
          const invite = response.data.data;
          commit('SET_INVITE', invite);
        }
      } catch (error) {
        console.error(error);
      }
    },
    ACTIVATE_SAVE_AND_EXIT({ commit }) {
      commit('SET_SAVE_AND_EXIT', true);
      setTimeout(() => commit('SET_SAVE_AND_EXIT', false), 1000);
    },
    MARK_STEP_COMPLETED({ commit }, stepIndex) {
      commit('MARK_STEP_COMPLETED', stepIndex);
    },
    TOGGLE_TERMS({ commit }, value) {
      commit('SET_TERMS_OPEN', value);
    },
    TOGGLE_PRIVACY({ commit }, value) {
      commit('SET_PRIVACY_OPEN', value);
    },
    async SET_OVERRIDE_BACK_BUTTON({ commit }, value) {
      commit('SET_OVERRIDE_BACK_BUTTON', value);
      commit('SET_BACK_BUTTON_PRESSED', false);
    },
    SET_BACK_BUTTON_PRESSED({ commit }, value) {
      commit('SET_BACK_BUTTON_PRESSED', value);
      if (value) {
        commit('SET_OVERRIDE_BACK_BUTTON', false);
      }
    },
    SET_SIGNIFICANT_CHANGES({ commit }, { hasChanges, step }) {
      commit('SET_SIGNIFICANT_CHANGES', { hasChanges, step });
    },
    RESET_SIGNIFICANT_CHANGES({ commit }) {
      commit('RESET_SIGNIFICANT_CHANGES');
    },
    MARK_STEPS_VISITED({ commit }, upToIndex) {
      commit('MARK_STEPS_VISITED', upToIndex);
    },
    RESET_VISITED_STEPS({ commit }, fromStep) {
      commit('RESET_VISITED_STEPS', fromStep);
    }
  },
  getters: {
    getIdentity: state => state.identity,
    getSmApplicationId: state => state.smApplicationId,
    getPayout: state => state.payoutAccount,
    getInitiateStep: state => state.initiateFrom,
    getFees: state => state.fees,
    getFacilitator: state => state.facilitator,
    getInvite: state => state.invite,
    getSaveAndExit: state => state.saving,
    isTermsOpen: state => state.termsOpen,
    isPrivacyOpen: state => state.privacyOpen,
    shouldOverrideBackButton: state => state.overrideBackButton,
    isBackButtonPressed: state => state.backButtonPressed,
    getHasSignificantChanges: state => state.hasSignificantChanges,
    getLastSignificantChangeStep: state => state.lastSignificantChangeStep,
    getSteps: state => state.steps,
    getLastVisitedStepIndex: state =>
      state.steps.reduce(
        (lastIndex, step, index) => (step.isVisited ? index : lastIndex),
        -1
      ),
    isAuthenticated: state => !!state.token,
    getToken: state => state.token
  },
  plugins: [
    createPersistedState({
      paths: ['flowCompleted', 'flowStatus', 'initiateFrom', 'steps']
    })
  ]
});
