<template>
  <div
    class="modal fade custom-modal"
    id="privacy-policy"
    tabindex="-1"
    role="dialog"
    aria-labelledby="privacy-policy"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myModalLabel">Privacy Policy</h4>
          <button
            type="button"
            class="close close-custom"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="privacy-content text-justify">
            <div class="container">
              <h5 class="terms-section-title">
                Privacy Notice for Users Who Apply or Sign Up for a
                {{ getFacilitator.name }} Account or Other Services
              </h5>
              Last updated: June 6, 2023<br /><br />
              <p>
                This Privacy Notice describes how Jupi Co DBA
                {{ getFacilitator.name }} and our affiliates (collectively, “{{
                  getFacilitator.name
                }},” “we,” and “us”) collect, use, disclose, transfer, store, retain or
                otherwise process your information when you (whether you are a person or
                business) apply or sign up for a {{ getFacilitator.name }} account or
                other services through {{ getFacilitator.name }}'s website or applications
                (collectively, “Services”). <br /><br />
                This Privacy Notice applies to your use of our Services, and covers
                information collected in connection with your access to and use of our
                Services. Please read this Privacy Notice carefully. By continuing to
                interact with our Services, you are consenting to the practices described
                in this Privacy Notice.
              </p>
              <h5 class="terms-section-title">INFORMATION WE COLLECT ABOUT YOU</h5>
              <p>
                We need to collect information about you to provide you with the Services
                or the support you request. The type of information we collect can vary
                depending on the country from which you access our Services. Additionally,
                you can choose to voluntarily provide information to us.
              </p>
              <h6 class="terms-section-item">Information You Provide</h6>
              <p>
                We collect the information you provide when you apply or sign up for a
                {{ getFacilitator.name }} account or other Services, go through our
                identity or account verification process, authenticate into your account,
                communicate with us, answer our surveys, upload content, or otherwise use
                the Services. <br /><br />
                We collect information about you when you use our Services, including:
              </p>
              <ul class="mb-4">
                <li class="terms-li">
                  Identification Information. Your name; email address; mailing address;
                  phone number; photograph; birthdate; passport, driver’s license, Social
                  Security, Taxpayer Identification, or other government-issued
                  identification; or other historical, contact, and demographic
                  information when you apply or sign up for a
                  {{ getFacilitator.name }} account or other Services, signature, and
                  authentication credentials (for example, information you use to login to
                  your account), including IP address.
                </li>
                <li class="terms-li">
                  Financial Information. Information such as bank account,bank account
                  information including current balances and transactions for the previous
                  24 months, payment card numbers, credit reports, and other publicly
                  available information.
                </li>
                <li class="terms-li">
                  Tax information. Withholding allowances and tax filing status.
                </li>
                <li class="terms-li">
                  Transaction Information. When you use our Services to make, accept,
                  request, or record payments, we collect information about when and where
                  the transaction occur, the names of the transacting parties, a
                  description of the transaction, the payment or transfer amounts, billing
                  and shipping information, and the devices and payment methods used to
                  complete the transaction.
                </li>
                <li class="terms-li">
                  Other Information You Provide. Information that you voluntarily provide
                  to us, including your survey responses; participation in contests,
                  promotions, or other prospective seller marketing forms or devices;
                  suggestions for improvements; referrals; or any other actions performed
                  on the Services.
                </li>
              </ul>
              <h6 class="terms-section-item">
                Information We Collect From Your Use of our Services
              </h6>
              <p>
                We collect information about you and the devices you use to access the
                Services, such as your computer, mobile phone, or tablet. The information
                that we collect includes:
              </p>
              <ul class="mb-4">
                <li class="terms-li">
                  Precise Geolocation Information. The location of your device.
                </li>
                <li class="terms-li">
                  Device Information. Information about your device, including your
                  hardware model, operating system and version, device name, unique device
                  identifier, mobile network information, and information about the
                  device's interaction with our Services.
                </li>
                <li class="terms-li">
                  Use Information. Information about how you use our Services, including
                  your access time, “log-in” and “log-out” information, browser type and
                  language, country and language setting on your device, Internet Protocol
                  (“IP”) address, the domain name of your Internet service provider, other
                  attributes about your browser, mobile device and operating system, any
                  specific page you visit on our platform, content you view, features you
                  use, the date and time of your visit to or use of the Services, your
                  search terms, the website you visited before you visited or used the
                  Services, data about how you interact with our Services, and other
                  clickstream data.
                </li>
                <li class="terms-li">
                  Business Information. Information about products and services you sell
                  (including inventory, pricing and other data) and other information you
                  provide about you or your business (including appointment, staffing
                  availability, employee, payroll and contact data).
                </li>
                <li class="terms-li">
                  Customer Information. Information you collect from your customers,
                  including email address, phone number, payment information, or other
                  information.
                </li>
              </ul>
              <h6 class="terms-section-item">
                Information We Collect From Other Sources
              </h6>
              <p>We also collect information about you from third parties, including:</p>
              <ul class="mb-4">
                <li class="terms-li">
                  Identity Verification. Information from third-party verification
                  services, credit bureaus, financial institutions, mailing list
                  providers, and publicly available sources. In some circumstances, where
                  lawful, this information may include your government-issued
                  identification number.
                </li>
                <li class="terms-li">
                  Background Information. To the extent permitted by applicable laws, we
                  may obtain background check reports from public records of criminal
                  convictions and arrest records. We may use your information, including
                  your full name, government-issued identification number, and date of
                  birth, to obtain such reports.
                </li>
                <li class="terms-li">
                  Credit, Compliance and Fraud. Information about you from third parties
                  in connection with any credit investigation, credit eligibility,
                  identity or account verification process, fraud detection process, or
                  collection procedure, or as may otherwise be required by applicable law.
                  This includes, without limitation, the receipt and exchange of account
                  or credit-related information with any credit reporting agency or credit
                  bureau, where lawful, and any person or corporation with whom you have
                  had, currently have, or may have a financial relationship, including
                  without limitation past, present, and future places of employment,
                  financial institutions, and personal reporting agencies.
                </li>
              </ul>
              <h6 class="terms-section-item">Children’s Information</h6>
              <p>
                Our Services are general audience services not directed at children under
                the age of 13. If we obtain actual knowledge that any information we
                collect has been provided by a child under the age of 13, we will promptly
                delete that information.
              </p>
              <h5 class="terms-section-title">HOW WE USE YOUR INFORMATION</h5>
              <p>We may use information about you for a number of purposes, including:</p>
              <h6 class="terms-section-item">
                Providing, Improving, and Developing our Services
              </h6>
              <ul class="mb-4">
                <li class="terms-li">
                  Determining whether the Services are available in your country;
                </li>
                <li class="terms-li">
                  Processing or recording payment transactions or money transfers;
                </li>
                <li class="terms-li">
                  Otherwise providing you with the {{ getFacilitator.name }} products and
                  features you choose to use;
                </li>
                <li class="terms-li">
                  Displaying your historical transaction or appointment information;
                </li>
                <li class="terms-li">
                  Providing, maintaining and improving our Services;
                </li>
                <li class="terms-li">Developing new products and services;</li>
                <li class="terms-li">
                  Delivering the information and support you request, including technical
                  notices, security alerts, and support and administrative messages
                  including to resolve disputes, collect fees, and provide assistance for
                  problems with our Services or your {{ getFacilitator.name }} account;
                </li>
                <li class="terms-li">
                  Improving, personalizing, and facilitating your use of our Services;
                </li>
                <li class="terms-li">
                  Measuring, tracking, and analyzing trends and usage in connection with
                  your use or the performance of our Services.
                </li>
              </ul>
              <h6 class="terms-section-item">
                Communicating with You About our Services
              </h6>
              <ul class="mb-4">
                <li class="terms-li">
                  Sending you information we think you may find useful or which you have
                  requested from us about our products and services;
                </li>
                <li class="terms-li">
                  Conducting surveys and collecting feedback about our Services.
                </li>
              </ul>
              <h6 class="terms-section-item">
                Protecting our Services and Maintaining a Trusted Environment
              </h6>
              <ul class="mb-4">
                <li class="terms-li">
                  Investigating, detecting, preventing, or reporting fraud,
                  misrepresentations, security breaches or incidents, other potentially
                  prohibited or illegal activities, or to otherwise help protect your
                  account, including to dispute chargebacks on your behalf;
                </li>
                <li class="terms-li">
                  Protecting our, our customers’, or your customers’ rights or property,
                  or the security or integrity of our Services;
                </li>
                <li class="terms-li">
                  Enforcing our Terms of Service or other applicable agreements or
                  policies;
                </li>
                <li class="terms-li">
                  Verifying your identity (e.g., through government-issued identification
                  numbers);
                </li>
                <li class="terms-li">
                  Complying with any applicable laws or regulations, or in response to
                  lawful requests for information from the government or through legal
                  process;
                </li>
                <li class="terms-li">
                  Fulfilling any other purpose disclosed to you in connection with our
                  Services;
                </li>
                <li class="terms-li">
                  Contacting you to resolve disputes, collect fees, and provide assistance
                  with our Services.
                </li>
              </ul>
              <ul class="mb-4">
                <li class="terms-li">Marketing of our Services;</li>
                <li class="terms-li">
                  Communicating with you about opportunities, products, services,
                  contests, promotions, discounts, incentives, surveys, and rewards
                  offered by us and select partners;
                </li>
                <li class="terms-li">
                  If we send you marketing emails, each email will contain instructions
                  permitting you to “opt out” of receiving future marketing or other
                  communications.
                </li>
              </ul>
              <p>
                To learn about your choices regarding interest-based advertising and
                cross-device tracking, please see below.
              </p>
              <h6 class="terms-section-item">Other Uses</h6>
              <ul class="mb-4">
                <li class="terms-li">
                  For any other purpose disclosed to you in connection with our Services
                  from time to time.
                </li>
              </ul>
              <h5 class="terms-section-title">HOW WE SHARE YOUR INFORMATION</h5>
              <p>We may share information about you as follows:</p>
              <h6 class="terms-section-item">
                With Other Users of our Services with Whom You Interact
              </h6>
              <ul class="mb-4">
                <li class="terms-li">
                  With other users of our Services with whom you interact through your own
                  use of our Services. For example, we may share information when you make
                  or accept a payment, appointment, or money transfer using our Services.
                </li>
              </ul>
              <h6 class="terms-section-item">With our Affiliates</h6>
              <ul class="mb-4">
                <li class="terms-li">
                  With our group companies and corporate affiliates, for the purposes
                  outlined above.
                </li>
              </ul>
              <h6 class="terms-section-item">With Third Parties</h6>
              <ul class="mb-4">
                <li class="terms-li">
                  With third parties to provide, maintain, and improve our Services,
                  including service providers who access information about you to perform
                  services on our behalf (e.g., fraud prevention, identity verification,
                  and fee collection services), as well as financial institutions, payment
                  networks, payment card associations, credit bureaus, partners providing
                  services on {{ getFacilitator.name }}’s behalf, and other entities in
                  connection with the Services;
                </li>
                <li class="terms-li">
                  With third parties that run advertising campaigns, contests, special
                  offers, or other events or activities on our behalf or in connection
                  with our Services.
                </li>
              </ul>
              <h6 class="terms-section-item">Business Transfers and Corporate Changes</h6>
              <ul class="mb-4">
                <li class="terms-li">
                  To a subsequent owner, co-owner, or operator of one or more of the
                  Services; or
                </li>
                <li class="terms-li">
                  In connection with (including, without limitation, during the
                  negotiation or due diligence process of) a corporate merger,
                  consolidation, or restructuring; the sale of substantially all of our
                  stock and/or assets; financing, acquisition, divestiture, or dissolution
                  of all or a portion of our business; or other corporate change.
                </li>
              </ul>
              <h6 class="terms-section-item">Safety and Compliance with Law</h6>
              <ul class="mb-4">
                <li class="terms-li">
                  If we believe that disclosure is reasonably necessary (i) to comply with
                  any applicable law, regulation, legal process or governmental request
                  (e.g., from tax authorities, law enforcement agencies, etc.); (ii) to
                  enforce or comply with our General Terms or other applicable agreements
                  or policies; (iii) to protect our or our customers’ rights or property,
                  or the security or integrity of our Services; or (iv) to protect us,
                  users of our Services or the public from harm, fraud, or potentially
                  prohibited or illegal activities.
                </li>
              </ul>
              <h6 class="terms-section-item">With Your Consent</h6>
              <p>With your consent. For example:</p>
              <ul class="mb-4">
                <li class="terms-li">
                  At your direction or as described at the time you agree to share;
                </li>
                <li class="terms-li">
                  When you authorize a third party application or website to access your
                  information.
                </li>
              </ul>
              <h6 class="terms-section-item">Aggregated and Anonymized Information</h6>
              <ul class="mb-4">
                <li class="terms-li">
                  We also may share (within our group of companies or with third parties)
                  aggregated and anonymized information that does not specifically
                  identify you or any individual user of our Services.
                </li>
              </ul>
              <h5 class="terms-section-title">HOW LONG WE RETAIN YOUR INFORMATION</h5>
              <p>
                We generally retain your information as long as reasonably necessary to
                provide you the Services or to comply with applicable law. However, even
                after you deactivate your account, we can retain copies of information
                about you and any transactions or Services in which you may have
                participated for a period of time that is consistent with applicable law,
                applicable statute of limitations or as we believe is reasonably necessary
                to comply with applicable law, regulation, legal process, or governmental
                request, to detect or prevent fraud, to collect fees owed, to resolve
                disputes, to address problems with our Services, to assist with
                investigations, to enforce our General Terms or other applicable
                agreements or policies, or to take any other actions consistent with
                applicable law.
              </p>
              <h5 class="terms-section-title">COOKIES AND OTHER SIMILAR TECHNOLOGIES</h5>
              <p>
                We use various technologies to collect information when you access or use
                our Services, including placing a piece of code, commonly referred to as a
                “cookie,” or similar technology on your device and using web beacons.
                Cookies are small data files that are stored on your hard drive or in your
                device memory when you visit a website or view a message. Among other
                things, cookies support the integrity of our registration process, retain
                your preferences and account settings, and help evaluate and compile
                aggregated statistics about user activity. We will begin collecting
                information about you or from activity on devices you use as soon as you
                use our Services. By using our Services, you permit us to collect and use
                your information from activity on devices you use in accordance with this
                Privacy Notice. For more information and to learn how to block or delete
                cookies used in the Services, please see below. <br /><br />
                Certain cookies we use last only for the duration of your web or
                application session and expire when you close your browser or exit the
                application. Other cookies are used to remember you when you return to use
                the Services and, as such, will last longer. <br /><br />
                We may use cookies to:
              </p>
              <ul class="mb-4">
                <li class="terms-li">
                  Remember that you have visited us or used the Services before. This
                  allows us to identify the number of unique visitors we receive, so that
                  we can provide enough capacity to accommodate all of our users.
                </li>
                <li class="terms-li">
                  Customize elements of the promotional layout and/or content of our
                  Services.
                </li>
                <li class="terms-li">
                  Collect data about the way you interact with our Services (e.g., when
                  you use certain features).
                </li>
                <li class="terms-li">
                  Collect data to assess and improve our advertising campaigns, including
                  sending information to our business partners.
                </li>
                <li class="terms-li">
                  Allow our business partners (including third parties) to use these
                  tracking technologies to track your behavior on our behalf on our
                  Platform (including when you use multiple devices) and on partner
                  websites.
                </li>
                <li class="terms-li">
                  Enable third parties to collect data about the way you interact across
                  sites outside of our Services.
                </li>
                <li class="terms-li">
                  Collect anonymous statistical information about how you use the Services
                  (including the length of your web or application session) and the
                  location from which you access the Services, so that we can improve the
                  Services and learn which elements and functions of the Services are most
                  popular with our users.
                </li>
              </ul>
              <p>
                Some of the cookies used in the Services are set by us, and others are set
                by third parties who deliver services on our behalf. <br /><br />
                Most web and mobile device browsers are set to automatically accept
                cookies by default. However, you can change your browser settings to
                prevent automatic acceptance of cookies, or to notify you each time a
                cookie is set. <br /><br />
                We also may collect information using web beacons. Web beacons are
                electronic images that may be used in our Services or emails. We use web
                beacons to deliver cookies, track the number of visits to our website and
                apps, understand usage and campaign effectiveness, and determine whether
                an email has been opened and acted upon.
              </p>
              <h5 class="terms-section-title">THIRD-PARTY ADVERTISING AND ANALYTICS</h5>
              <p>
                We can use third-party service providers to provide site metrics and other
                analytics services. These third parties can use cookies, web beacons, and
                other technologies to collect information, such as your IP address,
                identifiers associated with your device, other applications on your
                device, the browsers you use to access our Services, web pages viewed,
                time spent on webpages, links clicked, and conversion information (e.g.,
                transactions entered into). This information can be used by
                {{ getFacilitator.name }} and third-party service providers on behalf of
                {{ getFacilitator.name }} to analyze and track usage of our Services,
                determine the popularity of certain content, and better understand how you
                use our Services. The third-party service providers that we engage are
                bound by confidentiality obligations and other restrictions with respect
                to their use and collection of your information. <br /><br />
                This Privacy Notice does not apply to, and we are not responsible for,
                third-party cookies, web beacons, or other tracking technologies, which
                are covered by such third parties’ privacy policies. For more information,
                we encourage you to check the privacy policies of these third parties to
                learn about their privacy practices.Examples of our third-party service
                providers to help deliver our Services or to connect to our Services
                include:
              </p>
              <ul class="mb-4">
                <li class="terms-li">
                  Google Analytics: We use Google Analytics to understand how our Services
                  perform and how you use them. To learn more about how Google processes
                  your data, please visit
                  <a href="https://www.google.com/policies/privacy/" target="_blank"
                    >https://www.google.com/policies/privacy/</a
                  >. To opt out of Google Analytics please visit
                  <a href="https://tools.google.com/dlpage/gaoptout/" target="_blank"
                    >https://tools.google.com/dlpage/gaoptout</a
                  >.
                </li>
                <li class="terms-li">
                  Facebook: We use Facebook to advertise and market our services. We also
                  use Facebook Connect API to allow you to connect your Facebook account
                  to your {{ getFacilitator.name }} account. To learn more about how
                  Facebook uses your data please visit
                  <a href="https://www.facebook.com/help/325807937506242/" target="_blank"
                    >https://www.facebook.com/help/325807937506242/</a
                  >
                  or log on to your Facebook account and access your settings. To
                  understand more about Facebook advertising please see here
                  <a href="https://www.facebook.com/about/ads" target="_blank"
                    >https://www.facebook.com/about/ads</a
                  >.
                </li>
              </ul>
              <p>
                These third party service providers make use of cookies to implement their
                services.
              </p>
              <h5 class="terms-section-title">YOUR CHOICES</h5>
              <h6 class="terms-section-item">Personal Information</h6>
              <p>
                You may access, change, or correct information that you have provided by
                logging into your {{ getFacilitator.name }} account at any time or by
                making a request to us in which case we may need to verify your identity
                before granting access or otherwise changing or correcting your
                information.
              </p>
              <h6 class="terms-section-item">Deactivating Your Account</h6>
              <p>
                If you wish to deactivate your account, you can do so by logging into your
                {{ getFacilitator.name }} account or by emailing us using the contact
                details provided below.
              </p>
              <h6 class="terms-section-item">Location Information</h6>
              <p>
                In order to provide certain Services, we may require access to location
                information, including precise geolocation information collected from your
                device. If you do not consent to collection of this information, certain
                Services will not function properly, and you will not be able to use those
                Services. You can stop our collection of location information at any time
                by changing the preferences on your mobile device. If you do so, some of
                our mobile applications will no longer function. You also may stop our
                collection of location information via mobile application by following the
                standard uninstall process to remove all {{ getFacilitator.name }} mobile
                applications from your device.
              </p>
              <h6 class="terms-section-item">Do Not Track</h6>
              <p>
                Do Not Track (“DNT”) is an optional browser setting that allows you to
                express your preferences regarding tracking across websites.
                {{ getFacilitator.name }} does not have a mechanism in place to respond to
                DNT signals. {{ getFacilitator.name }} does track some activity across
                websites (including your search terms, the website you visited before you
                visited or used the Services, and other clickstream data) and we may
                continue to collect information in the manner described in this Privacy
                Notice from web browsers that have enabled DNT signals or similar
                mechanisms.
              </p>
              <h6 class="terms-section-item">Promotional Communications</h6>
              <p>
                You can opt out of receiving promotional messages from
                {{ getFacilitator.name }} by following the instructions in those messages,
                by informing the caller that you would not like to receive future
                promotional calls, or by changing your notification settings by logging
                into your {{ getFacilitator.name }} account. Opting out of receiving
                communications may impact your use of the Services. If you decide to opt
                out, we can still send you non-promotional communications, such as digital
                receipts and messages about your account or our ongoing business
                relations.
              </p>
              <h5 class="terms-section-title">SECURITY</h5>
              <p>
                We take reasonable measures, including administrative, technical, and
                physical safeguards, to protect your information from loss, theft, misuse,
                and unauthorized access, disclosure, alteration, and destruction.
                Nevertheless, the internet is not a 100% secure environment, and we cannot
                guarantee absolute security of the transmission or storage of your
                information. We hold information about you both at our own premises and
                with the assistance of third-party service providers.
              </p>
              <h5 class="terms-section-title">STORAGE AND PROCESSING</h5>
              <p>
                We may, and we may use third-party service providers to, process and store
                your information in the United States, Canada, Japan, the European Union
                and other countries.
              </p>
              <h5 class="terms-section-title">CHANGES TO THIS PRIVACY NOTICE</h5>
              <p>
                We may amend this Privacy Notice from time to time by posting a revised
                version and updating the “Effective Date” above. The revised version will
                be effective on the “Effective Date” listed. We will provide you with
                reasonable prior notice of material changes in how we use your
                information, including by email, if you have provided an email address. If
                you disagree with these changes, you may cancel your account at any time.
                Your continued use of our Services constitutes your consent to any
                amendment of this Privacy Notice.
              </p>
              <h5 class="terms-section-title">CONTACT</h5>
              <p>
                Please contact our Privacy Department with any questions or concerns
                regarding this Privacy Policy. <br /><br />
                Jupi CO<br /><br />
                Attn: Privacy Department<br /><br />
                5830 Granite Pkwy #100-238<br /><br />
                Plano, TX 75024, U.S.A.<br /><br />
                support@Jupico.com<br /><br />
                If you have any questions or concerns regarding our notice, or if you
                believe our notice or applicable laws relating to the protection of your
                personal information have not been respected, you may file a complaint
                with our Privacy Department listed above, and we will respond to let you
                know who will be handling your matter and when you can expect a further
                response. We may request additional details from you regarding your
                concerns and may need to engage or consult with other parties in order to
                investigate and address your issue. We may keep records of your request
                and any resolution.
              </p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            @click="togglePrivacy"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'PrivacyPolicy',
  computed: {
    ...mapGetters(['getFacilitator'])
  },
  methods: {
    togglePrivacy() {
      this.$store.dispatch('TOGGLE_PRIVACY', false);
    }
  }
};
</script>
