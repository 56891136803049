<template>
  <section v-if="isIE" class="main-body-wrapper">
    <div class="container p-0">
      <div class="form-wrapper congrats-content">
        <div class="container-fluid">
          <h1>Unsupported Browser</h1>
          <p>
            {{ getFacilitator.name }} is committed to providing modern, secure
            technologies to our customers and as such we no longer support the Internet
            Explorer 11 browser per Microsoft’s recommendation. Please access this
            application from any of the following browsers:
            <a href="https://www.microsoft.com/edge" target="_blank">Microsoft Edge</a>,
            <a href="https://www.google.com/chrome" target="_blank">Chrome</a>,
            <a href="https://www.mozilla.com" target="_blank">Firefox</a>, or
            <a href="https://www.apple.com/safari" target="_blank">Safari</a>
          </p>
        </div>
      </div>
    </div>
  </section>
  <div v-else id="app-wrapper">
    <router-view />
  </div>
</template>

<script>
import axios from 'axios';
import Bowser from 'bowser';
import swal from 'sweetalert2';
import { mapGetters } from 'vuex';

export default {
  name: 'App',
  data() {
    return {
      show: false,
      currentVersion: undefined,
      isSwalOpen: false
    };
  },
  computed: {
    ...mapGetters(['getFacilitator']),
    isIE() {
      try {
        return (
          Bowser.getParser(window.navigator.userAgent).getBrowserName() ===
          'Internet Explorer'
        );
      } catch (error) {
        return false;
      }
    }
  },
  created() {
    axios.interceptors.response.use(response => {
      if (!this.currentVersion) {
        this.currentVersion = response.headers['x-app-version'];
      }
      if (
        response.headers['x-app-version'] &&
        response.headers['x-app-version'] !== this.currentVersion &&
        this.isSwalOpen === false
      ) {
        this.isSwalOpen = true;
        swal.fire({
          title: 'New Version Available',
          text: 'A new version of the application is available. Please log back in to get the latest version.',
          icon: 'info',
          confirmButtonText: 'OK',
          showCancelButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          reverseButtons: true,
          focusConfirm: true,
          focusCancel: false,
          showCloseButton: false,
          showLoaderOnConfirm: true,
          preConfirm: () => {
            //option 1
            localStorage.clear();
            sessionStorage.clear();
            window.location.replace(window.location.href);

            this.isSwalOpen = false;
          }
        });
      }
      return response;
    });
    localStorage.setItem(
      'branding',
      JSON.stringify({
        backgroundColor: '#f9f9fc',
        primaryColor: '#4d67ff',
        secondaryColor: '#a6b8fb',
        contrastColor: '#ffffff',
        textColor: '#000000',
        navbarBackgroundColor: '#F1F2F5;',
        logoUrl: ''
      })
    );
  },
  mounted() {
    if (this.getFacilitator.branding.darkForm) {
      document.getElementById('app-wrapper').classList.add('removed-box-shadows');
    }

    window.addEventListener('unload', this.handleUnload);
  },
  beforeDestroy() {
    window.removeEventListener('unload', this.handleUnload);
  },
  methods: {
    handleUnload() {
      localStorage.clear();
      sessionStorage.clear();
    }
  }
};
</script>

<style lang="scss">
#app-wrapper {
  min-height: 100vh;
  max-height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .d-grid {
    display: grid;
  }
  @for $i from 1 through 10 {
    .grid-columns-#{$i} {
      grid-template-columns: repeat(#{$i}, 1fr);
    }
    .gap-#{$i} {
      gap: #{$i * 4px};
    }
    .row-gap-#{$i} {
      row-gap: #{$i * 4px};
    }
    .column-gap-#{$i} {
      column-gap: #{$i * 4px};
    }
  }
}

.removed-box-shadows {
  .form-wrapper,
  .welcome-wrapper,
  .form-outer-wrap {
    box-shadow: none !important;
  }
}
// .page-title {
//   &.dark-form *{
//     color: #fff;
//     font-weight: 400;
//   }
//   &.light-form *{
//     color: #000;
//   }
// }

</style>
<style lang="scss" scoped>
:root {
  --body-background-color: #9c9c9c;
  --main-primary-color: #4dffea;
  --main-secondary-color: #a6fbf079;
  --main-contrast-color: #e70505;
  --main-text-color: #000000;
  --main-navbar-background: #f1f2f5;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
