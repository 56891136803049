<template>
  <input
    :placeholder="placeholder"
    @blur="onBlurHandler"
    @input="onInputHandler"
    @focus="onFocusHandler"
    ref="numeric"
    type="tel"
    :value="amount"
    v-if="!readOnly"
  />
  <span v-else ref="readOnly">{{ amount }}</span>
</template>

<script>
export function formatMoney(value, options = {}) {
  const {
    symbol = '',
    precision = 2,
    thousand = ',',
    decimal = '.',
    format = '%s%v',
  } = options;

  const negative = value < 0 ? '-' : '';
  const i = `${parseInt((value = Math.abs(Number(value) || 0).toFixed(precision)), 10)}`;
  const j = i.length > 3 ? i.length % 3 : 0;

  const formattedValue = (
    negative +
    (j ? i.substr(0, j) + thousand : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousand}`) +
    (precision
      ? decimal +
        Math.abs(value - i)
          .toFixed(precision)
          .slice(2)
      : '')
  );

  return format.replace('%s', symbol).replace('%v', formattedValue);
}

export function unformat(value, decimal = '.') {
  if (typeof value === 'number') return value;
  return parseFloat(
    value
      .replace(new RegExp(`[^0-9-${decimal}]`, 'g'), '')
      .replace(decimal, '.')
  );
}

export function toFixed(value, precision = 2) {
  return Number(value).toFixed(precision);
}

export default {
  name: 'VueNumeric',
  props: {
    /**
     * Currency symbol.
     */
    currency: {
      type: String,
      default: '',
      required: false,
    },
    /**
     * Maximum value allowed.
     */
    max: {
      type: Number,
      default: Number.MAX_SAFE_INTEGER || 9007199254740991,
      required: false,
    },
    /**
     * Minimum value allowed.
     */
    min: {
      type: Number,
      default: Number.MIN_SAFE_INTEGER || -9007199254740991,
      required: false,
    },
    /**
     * Enable/Disable minus value.
     */
    minus: {
      type: Boolean,
      default: false,
      required: false,
    },
    /**
     * Input placeholder.
     */
    placeholder: {
      type: String,
      default: '',
      required: false,
    },
    /**
     * Value when the input is empty
     */
    emptyValue: {
      type: [Number, String],
      default: '',
      required: false,
    },
    /**
     * Number of decimals.
     * Decimals symbol are the opposite of separator symbol.
     */
    precision: {
      type: Number,
      default: 0,
      required: false,
    },
    /**
     * Thousand separator type.
     * Separator props accept either . or , (default).
     */
    separator: {
      type: String,
      default: ',',
      required: false,
    },
    /**
     * Forced thousand separator.
     * Accepts any string.
     */
    thousandSeparator: {
      default: undefined,
      required: false,
      type: String,
    },
    /**
     * Forced decimal separator.
     * Accepts any string.
     */
    decimalSeparator: { default: undefined, required: false, type: String },
    /**
     * The output type used for v-model.
     * It can either be String or Number (default).
     */
    outputType: {
      required: false,
      type: String,
      default: 'Number',
    },
    /**
     * v-model value.
     */
    value: {
      type: [Number, String],
      // default: 0
      // required: true
    },
    /**
     * Hide input and show value in text only.
     */
    readOnly: { type: Boolean, default: false, required: false },
    /**
     * Class for the span tag when readOnly props is true.
     */
    readOnlyClass: {
      type: String,
      default: '',
      required: false,
    },
    /**
     * Position of currency symbol
     * Symbol position props accept either 'suffix' or 'prefix' (default).
     */
    currencySymbolPosition: {
      type: String,
      default: 'prefix',
      required: false,
    },
  },
  data: () => ({
    amount: '',
    touched: false,
  }),
  computed: {
    /**
     * Number type of formatted value.
     * @return {Number}
     */
    amountNumber() {
      return this.unformat(this.amount);
    },
    /**
     * Number type of value props.
     * @return {Number}
     */
    valueNumber() {
      if (this.value === 0 && !this.touched) {
        return this.unformat('0.0000000000001');
      }
      return this.unformat(this.value);
    },
    /**
     * Define decimal separator based on separator props.
     * @return {String} '.' or ','
     */
    decimalSeparatorSymbol() {
      if (typeof this.decimalSeparator !== 'undefined')
        return this.decimalSeparator;
      if (this.separator === ',') return '.';
      return ',';
    },
    /**
     * Define thousand separator based on separator props.
     * @return {String} '.' or ','
     */
    thousandSeparatorSymbol() {
      if (typeof this.thousandSeparator !== 'undefined')
        return this.thousandSeparator;
      if (this.separator === '.') return '.';
      if (this.separator === 'space') return ' ';
      return ',';
    },
    /**
     * Define format position for currency symbol and value.
     * @return {String} format
     */
    symbolPosition() {
      if (!this.currency) return '%v';
      return this.currencySymbolPosition === 'suffix' ? '%v %s' : '%s %v';
    },
  },
  watch: {
    /**
     * Watch for value change from other input with same v-model.
     * @param {Number} newValue
     */
    valueNumber(newValue) {
      if (this.$refs.numeric !== document.activeElement) {
        this.amount = this.value !== null ? this.format(newValue) : null;
      }
    },
    /**
     * When readOnly is true, replace the span tag class.
     * @param {Boolean} newValue
     * @param {Boolean} oldValue
     */
    readOnly(newValue, oldValue) {
      if (oldValue === false && newValue === true) {
        this.$nextTick(() => {
          this.$refs.readOnly.className = this.readOnlyClass;
        });
      }
    },
    /**
     * Immediately reflect separator changes
     */
    separator() {
      this.process(this.valueNumber);
      this.amount = this.format(this.valueNumber);
    },
    /**
     * Immediately reflect currency changes
     */
    currency() {
      this.process(this.valueNumber);
      this.amount = this.format(this.valueNumber);
    },
    /**
     * Immediately reflect precision changes
     */
    precision() {
      this.process(this.valueNumber);
      this.amount = this.format(this.valueNumber);
    },
  },
  mounted() {
    // Set default value props when placeholder undefined.
    if (!this.placeholder) {
      this.process(this.valueNumber);
      this.amount = this.format(this.valueNumber);
      // In case of delayed props value.
      setTimeout(() => {
        this.process(this.valueNumber);
        this.amount = this.format(this.valueNumber);
      }, 500);
    }
    // Set read-only span element's class
    if (this.readOnly) this.$refs.readOnly.className = this.readOnlyClass;
  },
  methods: {
    /**
     * Handle blur event.
     * @param {Object} e
     */
    onBlurHandler(e) {
      if (this.touched || (this.value != null)) {
        this.amount = this.format(this.valueNumber);
      }
      this.$emit('blur', e);
    },
    /**
     * Handle focus event.
     * @param {Object} e
     */
    onFocusHandler(e) {
      this.$emit('focus', e);
      this.amount = this.valueNumber === 0 && !this.touched ? null : formatMoney(this.valueNumber, {
        symbol: '',
        format: '%v',
        thousand: '',
        decimal: this.decimalSeparatorSymbol,
        precision: Number(this.precision),
      });
    },
    /**
     * Handle input event.
     */
    onInputHandler(evt) {
      if (!this.touched) this.touched = true;
      const newDecimalPart = evt.target.value.split(
        this.decimalSeparatorSymbol
      )[1];
      if (newDecimalPart && newDecimalPart.length > this.precision) {
        evt.target.value = this.amount;
        return;
      }
      this.amount = evt.target.value;
      this.process(this.amountNumber);
    },
    /**
     * Validate value before update the component.
     * @param {Number} value
     */
    process(value) {
      if (value >= this.max) this.update(this.max);
      if (value <= this.min) this.update(this.min);
      if (value > this.min && value < this.max) this.update(value);
      if (!this.minus && value < 0)
        if (this.min >= 0) {
          this.update(this.min);
        } else {
          this.update(0);
        }
    },
    /**
     * Update parent component model value.
     * @param {Number} value
     */
    update(value) {
      const fixedValue = toFixed(value, this.precision);
      const output =
        this.outputType.toLowerCase() === 'string'
          ? fixedValue
          : Number(fixedValue);
      this.$emit('input', output);
    },
    /**
     * Format value using symbol and separator.
     * @param {Number} value
     * @return {String}
     */
    format(value) {
      return formatMoney(value, {
        symbol: this.currency,
        format: this.symbolPosition,
        precision: Number(this.precision),
        decimal: this.decimalSeparatorSymbol,
        thousand: this.thousandSeparatorSymbol,
      });
    },
    /**
     * Remove symbol and separator.
     * @param {Number} value
     * @return {Number}
     */
    unformat(value) {
      const toUnformat =
        typeof value === 'string' && value === '' ? this.emptyValue : value;
      return unformat(toUnformat, this.decimalSeparatorSymbol);
    },
    reset() {
      this.touched = false;
    },
  },
};
</script>
