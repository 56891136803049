<template>
  <section class="main-body-wrapper">
    <div class="container p-0">
      <div class="form-wrapper congrats-content">
        <div class="container-fluid">
          <h1>Your invite has already been submitted</h1>
          <p>
            Welcome to the backoffice! Click the link below to start using it.
            <br /><br />
            <router-link v-if="isInternalLink" :to="{ name: 'Backoffice' }">
              Go to Backoffice</router-link
            >
            <a v-else :href="backofficeUrl">Go to Backoffice</a>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import config from '@/config';

export default {
  name: 'InviteAlreadySubmited',
  data() {
    return {
      backofficeUrl: config.BACKOFFICE_URL
    };
  },
  computed: {
    isInternalLink() {
      // Check if the BACKOFFICE_URL is an internal route
      return this.backofficeUrl.startsWith('/');
    }
  }
};
</script>
