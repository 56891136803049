import { getColorType, smartAdjustColor } from '../../helpers/uiTheme';

const productStatusUrl = productSlug => `/onboarding/spproducts/${productSlug}/status/`;

export default async function ({ store, axios, to, next }) {
  try {
    const url = productStatusUrl(to.params.spProductSlug);
    const facId = store.getters.getFacilitator.facId;
    const response = await axios({ url, method: 'POST', data: { facId } });

    if (response.data.status !== 'active') return next({ name: 'InitiateFailure' });
    const { branding, name } = response.data;
    let styles = document.body.style;
    localStorage.setItem('branding', JSON.stringify({
      backgroundColor: branding?.backgroundColor ?? '#000',
      primaryColor: branding?.primaryColor ?? '#000',
      secondaryColor: branding?.secondaryColor ?? '#FFF',
      contrastColor: branding?.contrastColor ?? '#FFF',
      textColor: branding?.textColor ?? '#000',
      logoUrl: branding?.logoUrl ?? '',
      navbarBackgroundColor: branding?.navbarBackgroundColor ?? '#FFF',
      name
    }));

    styles.setProperty('--body-background-color', branding?.backgroundColor ?? '#000');
    styles.setProperty('--main-primary-color', branding?.primaryColor ?? '#000');
    styles.setProperty('--main-secondary-color', branding?.secondaryColor ?? '#FFF');
    styles.setProperty('--main-contrast-color', branding?.contrastColor ?? '#FFF');
    styles.setProperty('--main-text-color', branding?.textColor ?? '#000');
    styles.setProperty('--main-logo-url', branding?.logoUrl ?? '');
    styles.setProperty('--main-navbar-background', branding?.navbarBackgroundColor ?? '#FFF');
    styles.setProperty(
      '--titles-color', (getColorType(branding.primaryColor ?? '#000') === 'dark' ? '#ffffff' : '#000000')
    );
    styles.setProperty(
      '--main-primary-color-lighter',
      smartAdjustColor(branding.primaryColor ?? '#000', {
        lightness: 0.5,
        saturation: -0.8,
        contrast: 1.5,
      })
    );
    styles.setProperty(
      '--main-primary-color-darker',
      smartAdjustColor(branding.primaryColor ?? '#000', {
        lightness: -0.15,
        saturation: 0.08,
        contrast: 2
      })
    );

    next();
  } catch (error) {
    console.log(error);
    const defaultError = 'Server side error, please contact the support team.';
    const errorMessage = error.response ? error.response.data.message : defaultError;

    let logObject = {
      module: 'onboarding',
      error: errorMessage,
      details: {
        router: to.path,
        endpoint: `/onboarding/spproducts/${to.params.spProductSlug}/status/`,
        message: `Invalid product status.`
      }
    };
    console.log(JSON.stringify(logObject));
    store.commit('LOG_ERROR', errorMessage);
  }
}
